import React, { useState, forwardRef, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import MaterialTable from "material-table";
import { Dropdown } from 'react-bootstrap';
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';

import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";
import avarta from "../../../images/avatar/1.png";

import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import moment from 'moment';

import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const Crypto_Transactions = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const [original_deposit_array, setoriginal_deposit_array] = useState([]);
    const [original_withdraw_array, setoriginal_withdraw_array] = useState([]);
    const [original_transfer_array, setoriginal_transfer_array] = useState([]);
    const [original_exchange_array, setoriginal_exchange_array] = useState([]);
    const [original_swap_array, setoriginal_swap_array] = useState([]);

    const [original_depositaddress_array, setoriginal_depositaddress_array] = useState([]);
    const [original_withdrawaddress_array, setoriginal_withdrawaddress_array] = useState([]);
    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");

    const [temp_deposit_array, settemp_deposit_array] = useState([]);
    const [temp_withdraw_array, settemp_withdraw_array] = useState([]);
    const [temp_transfer_array, settemp_transfer_array] = useState([]);
    const [temp_exchange_array, settemp_exchange_array] = useState([]);
    const [temp_swap_array, settemp_swap_array] = useState([]);
    const [temp_depositaddress_array, settemp_depositaddress_array] = useState([]);
    const [temp_withdrawaddress_array, settemp_withdrawaddress_array] = useState([]);

    const [TransactionType, setTransactionType] = useState("CryptoExchange");
    const handleChangeTransactionType = (TransactionTypeVal) => {
        setTransactionType(TransactionTypeVal);
        document.getElementById("CryptoExchange").style.display = "none";
        document.getElementById("Deposit").style.display = "none";
        document.getElementById("Withdrawal").style.display = "none";
        document.getElementById("Transfer").style.display = "none";
        document.getElementById("Swap").style.display = "none";
        document.getElementById("Depositaddress").style.display = "none";
        document.getElementById("Withdrawaddress").style.display = "none";
        document.getElementById(TransactionTypeVal).style.display = "block";
    }


    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Transactions";
        var newarray = original_exchange_array;
        var columns = [
            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
            { title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
            { title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
            { title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
            { title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
            { title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
        ];

        if (TransactionType === "CryptoExchange") {
            FileName = "CryptoExchange";
            newarray = original_exchange_array;
            columns = [
                { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                { title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
                { title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
                { title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
                { title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
                { title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
            ];

            var FileData = newarray.map(rowData =>
                [rowData["transaction_hash"],
                rowData["created_datetime"],
                rowData["exchange_type"],
                rowData["exchnage_pair"],
                rowData["fiat_amount"],
                rowData["crypto_amount"],
                rowData["StatusText"]]
            );

        } else if (TransactionType === "Deposit") {
            FileName = "Deposit";
            newarray = original_deposit_array;
            columns = [
                { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                { title: t("TRANSACTION.AMOUNT"), field: 'amount' },
                { title: t("TRANSACTION.STATUS_LA"), field: 'status', align: 'center' }
            ];

            var FileData = newarray.map(rowData =>
                [rowData["date"],
                rowData["currancy"],
                rowData["transactionid"],
                rowData["amount"],
                rowData["StatusText"]]
            );
        } else if (TransactionType === "Depositaddress") {
            FileName = "Deposit Address";
            newarray = original_depositaddress_array;
            columns = [
                { title: 'Date', field: 'date', },
                { title: 'Account Number', field: 'Accountno' },
                { title: 'Wallet Address', field: 'WalletAddress' },
                { title: 'Status', field: 'Status', align: 'center' },
            ]

            var FileData = newarray.map(rowData =>
                [rowData["date"],
                rowData["Accountno"],
                rowData["WalletAddress"],
                rowData["StatusText"]]
            );
        } else if (TransactionType === "Withdrawaddress") {
            FileName = "Withdraw Address";
            newarray = original_withdrawaddress_array;
            columns = [
                { title: 'Date', field: 'date', },
                { title: 'Account Number', field: 'Accountno' },
                { title: 'Wallet Address', field: 'WalletAddress' },
                { title: 'Status', field: 'Status', align: 'center' },
            ]

            var FileData = newarray.map(rowData =>
                [rowData["date"],
                rowData["Accountno"],
                rowData["WalletAddress"],
                rowData["StatusText"]]
            );
        } else if (TransactionType === "Withdrawal") {
            FileName = "Withdrawal";
            newarray = original_withdraw_array;
            columns = [
                { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                { title: t("TRANSACTION.AMOUNT"), field: 'amount' },
                { title: t("TRANSACTION.STATUS_LA"), field: 'status', align: 'center' }
            ];

            var FileData = newarray.map(rowData =>
                [rowData["date"],
                rowData["coincode"],
                rowData["transactionid"],
                rowData["amount"],
                rowData["StatusText"]]
            );
        } else if (TransactionType === "Transfer") {
            FileName = "Transfer";
            newarray = original_transfer_array;
            columns = [
                { title: t("TRANSACTION.USERNAME"), field: 'userdiv' },
                { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                { title: t("TRANSACTION.AMOUNT"), field: 'amount' }
            ];


            var FileData = newarray.map(rowData =>
                [rowData["CusName"],
                rowData["transactionid"],
                rowData["date"],
                rowData["coincode"],
                rowData["amount"]]
            );
        } else if (TransactionType === "Swap") {
            FileName = "Swap";
            newarray = original_swap_array;
            columns = [
                { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                { title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
                { title: t("FIAT.FROM"), field: 'from_coin' },
                { title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
                { title: t("FIAT.TO"), field: 'to_coin' },
                { title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
            ];

            var FileData = newarray.map(rowData =>
                [rowData["transaction_hash"],
                rowData["created_datetime"],
                rowData["from_amount"],
                rowData["from_coincode"],
                rowData["to_amount"],
                rowData["to_coincode"],
                rowData["StatusText"]]
            );
        }


        const columnTitles = columns.map(columnDef => columnDef.title);



        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const viewdeposit = (val) => {
        console.log(val.deposit_address_id);
        window.sessionStorage.setItem("depoidfk", val.deposit_address_id);
        history.push('/wallets-deposit-transaction');
    }

    const viewwithdraw = (val) => {
        console.log(val)
        window.localStorage.setItem("cypto_wallet_id", val.user_crypto_wallets_crypto_fk);
        window.localStorage.setItem("account_id", val.account_id);
        window.localStorage.setItem("withaddress", val.wallet_address);
        setTimeout(() => {
            history.push('/wallets-withdrawal-transaction');
        }, 1000);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleclicktransfer = (value) => {}

    const handleLoad = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/GetAllCryptoTransaction', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setLoader(false);
                    settemp_transfer_array(res.data.transfer_array);
                    settemp_withdraw_array(res.data.withdrawtransaction);
                    settemp_exchange_array(res.data.buyselltra);
                    settemp_swap_array(res.data.swaptra);
                    settemp_deposit_array(res.data.deposittransaction);
                    settemp_depositaddress_array(res.data.depoaddress);
                    settemp_withdrawaddress_array(res.data.withaddress);
                    if (res.data.transfer_array.length > 0) {
                        var TempArrfiat = [];
                        res.data.transfer_array.forEach(element => {

                            var currancy = "";
                            var amount = "";
                            //var popup = <div onClick={(event) => handleclicktransfer(element.transfer_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>

                            var userdiv = <div><div className="mb-lg-0 mb-3 d-flex align-items-center"><div className="profile-image mr-4"><img src={avarta} alt="" width="63" className="rounded-circle" /></div><div><h6 className="fs-16 font-w700 mb-0"><Link className="text-black"> {element.first_name + '' + element.last_name}</Link></h6><span className="fs-14">{element.email_id}</span></div></div></div>
                            if (element.image_64 !== "" && element.image_64 !== null) {
                                userdiv = <div><div className="mb-lg-0 mb-3 d-flex align-items-center"><div className="profile-image mr-4"><img src={process.env.REACT_APP_FILE_PATH + element.image_64} alt="" width="63" className="rounded-circle" /></div><div><h6 className="fs-16 font-w700 mb-0"><Link className="text-black"> {element.first_name + ' ' + element.last_name}</Link></h6><span className="fs-14">{element.email_id}</span></div></div></div>
                            }
                            if (element.coin_code === "BTC") {
                                currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                                amount = element.transfer_amount + " BTC";
                            } else if (element.coin_code === "ETH") {
                                currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                                amount = element.transfer_amount + " ETH";
                            } else if (element.coin_code === "USDT") {
                                currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                                amount = element.transfer_amount + " USDT";
                            }

                            var fiatonetemp = {
                                userdiv: userdiv,
                                transactionid: element.transaction_hash,
                                date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                currancy: currancy,
                                amount: amount,
                                CusName: element.first_name + '' + element.last_name,
                                coincode: element.coin_code,
                            };
                            TempArrfiat.push(fiatonetemp);

                        });
                        setoriginal_transfer_array(TempArrfiat);
                    }


                    if (res.data.deposittransaction.length > 0) {
                        var TempArrwith = [];
                        res.data.deposittransaction.forEach(element => {

                            var currancy = "";
                            var amount = "";
                            // var popup = <div onClick={(event) => handleclicktransfer(element.withdraw_tra_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>
                            var status = "";

                            if (element.coin_code === "BTC") {
                                currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                                amount = element.deposit_amount + " BTC";
                            } else if (element.coin_code === "ETH") {
                                currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                                amount = element.deposit_amount + " ETH";
                            } else if (element.coin_code === "USDT") {
                                currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                                amount = element.deposit_amount + " USDT";
                            }

                            if (element.deposit_status === "Completed") {
                                status = <div><button className="btn btn-outline-success btn-rounded">Success</button></div>
                            } else if (element.deposit_status === "Failed") {
                                status = <div><button className="btn btn-outline-danger btn-rounded">Failed</button></div>
                            } else {
                                status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button></div>
                            }


                            var withtemp = {
                                date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                currancy: currancy,
                                transactionid: element.transaction_hash,
                                amount: amount,
                                status: status,
                                StatusText: element.deposit_status,
                            };
                            TempArrwith.push(withtemp);

                        });
                        setoriginal_deposit_array(TempArrwith);
                    }

                    if (res.data.depoaddress.length > 0) {
                        var TempArrwith = [];
                        res.data.depoaddress.forEach(element => {

                            if (element.status === "Approved") {
                                var status = <div><button className="btn btn-outline-warning btn-rounded">Inactive</button></div>
                            } else {
                                var status = <div><button className="btn btn-outline-success btn-rounded">Active</button></div>
                            }

                            var depoaddress = {
                                date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                Accountno: element.user_acc_account_number,
                                WalletAddress: element.wallet_address,
                                Status: status,
                                Action: <Link to="#" onClick={() => viewdeposit(element)} className="btn btn-light btn-rounded btn-xs" title="View"><i className="fa fa-eye f-18"></i></Link>,
                                StatusText: element.status,
                            };
                            TempArrwith.push(depoaddress);

                        });
                        setoriginal_depositaddress_array(TempArrwith);
                    }


                    if (res.data.withaddress.length > 0) {
                        var TempArrwith = [];
                        res.data.withaddress.forEach(element => {

                            var status = <div><button className="btn btn-outline-warning btn-rounded">Inactive</button></div>

                            var withaddress = {
                                date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                Accountno: element.user_acc_account_number,
                                WalletAddress: element.wallet_address,
                                Status: status,
                                StatusText: "Inactive",
                                Action: <Link to="#" onClick={() => viewwithdraw(element)} className="btn btn-light btn-rounded btn-xs" title="View"><i className="fa fa-eye f-18"></i></Link>
                            };
                            TempArrwith.push(withaddress);

                        });
                        setoriginal_withdrawaddress_array(TempArrwith);
                    }



                    if (res.data.withdrawtransaction.length > 0) {
                        var TempArrwith = [];
                        res.data.withdrawtransaction.forEach(element => {

                            var currancy = "";
                            var amount = "";
                            // var popup = <div onClick={(event) => handleclicktransfer(element.withdraw_tra_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>
                            var status = "";

                            if (element.coin_code === "BTC") {
                                currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                                amount = element.withdraw_amount + " BTC";
                            } else if (element.coin_code === "ETH") {
                                currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                                amount = element.withdraw_amount + " ETH";
                            } else if (element.coin_code === "USDT") {
                                currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                                amount = element.withdraw_amount + " USDT";
                            }

                            if (element.withdraw_status === "Completed") {
                                status = <div><button className="btn btn-outline-success btn-rounded">Success</button></div>
                            } else if (element.withdraw_status === "Failed") {
                                status = <div><button className="btn btn-outline-danger btn-rounded">Failed</button></div>
                            } else {
                                status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button></div>
                            }


                            var withtemp = {
                                date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                currancy: currancy,
                                transactionid: element.transaction_hash,
                                amount: amount,
                                status: status,
                                StatusText: element.withdraw_status,
                                coincode: element.coin_code,
                            };
                            TempArrwith.push(withtemp);

                        });
                        setoriginal_withdraw_array(TempArrwith);
                    }

                    if (res.data.buyselltra.length > 0) {
                        var TempArrbuysell = [];
                        res.data.buyselltra.forEach(element => {

                            var status = "";
                            if (element.transfer_status === "Completed") {
                                status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                            } else if (element.transfer_status === "Failed") {
                                status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                            } else {
                                status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                            }
                            var fiatonetemp = {
                                transaction_hash: element.transaction_hash,
                                created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                exchange_type: element.exchange_type,
                                exchnage_pair: element.exchnage_pair,
                                fiat_amount: element.fiat_amount,
                                crypto_amount: element.crypto_amount,
                                transfer_status: status,
                                StatusText: element.transfer_status,

                            };
                            TempArrbuysell.push(fiatonetemp);

                        });
                        setoriginal_exchange_array(TempArrbuysell);
                    }

                    if (res.data.swaptra.length > 0) {
                        var TempArrswap = [];
                        res.data.swaptra.forEach(element => {
                            var from_coin = "";
                            var to_coin = "";
                            var status = "";
                            if (element.swap_status === "Completed") {
                                status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                            } else if (element.swap_status === "Failed") {
                                status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                            } else {
                                status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                            }

                            if (element.coin_code === "BTC") {
                                from_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                            } else if (element.coin_code === "ETH") {
                                from_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                            } else if (element.coin_code === "USDT") {
                                from_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                            }

                            if (element.to_coin_code === "BTC") {
                                to_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                            } else if (element.to_coin_code === "ETH") {
                                to_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                            } else if (element.to_coin_code === "USDT") {
                                to_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                            }

                            var swaptemp = {
                                transaction_hash: element.transaction_hash,
                                created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                from_amount: element.from_amount,
                                from_coin: from_coin,
                                to_amount: element.to_amount,
                                to_coin: to_coin,
                                swap_status: status,
                                from_coincode: element.coin_code,
                                to_coincode: element.to_coin_code,
                                StatusText: element.swap_status,
                            };
                            TempArrswap.push(swaptemp);

                        });
                        setoriginal_swap_array(TempArrswap);
                    }

                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateFromdate = (value) => {
        setfrom_date(value);
        filter("1," + value, "");
    }

    const validateTodate = (value) => {
        setto_date(value);
        filter("", "1," + value);
    }


    const filter = (fromdate = "", todate = "") => {
        var tempfrom = from_date;
        var temptodate = to_date;

        if (fromdate !== "") {
            if (fromdate.split(",")[0] === "1" && fromdate.split(",")[1] !== "") {
                tempfrom = fromdate.split(",")[1];
            }
            else if (fromdate.split(",")[0] === "1" && fromdate.split(",")[1] === "") {
                tempfrom = "";
            }
        }

        if (todate !== "") {
            if (todate.split(",")[0] === "1" && todate.split(",")[1] !== "") {
                temptodate = todate.split(",")[1];
            }
            else if (todate.split(",")[0] === "1" && todate.split(",")[1] === "") {
                temptodate = "";
            }
        }

        if (TransactionType === "Withdrawal") {
            var TempArr = [];
            temp_withdraw_array.forEach(element => {
                var currancy = "";
                var amount = "";
                //var popup = <div onClick={(event) => handleclicktransfer(element.withdraw_tra_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>
                var status = "";

                if (element.coin_code === "BTC") {
                    currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                    amount = element.withdraw_amount + " BTC";
                } else if (element.coin_code === "ETH") {
                    currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                    amount = element.withdraw_amount + " ETH";
                } else if (element.coin_code === "USDT") {
                    currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                    amount = element.withdraw_amount + " USDT";
                }

                if (element.withdraw_status === "Completed") {
                    status = <div><button className="btn btn-outline-success btn-rounded">Success</button></div>
                } else if (element.withdraw_status === "Failed") {
                    status = <div><button className="btn btn-outline-danger btn-rounded">Failed</button></div>
                } else {
                    status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button></div>
                }


                var temp = {
                    date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    currancy: currancy,
                    transactionid: element.transaction_hash,
                    amount: amount,
                    status: status
                };


                if (tempfrom === "" && temptodate === "") {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    TempArr.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
            });
            setoriginal_withdraw_array(TempArr);
        } else if (TransactionType === "Deposit") {
            var TempArr = [];
            temp_deposit_array.forEach(element => {
                var currancy = "";
                var amount = "";
                //var popup = <div onClick={(event) => handleclicktransfer(element.withdraw_tra_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>
                var status = "";

                if (element.coin_code === "BTC") {
                    currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                    amount = element.deposit_amount + " BTC";
                } else if (element.coin_code === "ETH") {
                    currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                    amount = element.deposit_amount + " ETH";
                } else if (element.coin_code === "USDT") {
                    currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                    amount = element.deposit_amount + " USDT";
                }

                if (element.deposit_status === "Completed") {
                    status = <div><button className="btn btn-outline-success btn-rounded">Success</button></div>
                } else if (element.deposit_status === "Failed") {
                    status = <div><button className="btn btn-outline-danger btn-rounded">Failed</button></div>
                } else {
                    status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button></div>
                }


                var temp = {
                    date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    currancy: currancy,
                    transactionid: element.transaction_hash,
                    amount: amount,
                    status: status
                };


                if (tempfrom === "" && temptodate === "") {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    TempArr.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
            });
            setoriginal_deposit_array(TempArr);
        } else if (TransactionType === "Depositaddress") {
            var TempArr = [];
            temp_depositaddress_array.forEach(element => {
                if (element.status === "Approved") {
                    var status = <div><button className="btn btn-outline-warning btn-rounded">Inactive</button></div>
                } else {
                    var status = <div><button className="btn btn-outline-success btn-rounded">Active</button></div>
                }

                var temp = {
                    date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    Accountno: element.user_acc_account_number,
                    WalletAddress: element.wallet_address,
                    Status: status,
                    Action: <Link to="#" onClick={() => viewdeposit(element)} className="btn btn-light btn-rounded btn-xs" title="View"><i className="fa fa-eye f-18"></i></Link>
                };


                if (tempfrom === "" && temptodate === "") {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    TempArr.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
            });
            setoriginal_depositaddress_array(TempArr);
        } else if (TransactionType === "Withdrawaddress") {
            var TempArr = [];
            temp_withdrawaddress_array.forEach(element => {
                var status = <div><button className="btn btn-outline-warning btn-rounded">Inactive</button></div>


                var temp = {
                    date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    Accountno: element.user_acc_account_number,
                    WalletAddress: element.wallet_address,
                    Status: status,
                    Action: <Link to="#" onClick={() => viewdeposit(element)} className="btn btn-light btn-rounded btn-xs" title="View"><i className="fa fa-eye f-18"></i></Link>
                };

                if (tempfrom === "" && temptodate === "") {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    TempArr.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArr.push(temp);
                }
            });
            setoriginal_withdrawaddress_array(TempArr);
        } else if (TransactionType === "Transfer") {
            var TempArrss = [];
            temp_transfer_array.forEach(element => {
                var currancy = "";
                var amount = "";
                // var popup = <div onClick={(event) => handleclicktransfer(element.transfer_id)}><Link className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> {t("TRANSACTION.VIEW_DETAILS")}</Link></div>

                var userdiv = <div><div className="mb-lg-0 mb-3 d-flex align-items-center"><div className="profile-image mr-4"><img src={avarta} alt="" width="63" className="rounded-circle" /></div><div><h6 className="fs-16 font-w700 mb-0"><Link className="text-black"> {element.first_name + '' + element.last_name}</Link></h6><span className="fs-14">{element.email_id}</span></div></div></div>
                if (element.image_64 !== "" && element.image_64 !== null) {
                    userdiv = <div><div className="mb-lg-0 mb-3 d-flex align-items-center"><div className="profile-image mr-4"><img src={process.env.REACT_APP_FILE_PATH + element.image_64} alt="" width="63" className="rounded-circle" /></div><div><h6 className="fs-16 font-w700 mb-0"><Link className="text-black"> {element.first_name + ' ' + element.last_name}</Link></h6><span className="fs-14">{element.email_id}</span></div></div></div>
                }
                if (element.coin_code === "BTC") {
                    currancy = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                    amount = element.transfer_amount + " BTC";
                } else if (element.coin_code === "ETH") {
                    currancy = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                    amount = element.transfer_amount + " ETH";
                } else if (element.coin_code === "USDT") {
                    currancy = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                    amount = element.transfer_amount + " USDT";
                }

                var temp = {
                    userdiv: userdiv,
                    transactionid: element.transaction_hash,
                    date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    currancy: currancy,
                    amount: amount
                };

                if (tempfrom === "" && temptodate === "") {
                    TempArrss.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    TempArrss.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArrss.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    TempArrss.push(temp);
                }
            });
            setoriginal_transfer_array(TempArrss);
        } else if (TransactionType === "CryptoExchange") {
            var Tempexchange = [];
            temp_exchange_array.forEach(element => {
                var status = "";
                if (element.transfer_status === "Completed") {
                    status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                } else if (element.transfer_status === "Failed") {
                    status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                } else {
                    status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                }
                var temp = {
                    transaction_hash: element.transaction_hash,
                    created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    exchange_type: element.exchange_type,
                    exchnage_pair: element.exchnage_pair,
                    fiat_amount: element.fiat_amount,
                    crypto_amount: element.crypto_amount,
                    transfer_status: status
                };

                if (tempfrom === "" && temptodate === "") {
                    Tempexchange.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    Tempexchange.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    Tempexchange.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    Tempexchange.push(temp);
                }
            });
            setoriginal_exchange_array(Tempexchange);
        } else if (TransactionType === "Swap") {
            var Tempswap = [];
            temp_swap_array.forEach(element => {
                var from_coin = "";
                var to_coin = "";
                var status = "";
                if (element.swap_status === "Completed") {
                    status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                } else if (element.swap_status === "Failed") {
                    status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                } else {
                    status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                }

                if (element.coin_code === "BTC") {
                    from_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                } else if (element.coin_code === "ETH") {
                    from_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                } else if (element.coin_code === "USDT") {
                    from_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                }

                if (element.to_coin_code === "BTC") {
                    to_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                } else if (element.to_coin_code === "ETH") {
                    to_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                } else if (element.to_coin_code === "USDT") {
                    to_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                }

                var temp = {
                    transaction_hash: element.transaction_hash,
                    created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                    from_amount: element.from_amount,
                    from_coin: from_coin,
                    to_amount: element.to_amount,
                    to_coin: to_coin,
                    swap_status: status
                };

                if (tempfrom === "" && temptodate === "") {
                    Tempswap.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "") {
                    Tempswap.push(temp);
                }
                else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    Tempswap.push(temp);
                }
                else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD'))) {
                    Tempswap.push(temp);
                }
            });
            setoriginal_swap_array(Tempswap);
        }
    }

    return (
        <div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header mobile-card-header">
                        <h2 className="text-black font-w600 mb-0">{t("CRYPTOTRANSACTION.CRYPTO_TRA_LABEL")}</h2>
                        <div className="mobileFullWidth">
                            <Dropdown className="custom-dropdown mb-0" >
                                <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                    <i className="las la-download scale5 mr-3"></i> Download Report
                                    <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                    <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("CSV")}>CSV</Dropdown.Item>
                                    <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("PDF")}>PDF</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card mb-0 p-0">
                    <div className="card-body bdr-btm-card">
                        <div className="row">

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => validateFromdate(e.target.value)} value={from_date} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => validateTodate(e.target.value)} value={to_date} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t("TRANSACTION.TRANSACTION_TYPE")}</span>
                                    </label>
                                    <select className="form-control webkit-none" value={TransactionType} onChange={(event) => handleChangeTransactionType(event.target.value)}>
                                        <option value={"CryptoExchange"}>{t("CRYPTOTRANSACTION.CRYPTO_EX_LABEL")}</option>
                                        <option value={"Deposit"}>{t("CRYPTOTRANSACTION.DEPOSIT_TITLE")}</option>
                                        <option value={"Depositaddress"}>Deposit Address</option>
                                        <option value={"Withdrawaddress"}>Withdraw Address</option>
                                        <option value={"Withdrawal"}>{t("CRYPTOTRANSACTION.WITHDRAW_TITLE")}</option>
                                        <option value={"Transfer"}>{t("CRYPTOTRANSACTION.TRANSFER_TITLE")}</option>
                                        <option value={"Swap"}>{t("CRYPTOTRANSACTION.SWAP_TITLE")}</option>
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="mobileFullWidth">
                            {/* <Dropdown className="custom-dropdown mb-0" >
                                <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                    <i className="las la-download scale5 mr-3"></i> Download Report
                                    <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                    <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("CSV")}>CSV</Dropdown.Item>
                                    <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("PDF")}>PDF</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                        <div className="position-relative hide-scrolllbar">
                            <div className="table-responsive" id="CryptoExchange">
                                {original_exchange_array.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.BUYSELLLA")}
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, }}
                                        columns={[
                                            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                                            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                                            { title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
                                            { title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
                                            { title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
                                            { title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
                                        ]}
                                        data={original_exchange_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.BUYSELLLA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>

                            <div className="table-responsive" id="Deposit" style={{ display: "none" }}>
                                {original_deposit_array.length > 0 ?
                                    <MaterialTable
                                        title="Deposit"
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                                            { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                                            { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                                            { title: t("TRANSACTION.AMOUNT"), field: 'amount' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'status', align: 'center' }
                                        ]}
                                        data={original_deposit_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Deposit Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                            <div className="table-responsive" id="Depositaddress" style={{ display: "none" }}>
                                {original_depositaddress_array.length > 0 ?
                                    <MaterialTable
                                        title="Deposit Address"
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            { title: 'Date', field: 'date', },
                                            { title: 'Account Number', field: 'Accountno' },
                                            { title: 'Wallet Address', field: 'WalletAddress' },
                                            { title: 'Status', field: 'Status', align: 'center' },
                                            // { title: 'Action', field: 'Action', align: 'center' },
                                        ]}
                                        data={original_depositaddress_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Deposit Address Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                            <div className="table-responsive" id="Withdrawaddress" style={{ display: "none" }}>
                                {original_withdrawaddress_array.length > 0 ?
                                    <MaterialTable
                                        title="Withdraw Address"
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            { title: 'Date', field: 'date', },
                                            { title: 'Account Number', field: 'Accountno' },
                                            { title: 'Wallet Address', field: 'WalletAddress' },
                                            { title: 'Status', field: 'Status', align: 'center' },
                                            // { title: 'Action', field: 'Action' },
                                        ]}
                                        data={original_withdrawaddress_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Withdraw Address Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                            <div className="table-responsive" id="Withdrawal" style={{ display: "none" }}>
                                {original_withdraw_array.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.WITHTRALA")}
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                                            { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                                            { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                                            { title: t("TRANSACTION.AMOUNT"), field: 'amount' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'status', align: 'center' }
                                        ]}
                                        data={original_withdraw_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.WITHTRALA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                            <div className="table-responsive" id="Transfer" style={{ display: "none" }}>
                                {original_transfer_array.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.TRANSFERTRALA")}
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            { title: t("TRANSACTION.USERNAME"), field: 'userdiv' },
                                            { title: t("CRYPTOTRANSACTION.TRAHASH"), field: 'transactionid' },
                                            { title: t("TRANSACTION.DATE_LA"), field: 'date' },
                                            { title: t("CRYPTOTRANSACTION.COINCODE"), field: 'currancy' },
                                            { title: t("TRANSACTION.AMOUNT"), field: 'amount' }
                                        ]}
                                        data={original_transfer_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.TRANSFERTRALA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                            <div className="table-responsive" id="Swap" style={{ display: "none" }}>
                                {original_swap_array.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.SWAP_TRA_LA")}
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, }}
                                        columns={[
                                            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                                            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                                            { title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
                                            { title: t("FIAT.FROM"), field: 'from_coin' },
                                            { title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
                                            { title: t("FIAT.TO"), field: 'to_coin' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
                                        ]}
                                        data={original_swap_array}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.SWAP_TRA_LA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )

}
export default Crypto_Transactions;