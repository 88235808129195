import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import CardCenterOwl from '../CardAccount/CardCenterOwl';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import card2 from '../../../images/card/p-card.png';
import dualdot from '../../../images/dual-dot.png';
import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';
import card1 from '../../../images/card/v-card.png';
// import { createRoot } from 'react-dom/client';
import jsPDF from 'jspdf';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';

const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    loop: false,
    merge: true,
    center: true,
    autoWidth: true,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                centerMode: true,

            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};


export const MyCards = () => {
    const history = useHistory();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [accountval, setaccountval] = useState("");
    const [amountval, setamountval] = useState("");
    const [cardidval, setcardidval] = useState("");
    const [accountidval, setaccountidval] = useState("");
    const [amountsymbole, setamountsymbole] = useState("");

    const [loader, setLoader] = useState(false);
    const [cardarray, setcardarray] = useState([]);
    const [cardarrayoriginal, setcardarrayoriginal] = useState(["aaa"]);

    const [OTPVerification, setOTPVerification] = useState(false);
    const [GoogleAuthenticator, setGoogleAuthenticator] = useState(false);


    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);
    const [success_display_debit_amount, setsuccess_display_debit_amount] = useState("");
    const [success_display_card_number, setsuccess_display_card_number] = useState("");
    const [success_display_from_account, setsuccess_display_from_account] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = async () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getusercards', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;

                if (res.code === "200") {
                    setLoader(false);
                    await setcardarrayoriginal(res.data);
                    if (res.data.length > 0) {
                        var TempArrswap = [];
                        res.data.forEach(element => {
                            var action = "";
                            var status = <Link className="btn btn-outline-warning btn-rounded">Pending</Link>;
                            if (element.usercard_status === "Approved") {
                                var status = <Link to="#" onClick={() => handleapprove(element.card_id)} className="btn btn-outline-success btn-rounded">Pay Card Fees</Link>;
                            } else if (element.usercard_status === "Active") {
                                var status = <Link className="btn btn-outline-success btn-rounded">Active</Link>;
                                var action = <div><Link to={"/card-settings/" + element.card_id} className="btn btn-primary btn-rounded btn-xs"><i className="fa fa-solid fa-gear"></i></Link></div>;

                            } else if (element.usercard_status === "Blocked") {
                                var status = <Link className="btn btn-outline-danger btn-rounded">Blocked</Link>;
                            }
                            else if (element.usercard_status === "Rejected") {
                                var status = <Link className="btn btn-outline-danger btn-rounded">Rejected</Link>;
                            }

                            var cardno = "";
                            var validno = "";
                            if (element.usercard_status === "Active") {
                                cardno = "**** **** **** " + String(element.card_number).slice("-4");
                                validno = element.exp_month + "/" + element.exp_year;
                            }

                            var swaptemp = {
                                CardType: element.cardtypename,
                                AccountNumber: element.accnumber,
                                CardNumber: cardno,
                                Validthru: validno,
                                NameinCard: element.card_customer_name,
                                Action: action,
                                Status: status,
                                StatusText: element.usercard_status,
                            };
                            TempArrswap.push(swaptemp);

                        });
                        setcardarray(TempArrswap);
                    }
                   
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleapprove = (card_id) => {

        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: card_id,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getapprovecarddetails', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setaccountval(res.data.accno);
                    setamountval(res.data.amount);
                    setamountsymbole(res.data.amountsymbole);
                    setaccountidval(res.data.accid);
                    setcardidval(res.data.cardid);
                    setOTPVerification(true);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleissue = () => {

        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardidval,
            acc_id: accountidval,
            amount_id: amountval,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setOTPVerification(false);
        axios.post(process.env.REACT_APP_API_URL + 'submitissuetra', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setsuccess_display_debit_amount(res.data.success_display_debit_amount);
                    setsuccess_display_card_number(res.data.success_display_card_number);
                    setsuccess_display_from_account(res.data.success_display_from_account);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_details(res.data.success_display_details);
                    setTransferAccept(true);

                    setaccountval("");
                    setamountval("");
                    setamountsymbole("");
                    setaccountidval("");
                    setcardidval("");
                    handleLoad();
                    setLoader(false);
                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Card List";

        var columns = [
            { title: 'Card Type', field: 'CardType' },
            { title: 'Account Number', field: 'AccountNumber' },
            { title: 'Card Number', field: 'CardNumber' },
            { title: 'Valid thru', field: 'Validthru' },
            { title: 'Namein Card', field: 'NameinCard' },
            { title: 'Status', field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = cardarray.map(rowData =>
            [rowData["CardType"],
            rowData["AccountNumber"],
            rowData["CardNumber"],
            rowData["Validthru"],
            rowData["NameinCard"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }
    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <div className="card">
                <div className="card-header mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">My Cards</h2>
                    <div className="mobileFullWidth">
                        <Link to="request-card" className="btn btn-primary btn-rounded">+ &nbsp;Request Card</Link>
                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="row">
                    <div className="col-xl-12 csmt-card" id="carddisplay">
                        <Slider className="cards mb-sm-5 mb-3" {...settings}>
                            {cardarrayoriginal.map((data, index) => (
                                <div className="items p-3">
                                    <div className="card-bx mb-0">
                                        <img src={card1} alt="" className="w-100" />
                                        <div className="card-info text-white">
                                            <p className="mb-1">{data.cardtypename}</p>
                                            <h2 className="fs-25 text-white mb-sm-4 mb-3">{data.card_currancy === "USD" ? '$' + data.card_balance : '€' + data.card_balance}</h2>
                                            <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-3">
                                                <img src={dualdot} alt="" className="dot-img" />
                                                <h4 className="fs-19 text-white mb-0">{"**** **** **** " + String(data.card_number).slice(-4)}</h4>
                                            </div>
                                            <div className="d-flex">
                                                <div className="mr-5">
                                                    <p className="fs-14 mb-1 op6">VALID THRU</p>
                                                    <span>{data.exp_month}/{data.exp_year}</span>
                                                </div>
                                                <div>
                                                    <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                                                    <span>{data.card_customer_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-sm-12">
                    <div className="card height-cstm-chart table-action-center">
                        <div className="card-body">
                            <div className="table-responsive">
                                {cardarray.length > 0 ?
                                    <MaterialTable
                                        title="Card List"
                                        columns={[
                                            { title: 'Card Type', field: 'CardType' },
                                            { title: 'Account Number', field: 'AccountNumber' },
                                            { title: 'Card Number', field: 'CardNumber' },
                                            { title: 'Valid thru', field: 'Validthru' },
                                            { title: 'Name in Card', field: 'NameinCard' },
                                            { title: 'Action', field: 'Action' },
                                            { title: 'Status', field: 'Status', align: 'center' },
                                        ]}
                                        data={cardarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Card List Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                                <Modal className="modal fade" show={OTPVerification}>
                                    <div className="modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Payment</h3>
                                                <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group position-relative">
                                                            <label className="mb-2 ">
                                                                <span>Paying From</span>
                                                            </label>
                                                            <input type="text" className="form-control text-black" value={accountval} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="mb-2 ">Amount  </label>
                                                            <input type="text" className="form-control text-black" value={amountsymbole} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>Close</button>
                                                <button className="btn btn-primary btn-rounded" onClick={handleissue}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal className="modal fade" show={GoogleAuthenticator}>
                                    <div className="modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Google Authenticator</h3>
                                                <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group position-relative">
                                                            <label className="mb-2 ">
                                                                <span>Paying From</span>
                                                            </label>
                                                            <select className="form-control webkit-none" id="sel1">
                                                                <option>Select Account</option>
                                                                <option>My Account : 1566956565611 ( 1529.10 € )</option>
                                                                <option>My Account : 9896515456565 ( 1929.10 $ )</option>
                                                            </select>
                                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="mb-2 ">Amount  </label>
                                                            <input type="email" className="form-control text-black" placeholder="Please Enter Amount" value={"$10.00"} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button className="btn btn-light btn-rounded light" onClick={() => setGoogleAuthenticator(false)}>Close</button>
                                                <Link to="/my-cards" onClick={() => setGoogleAuthenticator(false)} className="btn btn-primary btn-rounded">Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" show={TransferAccept}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Card Issuance Fee Payment</h3>
                            <button type="button" className="close" onClick={() => setTransferAccept(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-check"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Debited Amount : <span className="text-black">{success_display_debit_amount}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Card Number : <span className="text-black">{success_display_card_number}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Debited Account Number : <span className="text-black">{success_display_from_account}</span></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{success_display_details}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferAccept(false)}> OK </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={() => setTransferAccept(false)}> Card List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade" show={TransferDecline}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Card Issuance Fee Payment</h3>
                            <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-times"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{error_display_details}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferDecline(false)}> Retry </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={() => setTransferDecline(false)}> Card List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )

}
export default MyCards;