import React, { useState,forwardRef, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';

import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";

import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
// import moment from 'moment';
import validator from 'validator';
import Snackbars from '../../pages/Snackbars';
import Crypto_Transactions from "../Transaction/Crypto_Transactions";

import jsPDF from 'jspdf';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';

const defaultSymbols = [
    {
        proName: "FOREXCOM:SPXUSD",
        title: "S&P 500",
    },
    {
        proName: "FOREXCOM:NSXUSD",
        title: "Nasdaq 100",
    },
    {
        proName: "FX_IDC:EURUSD",
        title: "EUR/USD",
    },
    {
        proName: "BITSTAMP:BTCUSD",
        title: "BTC/USD",
    },
    {
        proName: "BITSTAMP:ETHUSD",
        title: "ETH/USD",
    },
];

export const Crypto = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [allaccount, setAllaccount] = useState([]);
    const [allcrypto, setAllcrypto] = useState([]);
    const [allUsercrypto, setUserCryptoWallet] = useState([]);

    const [loader, setLoader] = useState(false);
    const [activeToggle, setActiveToggle] = useState("posts");
    const [OTPVerification, setOTPVerification] = useState(false);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    const [depo_crypto, setdepo_crypto] = useState("");
    const [depo_crypto_error, setdepo_crypto_error] = useState("");
    const [depo_account, setdepo_account] = useState("");
    const [depo_account_error, setdepo_account_error] = useState("");

    const [with_crypto, setwith_crypto] = useState("");
    const [with_crypto_error, setwith_crypto_error] = useState("");
    const [with_account, setwith_account] = useState("");
    const [with_account_error, setwith_account_error] = useState("");
    const [with_address, setwith_address] = useState("");
    const [with_address_error, setwith_address_error] = useState("");


    const [transfer_crypto, settransfer_crypto] = useState("");
    const [transfer_crypto_error, settransfer_crypto_error] = useState("");
    const [transfer_email, settransfer_email] = useState("");
    const [transfer_email_error, settransfer_email_error] = useState("");
    const [transfer_amount, settransfer_amount] = useState("");
    const [transfer_amount_error, settransfer_amount_error] = useState("");
    const [transfer_wallet_address, settransfer_wallet_address] = useState("");
    const [transfer_wallet_address_error, settransfer_wallet_address_error] = useState("");

    const [google_auth, setgoogle_auth] = useState("");
    const [google_auth_error, setgoogle_auth_error] = useState("");

    const [minamount, setminamount] = useState("");
    const [maxamount, setmaxamount] = useState("");

    const [snackbar, setSnackbar] = useState(props.location.openSnackbar);
    const [snackbar_msg, openSnackbarmsg] = useState(props.location.openSnackbarmsg);

    const [SMSOTPVerification, setSMSOTPVerification] = useState(false);
    const [transferotp, settransferotp] = useState("");
    const [transferotpmessage, settransferotpmessage] = useState("");
    const [transferotpmessage_error, settransferotpmessage_error] = useState("");

    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);
    const [success_display_amount, setsuccess_display_amount] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_toaccount, setsuccess_display_toaccount] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");

    
    var ref = React.createRef();

    useEffect(() => {
        handleLoad();
        const script = document.createElement("script");
        script.setAttribute("data-nscript", "afterInteractive");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.type = "text/javascript";
        var scriptHTML = {
            symbols: defaultSymbols,
            showSymbolLogo: true,
            colorTheme: "light",
            isTransparent: false,
            largeChartUrl: undefined,
            displayMode: "adaptive",
            locale: "in",
        }
        script.innerHTML = JSON.stringify(scriptHTML);
        ref.current.appendChild(script);
        //refValue = ref.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/GetAllCrypto', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setLoader(false);
                    settransferotp(res.data.otp);
                    setAllaccount(res.data.accounts);
                    //setAllcrypto(res.data.cryptodata);
                    setAllcrypto(res.data.usercryptowallet);
                    // setminamount(res.data.transferminamount);
                    // setmaxamount(res.data.transfermaxamount);
                    if (res.data.usercryptowallet.length > 0) {
                        var TempArrfiat = [];
                        res.data.usercryptowallet.forEach(element => {
                            var newcoin = "";
                            var newcoincode = "";
                            var newwalletbalance = "";
                            var newtotalunavailable = "";
                            var newtotalavailable = "";

                            if (element.coin_code === "BTC") {
                                newcoincode = element.coin_code;
                                newcoin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                                newwalletbalance = element.wallet_balance + " BTC";
                                newtotalunavailable = element.total_unavailable + " BTC";
                                newtotalavailable = element.total_available + " BTC";
                            } else if (element.coin_code === "ETH") {
                                newcoin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                                newcoincode = element.coin_code;
                                newwalletbalance = element.wallet_balance + " ETH";
                                newtotalunavailable = element.total_unavailable + " ETH";
                                newtotalavailable = element.total_available + " ETH";
                            } else if (element.coin_code === "USDT") {
                                newcoin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                                newcoincode = element.coin_code;
                                newwalletbalance = element.wallet_balance + " USDT";
                                newtotalunavailable = element.total_unavailable + " USDT";
                                newtotalavailable = element.total_available + " USDT";
                            }

                            var fiatonetemp = {
                                coin: newcoin,
                                coin_code: newcoincode,
                                wallet_balance: newwalletbalance,
                                total_unavailable: newtotalunavailable,
                                total_available: newtotalavailable
                            };
                            TempArrfiat.push(fiatonetemp);

                        });
                        setUserCryptoWallet(TempArrfiat);
                    }

                   
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
    }

    const validateGoogleAuth = (value) => {
        setgoogle_auth(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_error("");
            return true;
        } else {
            setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }

    const validateTransferWalletAddress = (value) => {
        settransfer_wallet_address(value);
        if (!validator.isEmpty(value)) {
            settransfer_wallet_address_error("");
            return true;
        } else {
            settransfer_wallet_address_error(t("TRANSACTION.WALLET_ADDRESS_PLACE"));
            return false;
        }
    }

    const validateTransferAmount = (value) => {
        settransfer_amount(value);
        if (validator.isNumeric(value)) {
            if (parseFloat(maxamount) > 0) {
                if (parseFloat(value) < parseFloat(minamount)) {
                    settransfer_amount_error("Minimum Amount is " + minamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(maxamount)) {
                    settransfer_amount_error("Maximum Amount is " + maxamount);
                    return false;
                }
            }

            settransfer_amount_error("");
            return true;
        } else {
            settransfer_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        }
    }

    const validateTransferEmail = (value) => {
        settransfer_email(value);
        if (validator.isEmail(value)) {
            settransfer_email_error("");
            return true;
        } else {
            settransfer_email_error(t("FIAT.EMAIL_ADDRESS_PLACE"));
            return false;
        }

    }

    const validateTransferCrypto = (value) => {
        const myArray = value.split("|");
        settransfer_crypto(value);
        if (!validator.isEmpty(value)) {
            getlimit(myArray[1]);
            settransfer_crypto_error("");
            return true;
        } else {
            settransfer_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const getlimit = (value) => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            coinname: value,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getbaselimitcrypto', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setminamount(res.mininternallimit);
                    setmaxamount(res.maxinternallimit);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const validateAccount = (value) => {
        setdepo_account(value);
        if (!validator.isEmpty(value)) {
            setdepo_account_error("");
            return true;
        } else {
            setdepo_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }

    }

    const validateCrypto = (value) => {
        setdepo_crypto(value);
        if (!validator.isEmpty(value)) {
            setdepo_crypto_error("");
            return true;
        } else {
            setdepo_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }


    const validateWithAccount = (value) => {
        setwith_account(value);
        if (!validator.isEmpty(value)) {
            setwith_account_error("");
            return true;
        } else {
            setwith_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }

    }

    const validateWithCrypto = (value) => {
        setwith_crypto(value);
        if (!validator.isEmpty(value)) {
            setwith_crypto_error("");
            return true;
        } else {
            setwith_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const validateWithAddress = (value) => {
        setwith_address(value);
        if (!validator.isEmpty(value)) {
            setwith_address_error("");
            return true;
        } else {
            setwith_address_error(t("CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE"));
            return false;
        }
    }


    const depositsubmit = () => {
        if (validateCrypto(depo_crypto) === false) {
            setdepo_account_error("");
            setdepo_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateAccount(depo_account) === false) {
            setdepo_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else {
            setdepo_crypto_error("");
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                cypto_wallet_id: depo_crypto,
                account_id: depo_account,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + '../settings/GenerateNewDepositAdress', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setLoader(false);
                        history.push({
                            pathname: '/wallets-deposit-transaction',
                            state: res.data
                        });
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        }

    }

    const withsubmit = () => {
        if (validateWithCrypto(with_crypto) === false) {
            setwith_account_error("");
            setwith_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateWithAccount(with_account) === false) {
            setwith_crypto_error();
            setwith_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else if (validateWithAddress(with_address) === false) {
            setwith_account_error();
            setwith_address_error(t('CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE'));
            return false;
        } else {
            setLoader(true);
            setwith_address_error("");
            window.localStorage.setItem("cypto_wallet_id", with_crypto);
            window.localStorage.setItem("account_id", with_account);
            window.localStorage.setItem("withaddress", with_address);
            setTimeout(() => {
                setLoader(false);
                history.push('/wallets-withdrawal-transaction');
            }, 2000);

        }
    }

    const transfersubmit = () => {
        if (validateTransferCrypto(transfer_crypto) === false) {
            settransfer_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateTransferEmail(transfer_email) === false) {
            settransfer_crypto_error("");
            settransfer_email_error(t("FIAT.EMAIL_ADDRESS_PLACE"));
            return false;
        } else if (validateTransferAmount(transfer_amount) === false) {
            settransfer_email_error("");
            settransfer_amount_error(t('TRANSACTION.AMOUNT_PLACE'));
            return false;
        } else if (validateTransferWalletAddress(transfer_wallet_address) === false) {
            settransfer_amount_error("");
            settransfer_wallet_address_error(t('CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE'));
            return false;
        } else {
            setwith_address_error("");
            setgoogle_auth("");
            if (parseFloat(transfer_amount) <= 0) {
                settransfer_amount_error("You must enter amount greater than zero");
                return false;
            }
            settransfer_amount_error("");
            if (transferotp === "Google Authentication") {
                setOTPVerification(true);
            } else if (transferotp === "SMS OTP Authentication") {
                setSMSOTPVerification(true);
            } else {
                finalsubmit();
            }
        }

    }

    const finalsubmit = () => {
        if (transferotp === "Google Authentication") {
            if (validateGoogleAuth(google_auth) === false) {
                setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        } if (transferotp === "SMS OTP Authentication") {
            if (validateCodeTransfer(transferotpmessage) === false) {
                settransferotpmessage_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }

        setgoogle_auth_error("");
        settransferotpmessage_error("");
        if (parseFloat(transfer_amount) <= 0) {
            settransfer_amount_error("You must enter amount greater than zero");
            return false;
        }
        settransfer_amount_error("");
        var accountid = transfer_crypto.split("|");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            auth_code: google_auth,
            transfer_crypto: accountid[0],
            transfer_email: transfer_email,
            transfer_amount: transfer_amount,
            transfer_wallet_address: transfer_wallet_address,
            smscode: transferotpmessage
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/submitTransfer', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                setOTPVerification(false);
                setSMSOTPVerification(false);
                if (res.code === "200") {
                    setLoader(false);

                    setsuccess_display_amount(res.data.success_display_amount);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_toaccount(res.data.success_display_toaccount);
                    setsuccess_display_details(res.data.success_display_details);
                    setTransferAccept(true);

                    settransfer_crypto("");
                    settransfer_email("");
                    settransfer_amount("");
                    settransfer_wallet_address("");
                    setgoogle_auth("");
                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
    }

    const validateCodeTransfer = (value) => {
        settransferotpmessage(value);
        if (!validator.isEmpty(value)) {
            settransferotpmessage_error("");
            return true;
        } else {
            settransferotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handleChangeCSVNdPDFthird = (val) => {
        var FileName = "Crypto Accounts";

        var columns = [
            { title: t("CRYPTOTRANSACTION.COIN_CODE"), field: 'coin_code' },
            { title: t("CRYPTOTRANSACTION.WALLET_BALANCE"), field: 'wallet_balance' },
            { title: t("CRYPTOTRANSACTION.TOTAL_UNAVAIL"), field: 'total_unavailable' },
            { title: t("CRYPTOTRANSACTION.TOTAL_AVAIL"), field: 'total_available' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = allUsercrypto.map(rowData =>
            [rowData["coin_code"],
            rowData["wallet_balance"],
            rowData["total_unavailable"],
            rowData["total_available"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    return (
        <>
            {snackbar ? <Snackbars open_close={snackbar} type="success" message={snackbar_msg} /> : ""}
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}


            <div className="breaking-news bg-primary" id="tickerid">
                <div ref={ref} />
            </div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t("CRYPTOTRANSACTION.CRYPTOACCOUNT")}</h2>
                </div>
            </div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body hide-scrolllbar">
                        <div className="position-relative">
                            <div className="table-responsive ">
                                {allUsercrypto.length > 0 ?
                                    <MaterialTable
                                        title={t("CRYPTOTRANSACTION.CRYPTOACCOUNT")}
                                        columns={[
                                            { title: t("CRYPTOTRANSACTION.COIN"), field: 'coin' },
                                            { title: t("CRYPTOTRANSACTION.COIN_CODE"), field: 'coin_code' },
                                            { title: t("CRYPTOTRANSACTION.WALLET_BALANCE"), field: 'wallet_balance' },
                                            { title: t("CRYPTOTRANSACTION.TOTAL_AVAIL"), field: 'total_available' },
                                        ]}
                                        data={allUsercrypto}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDFthird("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDFthird("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Crypto Accounts Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ul className="nav nav-tabs crypto-tabs-btn">
                <li className="nav-item" onClick={() => setActiveToggle("posts")}>
                    <Link to="#01" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}><i className="flaticon-381-folder-3"></i>&nbsp; {t("CRYPTOTRANSACTION.DEPOSIT_TITLE")}</Link>
                </li>
                <li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
                    <Link to="#02" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}><i className="flaticon-381-folder-4"></i>&nbsp; {t("CRYPTOTRANSACTION.WITHDRAW_TITLE")}</Link>
                </li>
                <li className="nav-item">
                    <Link to="#03" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}><i className="fa fa-exchange mt-1"></i>&nbsp; {t("CRYPTOTRANSACTION.TRANSFER_TITLE")}</Link>
                </li>

            </ul>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="custom-tab-1 setTabDesignbtn">

                            <div className="tab-content">
                                <div id="01" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
                                    <div className="profile-personal-info mt-4">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.SELECTCOIN")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateCrypto(e.target.value)} value={depo_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.crypto_id} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>

                                                </div>
                                                {depo_crypto_error !== "" ? <label className='text-red'>{depo_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t('FIAT.SELECT_ACCOUNT')}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateAccount(e.target.value)} value={depo_account}>
                                                        <option value="">{t('FIAT.SELECT_ACCOUNT')}</option>
                                                        {allaccount && allaccount.map((row) => (
                                                            <option value={row.account_id_pk} key={row.account_id_pk}>{t('KYC.MYACCOUNT')} : {row.account_number} ( {row.available_balance} {row.account_currency_type === "USD" ? '$' : '€'} ) </option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {depo_account_error !== "" ? <label className='text-red'>{depo_account_error}</label> : ""}
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <button type="button" onClick={depositsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                                <Link to="/fiat" className="btn btn-outline-light ml-3 btn-rounded">{t("BUTTONS.CANCEL")}</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="02" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
                                    <div className="profile-personal-info mt-4">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.SELECTCOIN")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateWithCrypto(e.target.value)} value={with_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.crypto_id} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {with_crypto_error !== "" ? <label className='text-red'>{with_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t('FIAT.SELECT_ACCOUNT')}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateWithAccount(e.target.value)} value={with_account}>
                                                        <option value="">{t('FIAT.SELECT_ACCOUNT')}</option>
                                                        {allaccount && allaccount.map((row) => (
                                                            <option value={row.account_id_pk} key={row.account_id_pk}>{t('KYC.MYACCOUNT')} : {row.account_number} ( {row.available_balance} {row.account_currency_type === "USD" ? '$' : '€'} ) </option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {with_account_error !== "" ? <label className='text-red'>{with_account_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">{t("CRYPTOTRANSACTION.WALLET_ADDRESS")} </label>
                                                    <input type="text" className="form-control" onChange={(e) => validateWithAddress(e.target.value)} value={with_address} placeholder={t("CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE")} />
                                                    {with_address_error !== "" ? <label className='text-red'>{with_address_error}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <button type="button" onClick={withsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                                <Link to="/fiat" className="btn btn-outline-light ml-3 btn-rounded">{t("BUTTONS.CANCEL")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="03" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
                                    <div className="profile-personal-info mt-4">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.SELECTCOIN")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateTransferCrypto(e.target.value)} value={transfer_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.crypto_wallet_id + "|" + row.coin_code} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {transfer_crypto_error !== "" ? <label className='text-red'>{transfer_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">{t("HEADER.CUS_EMAIL")}</label>
                                                    <input type="text" className="form-control" onChange={(e) => validateTransferEmail(e.target.value)} value={transfer_email} placeholder={t("FIAT.EMAIL_ADDRESS_PLACE")} />
                                                    {transfer_email_error !== "" ? <label className='text-red'>{transfer_email_error}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">{t("TRANSACTION.AMOUNT")}</label>
                                                    <input type="text" className="form-control" onChange={(e) => validateTransferAmount(e.target.value)} value={transfer_amount} placeholder={t("TRANSACTION.AMOUNT_PLACE")} />
                                                    {transfer_amount_error !== "" ? <label className='text-red'>{transfer_amount_error}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">{t("CRYPTOTRANSACTION.WALLET_ADDRESS")} </label>
                                                    <input type="text" className="form-control" onChange={(e) => validateTransferWalletAddress(e.target.value)} value={transfer_wallet_address} placeholder={t("CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE")} />
                                                    {transfer_wallet_address_error !== "" ? <label className='text-red'>{transfer_wallet_address_error}</label> : ""}
                                                </div>
                                            </div>
                                            {parseFloat(maxamount) > 0 ?
                                                <div className="col-md-12 mt-4">
                                                    <label className="mb-2 minmaxclass">Minimum Amount = {minamount} </label>
                                                    <label className="mb-2 ml-3 minmaxclass">Maximum Amount = {maxamount} </label>
                                                </div>
                                                : ""}
                                            <div className="col-md-12 mt-4">
                                                <button type="button" onClick={transfersubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                                <Link to="/fiat" className="btn btn-outline-light ml-3 btn-rounded">{t("BUTTONS.CANCEL")}</Link>
                                            </div>

                                            <Modal className="modal fade" show={OTPVerification}>
                                                <div className="modal-dialog-centered " >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                                            <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="mb-2 ">{t("FIAT.OTP_CODE_TITLE")}</label>
                                                                        <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuth(e.target.value)} value={google_auth} placeholder={t("FIAT.OTP_CODE_PLACE")} />
                                                                        {google_auth_error !== "" ? <label className='text-red'>{google_auth_error}</label> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t("BUTTONS.CANCEL")}</button>
                                                            <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>

                                            <Modal className="modal fade" show={SMSOTPVerification}>
                                                <div className="modal-dialog-centered " >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                                            <button type="button" className="close" onClick={() => setSMSOTPVerification(false)}><span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                                                        <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCodeTransfer(e.target.value)} value={transferotpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                                                        <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                                                        <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                                                    </div>
                                                                    {transferotpmessage_error !== "" ? <label className='text-red'>{transferotpmessage_error}</label> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setSMSOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                                            <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>

                                            <Modal className="modal fade" show={TransferAccept}>
                                                <div className="modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h3 className="modal-title">Crypto Fund Transfer</h3>
                                                            <button type="button" className="close" onClick={() => history.push("/crypto")}><span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="check">
                                                                <i class="fa fa-solid fa-check"></i>
                                                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                                                            </div>

                                                            {success_display_refno === "" || success_display_refno === undefined || success_display_refno === null ? " " :
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-12 mb-1">
                                                                            <p className="fs-16 mb-1">Amount : <span className="text-black">{success_display_amount}</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 mb-1">
                                                                            <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-12 mb-1">
                                                                            <p className="fs-16 mb-1">Transfer to : <span className="text-black">{success_display_toaccount}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="row mt-1">
                                                                <div className="col-md-12 mb-3">
                                                                    <p className="fs-16 mb-1">Details</p>
                                                                    <span className="text-black">{success_display_details}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 mb-2">
                                                                <ul className="btm-link">
                                                                    <li>
                                                                        <Link to="#" onClick={() => setTransferAccept(false)}> New Crypto Transfer</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/dashboard"> Dashboard</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                            <Modal className="modal fade" show={TransferDecline}>
                                                <div className="modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h3 className="modal-title">Crypto Fund Transfer</h3>
                                                            <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="check">
                                                                <i class="fa fa-solid fa-times"></i>
                                                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12 mb-1">
                                                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-1">
                                                                <div className="col-md-12 mb-3">
                                                                    <p className="fs-16 mb-1">Details</p>
                                                                    <span className="text-black">{error_display_details}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 mb-2">
                                                                <ul className="btm-link">
                                                                    <li>
                                                                        <Link to="#" onClick={() => setTransferDecline(false)}> Retry </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/dashboard"> Dashboard</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card p-0">
                        <div className="custom-tab-1 setTabDesignbtn">
                            <Crypto_Transactions></Crypto_Transactions>
                        </div>
                    </div>
                </div>
            }


        </>
    )

}
export default Crypto;