import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error500 = () => {
   const { t } = useTranslation();
   return (
      <div className='justify-content-center h-100 align-items-center mt-5'>
         <div className='form-input-content text-center error-page center-error-box'>
            <h1 className="error-text font-weight-bold">500</h1>
            <h4>
               <i className="fa fa-times-circle text-danger" />{" "}
               {t('ERROR_PAGE_TEXT.INTERNAL_ERROR')}
            </h4>
            <p> {t('ERROR_PAGE_TEXT.NOT_RES')}</p>
            <div>
               <Link className="btn btn-primary" to="/dashboard">
                  {t('ERROR_PAGE_TEXT.BACKHOME')}
               </Link>
            </div>
         </div>
      </div>
   );
};

export default Error500;
