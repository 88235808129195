import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Link } from 'react-router-dom';
import { Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from '../../pages/Snackbars';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import validator from 'validator';
import moment from 'moment';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';



export const Fiat_Incoming_Transfers = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [EURModal, setEURModal] = useState(false);
    const [modal_eur, setmodal_eur] = useState("");
    const [eurmodalemail_error, seteurmodalemail_error] = useState("");
    const [accountarray, setAccountarray] = useState([]);
    const [holdername, setholdername] = useState("");
    const [account_number, setaccount_number] = useState("");
    const [routing_number, setrouting_number] = useState("");
    const [swiftcode, setswiftcode] = useState("");
    const [bankname, setbankname] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [DepositFund, setDepositFund] = useState(false);
    const [fundamount, setfundamount] = useState("");
    const [fundamount_error, setfundamount_error] = useState("");
    const [fundinfo, setfundinfo] = useState("");
    const [fundinfo_error, setfundinfo_error] = useState("");
    const [fundaccid, setfundaccid] = useState("");
    const [DepositFundView, setDepositFundView] = useState(false);
    const [fundarray, setfundarray] = useState([]);

    const [popupaccount, setpopupaccount] = useState("");
    const [popupamount, setpopupamount] = useState("");
    const [popupdate, setpopupdate] = useState("");
    const [popupinfo, setpopupinfo] = useState("");
    const [popuphash, setpopuphash] = useState("");



    useEffect(() => {
        handleLoad();
        handleFundLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getuseraccoundsbyid/' + window.sessionStorage.getItem("user_id"), { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAccountarray(res.data);
                    if (res.data.length > 0) {
                        setfundaccid(res.data[0].account_id_pk);
                        setholdername(res.data[0].customer_name);
                        setaccount_number(res.data[0].account_number);
                        setrouting_number(res.data[0].routing_number);
                        setswiftcode(res.data[0].swift_code);
                        setbankname(res.data[0].bank_name);
                    }
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const getfunddetails = (fundid) => {
        setpopupaccount(fundid.account_number);
        setpopupamount(parseFloat(fundid.fund_amount).toFixed(2) + " " + fundid.account_currency_type);
        setpopupdate(moment(fundid.created_datetime).format('MM / DD / YYYY hh:mm:ss'));
        setpopupinfo(fundid.information);
        setpopuphash("#" + fundid.transaction_hash);
        setDepositFundView(true);
    }

    const handleFundLoad = () => {
        let jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + "getDepositFund", jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var TempArrfiat = [];
                    res.data.forEach(element => {
                        var status = <div> <button className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
                        if (element.withdraw_status === "Completed") {
                            status = <div> <button className="btn btn-outline-success btn-rounded">Completed</button> </div>
                        } else if (element.withdraw_status === "Failed") {
                            status = <div> <button className="btn btn-outline-danger btn-rounded">Failed</button> </div>
                        }
                        var fiatonetemp = {
                            date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            depositamount: parseFloat(element.fund_amount).toFixed(2) + " " + element.account_currency_type,
                            Action: <button onClick={() => getfunddetails(element)} className="btn btn-light btn-rounded btn-xs"><i className="fa fa-eye"></i> View Transaction</button>,
                            Status: status,
                            StatusText: element.withdraw_status,
                        };
                        TempArrfiat.push(fiatonetemp);
                    });
                    setfundarray(TempArrfiat);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const getaccountvalue = (value) => {
        setfundaccid(value);
        if (value === accountarray[0].account_id_pk) {
            setholdername(accountarray[0].customer_name);
            setaccount_number(accountarray[0].account_number);
            setrouting_number(accountarray[0].routing_number);
            setswiftcode(accountarray[0].swift_code);
            setbankname(accountarray[0].bank_name);
        }

        if (value === accountarray[1].account_id_pk) {
            setholdername(accountarray[1].customer_name);
            setaccount_number(accountarray[1].account_number);
            setrouting_number(accountarray[1].routing_number);
            setswiftcode(accountarray[1].swift_code);
            setbankname(accountarray[1].bank_name);
        }
    }

    const exportPdf = () => {
        html2canvas(document.querySelector("#capture")).then(canvas => {
            document.body.appendChild(canvas);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("Deposit_Account_" + Math.floor(100000 + Math.random() * 900000) + ".pdf");
            canvas.remove();
        });

    }

    const sendaccinfo = () => {
        if (validateEURModal(modal_eur) === false) {
            seteurmodalemail_error(t("PLACEHOLDER.PLACEHOLDER_EMAIL"));
        } else {
            seteurmodalemail_error("");
            setEURModal(false);
            var text = t("FIAT.INCOMMING_NOTE") + "<br><br>" +

                t("FIAT.FIAT_DEPOSIT_LABEL1") + " : " + holdername + "<br>" +

                t("FIAT.FIAT_DEPOSIT_LABEL2") + " : " + account_number + "<br>" +

                t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + routing_number + "<br>" +

                t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + swiftcode + "<br>" +

                t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + bankname + "<br><br>" +

                t("FIAT.INCOMMING_NOTE_TWO");

            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                emailtext: text,
                email_id: modal_eur
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + '../settings/sendaccountinfoemail', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setmodal_eur("");
                        setTimeout(() => {
                            setSuccess(false);
                        }, 6000);
                    } else {
                        setmodal_eur("");
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    }
                })
                .catch(function (error) {
                    setmodal_eur("");
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                });
        }

    }

    const validateEURModal = (value) => {
        setmodal_eur(value);
        if (validator.isEmail(value)) {
            seteurmodalemail_error("");
            return true;
        } else {
            seteurmodalemail_error(t('PLACEHOLDER.PLACEHOLDER_EMAIL'));
            return false;
        }
    }

    const clickclip = () => {
        setSuccess(true);
        setSuccessMsg(t("CRYPTOTRANSACTION.COPIEDMSGS"));
        setTimeout(() => {
            setSuccess(false);
        }, 6000);
    }

    const validateFundAmount = (value) => {
        setfundamount(value);
        if (validator.isNumeric(value)) {
            setfundamount_error("");
            return true;
        } else {
            setfundamount_error("Please Enter Amount");
            return false;
        }
    }

    const validateFundInfo = (value) => {
        setfundinfo(value);
        if (!validator.isEmpty(value)) {
            setfundinfo_error("");
            return true;
        } else {
            setfundinfo_error("Please Enter Valid Fund Information");
            return false;
        }
    }

    const handlefund = () => {
        if (validateFundAmount(fundamount) === false) {
            setfundamount_error("Please Enter Amount");
            return false;
        } else if (validateFundInfo(fundinfo) === false) {
            setfundamount_error("");
            setfundinfo_error("Please Enter Valid Fund Information");
            return false;
        } else {
            setfundinfo_error("");
            if(fundamount <= 0){
                setfundamount_error("Please Enter Amount grether than zero");
                return false;
            }
            setfundamount_error("");
            let jsonpar = {
                "user_id": window.sessionStorage.getItem("user_id"),
                "fundamount": fundamount,
                "fundinfo": fundinfo,
                "account_id": fundaccid,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'submitfunddeposit', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setfundamount("");
                        setfundinfo("");
                        setDepositFund(false);
                        setTimeout(() => {
                            setLoader(false);
                            setSuccess(true);
                            setSuccessMsg(res.message);
                            setTimeout(() => {
                                setSuccess(false);
                            }, 6000);
                        }, 1000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });

        }
    }

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Transactions";

        var columns = [
            { title: 'Date', field: 'date' },
            { title: 'Deposit Amount', field: 'depositamount' },
            { title: 'Status', field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = fundarray.map(rowData =>
            [rowData["date"],
            rowData["depositamount"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header page-title-space">
                        <h2 className="text-black font-w600 mb-0">{t('FIAT.INCOMING_TITLE')}</h2>
                        <Link to="/fiat" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t('LINK.BACK')}</Link>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="row position-relative z-111">
                            <div className="col-md-12">
                                <div className="form-group position-relative">
                                    <h4 className="card-title mb-3">{t('FIAT.SELECT_DEPOIST_ACCOUNT')}</h4>
                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => getaccountvalue(e.target.value)}>
                                        {accountarray.length > 0 ?
                                            <>
                                                <option value={accountarray[0].account_id_pk}>{t('KYC.MYACCOUNT')} : {accountarray[0].account_number} ( {accountarray[0].available_balance} $ )</option>
                                                <option value={accountarray[1].account_id_pk}>{t('KYC.MYACCOUNT')} : {accountarray[1].account_number} ( {accountarray[1].available_balance} € )</option>
                                            </>
                                            :
                                            <option value="">{t('FIAT.ACCOUNT_NOTFOUND')}</option>
                                        }
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 p-0">
                            <div className="mt-4 flex-wrap border-0 pb-0 align-items-end">
                                <Dropdown className="mb-auto">
                                    <Dropdown.Toggle variant="" as="div" className="btn-link i-false share-info" >
                                        <i className="fa fa-share-alt-square" aria-hidden="true" style={{ marginRight: '13px' }}></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu alignRight={true} className="dropdown-menu-right">
                                        <Dropdown.Item onClick={() => setEURModal(true)}>{t('HEADER.EMAIL')}</Dropdown.Item>
                                        <Dropdown.Item onClick={exportPdf}>{t('HEADER.PDF')}</Dropdown.Item>
                                        <CopyToClipboard text={t("FIAT.INCOMMING_NOTE") + "\n" +

                                            t("FIAT.FIAT_DEPOSIT_LABEL1") + " : " + holdername + "\n" +

                                            t("FIAT.FIAT_DEPOSIT_LABEL2") + " : " + account_number + "\n" +

                                            t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + routing_number + "\n" +

                                            t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + swiftcode + "\n" +

                                            t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + bankname + "\n" +

                                            t("FIAT.INCOMMING_NOTE_TWO")}>
                                            <Dropdown.Item onClick={clickclip}>{t('HEADER.CLIPBOARD')}</Dropdown.Item>
                                        </CopyToClipboard>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="accordion-body-text mt-2" id="capture">
                                    <p className="instructions">{t('FIAT.INCOMMING_NOTE')}</p>
                                    <p className="instructions-details">{t('FIAT.FIAT_DEPOSIT_LABEL1')}: {holdername}</p>
                                    <p className="instructions-details">{t('FIAT.FIAT_DEPOSIT_LABEL2')}: {account_number}</p>
                                    <p className="instructions-details">{t('FIAT.FIAT_DEPOSIT_LABEL3')}: {routing_number}</p>
                                    <p className="instructions-details">{t('FIAT.FIAT_DEPOSIT_LABEL4')}: {swiftcode}</p>
                                    <p className="instructions-details">{t('FIAT.FIAT_DEPOSIT_LABEL5')}: {bankname}</p>
                                    <p className="instructions-details-btm mt-2">{t('FIAT.INCOMMING_NOTE_TWO')}</p>
                                </div>
                                <Link to="#" className="btn btn-primary btn-rounded" onClick={() => setDepositFund(true)}><i className="flaticon-381-folder-3"></i>&nbsp; Deposit Fund</Link>

                            </div>

                        </div>
                    </div>
                    <Modal className="modal fade" show={EURModal}>
                        <div className="modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">{t('KYC.SEND_ACC_CRED')}</h3>
                                    <button type="button" className="close" onClick={() => setEURModal(false)}><span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('LABEL.LABEL_EMAIL')}</label>
                                            <input type="text" className="form-control" value={modal_eur} onChange={(e) => validateEURModal(e.target.value)} placeholder={t('PLACEHOLDER.PLACEHOLDER_EMAIL')} />
                                            {eurmodalemail_error !== "" ? <label className='text-red'>{eurmodalemail_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light btn-rounded light" onClick={() => setEURModal(false)}>{t('BUTTONS.CANCEL')}</button>
                                    <button type="button" onClick={sendaccinfo} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal className="modal fade" show={DepositFund}>
                        <div className="modal-dialog-centered " >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Deposit Fund</h3>
                                    <button type="button" className="close" onClick={() => setDepositFund(false)}><span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <div className="form-group">
                                                <label className="mb-2">Amount</label>
                                                <input type="text" className="form-control" placeholder="Please Enter Amount" onChange={(e) => validateFundAmount(e.target.value)} />
                                                {fundamount_error !== "" ? <label className='text-red'>{fundamount_error}</label> : ""}

                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="form-group">
                                                <label className="mb-2">Payment Information</label>
                                                <textarea type="text" rows={4} className="form-control" placeholder="Please Payment Information" onChange={(e) => validateFundInfo(e.target.value)} />
                                                {fundinfo_error !== "" ? <label className='text-red'>{fundinfo_error}</label> : ""}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 mb-2">
                                                <div className="form-group">
                                                    <label className="mb-2">Payment Proof</label>
                                                    <input type="file" className="form-control" style={{ paddingTop: '7px', paddingLeft: '7px' }} />
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light btn-rounded light" onClick={() => setDepositFund(false)}>Close</button>
                                    <button className="btn btn-primary btn-rounded" onClick={handlefund}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <div className="card hide-scrolllbar">
                        <div className="card-body">
                            <div className="position-relative">
                                <div className="table-responsive">
                                    <MaterialTable
                                        title="Transactions"
                                        columns={[
                                            { title: 'Date', field: 'date' },
                                            { title: 'Deposit Amount', field: 'depositamount' },
                                            { title: 'Action', field: 'Action' },
                                            { title: 'Status', field: 'Status'},
                                        ]}
                                        data={fundarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal fade" show={DepositFundView}>
                        <div className="modal-dialog-centered " >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Fiat Deposit Request</h3>
                                    <button type="button" className="close" onClick={() => setDepositFundView(false)}><span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <p className="fs-14 mb-1">Date</p>
                                            <span className="text-black">{popupdate}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <p className="fs-14 mb-1">Deposit Amount</p>
                                            <span className="text-black">{popupamount}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <p className="fs-14 mb-1">Bank account</p>
                                            <span className="text-black">{popupaccount}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <p className="fs-14 mb-1">Transaction hash</p>
                                            <span className="text-black">{popuphash}</span>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <p className="fs-14 mb-1">Payment Information</p>
                                            <span className="text-black">{popupinfo}</span>
                                        </div>
                                        {/* <div className="col-md-12 mb-3">
                                            <p className="fs-14 mb-1">Payment Proof</p>
                                            <img src="https://s3-alpha.figma.com/hub/file/948140848/1f4d8ea7-e9d9-48b7-b70c-819482fb10fb-cover.png" alt="" className="dot-img img-fluid" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </>
    )

}
export default Fiat_Incoming_Transfers;