import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import Loader from '../../pages/Loader';
import validator from 'validator';
import moment from 'moment';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap'

export const Verify_Your_Account = () => {
    const { t } = useTranslation();
    const [AddCustomer, setAddCustomer] = useState(false);
    const [ModalDocType, setModalDocType] = useState(false);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [allcountry, setAllCountry] = useState([]);
    const [allstate, setAllState] = useState([]);

    const [first_name, setFirst_name] = useState("");
    const [middle_name, setMiddleName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phonecode, setPhonecode] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [dob, setDob] = useState("");
    const [taxssn, setTexssn] = useState("");
    const [taxcountry, setTaxcountry] = useState("");
    const [taxstate, setTaxstate] = useState("");
    const [country, setCountry] = useState("");
    const [addressline1, setAddressline1] = useState("");
    const [addressline2, setAddressline2] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [zipcode, setZipcode] = useState("");

    const [aml_kyc_status, setAml_kyc_status] = useState("");
    const [cip_kyc_status, setCip_kyc_status] = useState("");
    const [iddoc_kyc_status, setIddoc_kyc_status] = useState("");
    const [address_kyc_status, setAddress_kyc_status] = useState("");
    const [aml_approved_datetime, setAml_approved_datetime] = useState("");
    const [cip_approved_datetime, setCip_approved_datetime] = useState("");
    const [iddoc_approved_datetime, setIddoc_approved_datetime] = useState("");
    const [address_approved_datetime, setAddress_approved_datetime] = useState("");
    const [created_at, setCreated_at] = useState("");
    const [updated_at, setUpdated_at] = useState("");
    const [kyc_staus, setKyc_staus] = useState("");

    const [first_name_error, setFirst_name_error] = useState("");
    const [middle_name_error, setMiddleName_error] = useState("");
    const [last_name_error, setLastName_error] = useState("");
    const [email_error, setEmail_error] = useState("");
    const [phonenumber_error, setPhonenumber_error] = useState("");
    const [dob_error, setDob_error] = useState("");
    const [taxssn_error, setTaxssn_error] = useState("");
    const [taxcountry_error, setTaxcountry_error] = useState("");
    const [taxstate_error, setTaxstate_error] = useState("");
    const [country_error, setCountry_error] = useState("");
    const [addressline1_error, setAddressline1_error] = useState("");
    const [addressline2_error, setAddressline2_error] = useState("");
    const [city_error, setCity_error] = useState("");
    const [region_error, setRegion_error] = useState("");
    const [zipcode_error, setZipcode_error] = useState("");
    const [kyc_id, setKyc_id] = useState(0);
    const [loader, setLoader] = useState(false);
    const [documentarray, setDocumentArray] = useState([]);
    const [documentdetails, setDocumentDetails] = useState([]);
    const [documentcountryname, setDocumentCountryname] = useState("");
    const [documenttypename, Setdocumenttypename] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [fileName_error, setFileName_error] = useState("");
    const [alldoctype, setAlldoctype] = useState([]);
    const [accountarray, setAccountarray] = useState([]);


    const [modal_doc_type, setmodal_doc_type] = useState("");
    const [modal_text, setmodal_text] = useState("");
    const [modal_country, setmodal_country] = useState("");
    const [modal_front_doc, setmodal_front_doc] = useState("");
    const [modal_back_doc, setmodal_back_doc] = useState("");

    const [modal_doc_type_error, setmodal_doc_type_error] = useState("");
    const [modal_text_error, setmodal_text_error] = useState("");
    const [modal_country_error, setmodal_country_error] = useState("");
    const [modal_front_doc_error, setmodal_front_doc_error] = useState("");
    const [modal_back_doc_error, setmodal_back_doc_error] = useState("");

    const [documentfrontfilename, setdocumentfrontfilename] = useState("");
    const [documentbackfilename, setdocumentbackfilename] = useState("");



    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleupload = () => {
        const formData = new FormData();
        formData.append("user_id", window.sessionStorage.getItem("user_id"));
        formData.append("kyc_id", kyc_id);
        if (fileName !== "") {
            setFileName_error("");
            formData.append("file", file);
            formData.append("fileName", fileName);


            const headers = {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                'Content-Length': '<calculated when request is sent>',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);

            axios.post(process.env.REACT_APP_API_URL + 'updateDoc', formData, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setFile("");
                        setFileName("");
                        setTimeout(() => {
                            handleLoad();
                            setSuccess(false);
                        }, 1000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        } else {
            setFileName_error(t('ERROR_MSG.ERROR_FILENAME'));
            return false;
        }
    }

    const handleLoad = () => {
        const jsonpar = {
            "user_id": window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getKYCDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllCountry(res.data.countryarray);
                    setAlldoctype(res.data.getalldoctype);
                    if (res.data.kyc_id <= 0) {
                        setPhonecode("+91");
                    }
                    //setAllState(res.data.statearray);
                    if (res.data.kyc_id > 0) {

                        setKyc_id(res.data.kyc_id);
                        setFirst_name(res.data.first_name);
                        setMiddleName(res.data.middle_name);
                        setLastName(res.data.last_name);
                        setEmail(res.data.email);
                        setPhonecode(res.data.phonecode);

                        setPhoneNumber(res.data.phone_number);
                        if (res.data.dob === null) {
                            setDob("");
                        } else {
                            setDob(res.data.dob);
                        }
                        setTexssn(res.data.taxssn);
                        setTaxcountry(res.data.tax_country_id);
                        getallStates(res.data.tax_country_id);
                        setTaxstate(res.data.tax_state_id);
                        setAddressline1(res.data.address1);
                        setAddressline2(res.data.address2);
                        setCountry(res.data.country_id);
                        setRegion(res.data.region);
                        setZipcode(res.data.postalcode);
                        setCity(res.data.city);
                        setAml_kyc_status(res.data.aml_kyc_status);
                        setCip_kyc_status(res.data.cip_kyc_status);
                        setIddoc_kyc_status(res.data.iddoc_kyc_status);
                        setAddress_kyc_status(res.data.address_kyc_status);
                        setAml_approved_datetime(res.data.aml_approved_datetime);
                        setCip_approved_datetime(res.data.cip_approved_datetime);
                        setIddoc_approved_datetime(res.data.iddoc_approved_datetime);
                        setAddress_approved_datetime(res.data.address_approved_datetime);
                        setCreated_at(res.data.created_at);
                        setUpdated_at(res.data.updated_at);
                        setKyc_staus(res.data.mainkycstatus);
                        setDocumentArray(res.data.documentarray);
                        setDocumentDetails(res.data.documentdetails);
                        setDocumentCountryname(res.data.documentcountryname);
                        Setdocumenttypename(res.data.documenttypename);
                        setdocumentfrontfilename(res.data.documenttypefrontfilename);
                        setdocumentbackfilename(res.data.documenttypebackfilename);
                        if (res.data.accountsarray.length > 0) {
                            setAccountarray(res.data.accountsarray);
                        }

                    } else {


                        const headers = {
                            'Content-Type': 'application/json',
                            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                        }
                        setLoader(true);
                        axios.get(process.env.REACT_APP_API_URL + 'getUserProfileDetails/' + window.sessionStorage.getItem("user_id"), { headers: headers })
                            .then(function (res) {
                                res = res.data;
                                if (res.code === "200") {
                                    setFirst_name(res.data.first_name);
                                    setLastName(res.data.last_name);
                                    setEmail(res.data.email_id);
                                    setPhonecode(res.data.phonecode);
                                    setPhoneNumber(res.data.phone_number);
                                }
                            })
                            .catch(function (error) {

                            });

                    }
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);

                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateModal_Doctype = (value) => {
        setmodal_doc_type(value);
        if (!validator.isEmpty(value)) {
            if (value === "3") {
                setModalDocType(true);
            } else {
                setModalDocType(false);
            }
            setmodal_doc_type_error("");
            return true;
        } else {
            setmodal_doc_type_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        }
    }
    const validateModal_Country = (value) => {
        setmodal_country(value);
        if (!validator.isEmpty(value)) {
            setmodal_country_error("");
            return true;
        } else {
            setmodal_country_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        }
    }

    const validateModal_Frontdoc = (value) => {
        setmodal_front_doc(value);
        if (!validator.isEmpty(value)) {
            setmodal_front_doc_error("");
            return true;
        } else {
            setmodal_front_doc_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        }
    }

    const validateModal_Backdoc = (value) => {
        setmodal_back_doc(value);
        if (!validator.isEmpty(value)) {
            setmodal_back_doc_error("");
            return true;
        } else {
            setmodal_back_doc_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        }
    }

    const validateModal_Text = (value) => {
        setmodal_text(value);
        if (!validator.isEmpty(value)) {
            setmodal_text_error("");
            return true;
        } else {
            setmodal_text_error(t('ERROR_MSG.ERROR_DOCNAME'));
            return false;
        }
    }


    const getallStates = (counryid) => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getStatesFromCountry/' + counryid, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllState(res.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateFirstName = (value) => {
        setFirst_name(value);
        if (!validator.isEmpty(value)) {
            setFirst_name_error("");
            return true;
        } else {
            setFirst_name_error(t('ERROR_MSG.ERROR_FIRSTNAME'));
            return false;
        }
    }

    const validateLastName = (value) => {
        setLastName(value);
        if (!validator.isEmpty(value)) {
            setLastName_error("");
            return true;
        } else {
            setLastName_error(t('ERROR_MSG.ERROR_LASTNAME'));
            return false;
        }
    }

    const validateMiddleName = (value) => {
        setMiddleName(value);
        if (!validator.isEmpty(value)) {
            setMiddleName_error("");
            return true;
        } else {
            setMiddleName_error(t('ERROR_MSG.ERROR_MIDDLENAME'));
            return false;
        }
    }

    const validateEmail = (value) => {
        setEmail(value);
        if (validator.isEmail(value)) {
            setEmail_error("");
            return true;
        } else {
            setEmail_error(t('ERROR_MSG.CORRECT_EMAIL'));
            return false;
        }
    }


    const validatePhonenumber = (value) => {
        setPhoneNumber(value);
        if (validator.isMobilePhone(value)) {
            setPhonenumber_error("");
            return true;
        } else {
            setPhonenumber_error(t('ERROR_MSG.ERROR_PHONENUMBER'));
            return false;
        }
    }

    const validateDOB = (value) => {
        setDob(value);
        if (validator.isDate(value)) {
            setDob_error("");
            return true;
        } else {
            setDob_error(t('ERROR_MSG.ERROR_DOB'));
            return false;
        }
    }

    const validateTaxssn = (value) => {
        setTexssn(value);
        if (!validator.isEmpty(value)) {
            setTaxssn_error("");
            return true;
        } else {
            setTaxssn_error(t('ERROR_MSG.ERROR_TAXSSN'));
            return false;
        }
    }

    const validateTaxCountry = (value) => {
        setTaxcountry(value);
        if (!validator.isEmpty(value)) {
            getallStates(value);
            setTaxstate("");
            setTaxcountry_error("");
            return true;
        } else {
            setTaxcountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }

    const validateCountrysecond = () => {
        if (!validator.isEmpty(country)) {
            setTaxcountry_error("");
            return true;
        } else {
            setTaxcountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }


    const validateTaxState = (value) => {
        setTaxstate(value);
        if (!validator.isEmpty(value)) {
            setTaxstate_error("");
            return true;
        } else {
            setTaxstate_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        }
    }

    const validateCountry = (value) => {
        setCountry(value);
        if (!validator.isEmpty(value)) {
            setCountry_error("");
            return true;
        } else {
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }

    const validateAddressLine1 = (value) => {
        setAddressline1(value);
        if (!validator.isEmpty(value)) {
            setAddressline1_error("");
            return true;
        } else {
            setAddressline1_error(t('ERROR_MSG.ERROR_ADDRESS1'));
            return false;
        }
    }

    const validateAddressLine2 = (value) => {
        setAddressline2(value);
        if (!validator.isEmpty(value)) {
            setAddressline2_error("");
            return true;
        } else {
            setAddressline2_error(t('ERROR_MSG.ERROR_ADDRESS2'));
            return false;
        }
    }


    const validateCity = (value) => {
        setCity(value);
        if (!validator.isEmpty(value)) {
            setCity_error("");
            return true;
        } else {
            setCity_error(t('ERROR_MSG.ERROR_CITY'));
            return false;
        }
    }

    const validateRegion = (value) => {
        setRegion(value);
        if (!validator.isEmpty(value)) {
            setRegion_error("");
            return true;
        } else {
            setRegion_error(t('ERROR_MSG.ERROR_REGION'));
            return false;
        }
    }

    const validateZipcode = (value) => {
        setZipcode(value);
        if (!validator.isEmpty(value)) {
            setZipcode_error("");
            return true;
        } else {
            setZipcode_error(t('ERROR_MSG.ERROR_POSTALCODE'));
            return false;
        }
    }


    const handlesubmit = () => {
        if (validateFirstName(first_name) === false) {
            setFirst_name_error(t('ERROR_MSG.ERROR_FIRSTNAME'));
            return false;
        } else if (validateLastName(last_name) === false) {
            setFirst_name_error("");
            setLastName_error(t('ERROR_MSG.ERROR_LASTNAME'));
            return false;
        } else if (validateMiddleName(middle_name) === false) {
            setLastName_error("");
            setMiddleName_error(t('ERROR_MSG.ERROR_MIDDLENAME'));
            return false;
        } else if (validateEmail(email) === false) {
            setMiddleName_error("");
            setEmail_error(t('ERROR_MSG.ERROR_EMAIL'));
            return false;
        } else if (validatePhonenumber(phonenumber) === false) {
            setEmail_error("");
            setPhonenumber_error(t('ERROR_MSG.ERROR_PHONENUMBER'));
            return false;
        } else if (validateDOB(dob) === false) {
            setPhonenumber_error("");
            setDob_error(t('ERROR_MSG.ERROR_DOB'));
            return false;
        } else if (validateTaxssn(taxssn) === false) {
            setDob_error("");
            setTaxssn_error(t('ERROR_MSG.ERROR_TAXSSN'));
            return false;
        } else if (validateCountrysecond(taxcountry) === false) {
            setTaxssn_error("");
            setTaxcountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        } else if (validateTaxState(taxstate) === false) {
            setTaxcountry_error("");
            setTaxstate_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        } else if (validateCountry(country) === false) {
            setTaxstate_error("");
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        } else if (validateAddressLine1(addressline1) === false) {
            setCountry_error("");
            setAddressline1_error(t('ERROR_MSG.ERROR_ADDRESS1'));
            return false;
        } else if (validateAddressLine2(addressline2) === false) {
            setAddressline1_error("");
            setAddressline2_error(t('ERROR_MSG.ERROR_ADDRESS2'));
            return false;
        } else if (validateCity(city) === false) {
            setAddressline2_error("");
            setCity_error(t('ERROR_MSG.ERROR_CITY'));
            return false;
        } else if (validateRegion(region) === false) {
            setCity_error("");
            setRegion_error(t('ERROR_MSG.ERROR_REGION'));
            return false;
        } else if (validateZipcode(zipcode) === false) {
            setRegion_error("");
            setZipcode_error(t('ERROR_MSG.ERROR_POSTALCODE'));
            return false;
        } else {
            setZipcode_error("");

            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                email: email,
                phonecode: phonecode,
                phone_number: phonenumber,
                dob: dob,
                taxssn: taxssn,
                tax_country_id: taxcountry,
                tax_state_id: taxstate,
                country_id: country,
                address1: addressline1,
                address2: addressline2,
                city: city,
                region: region,
                postalcode: zipcode
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);

            axios.post(process.env.REACT_APP_API_URL + 'updateUserKyc', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            handleLoad();
                            setSuccess(false);
                        }, 1000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }


    const modalupload = () => {
        if (validateModal_Doctype(modal_doc_type) === false) {
            setmodal_doc_type_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        } else if (validateModal_Country(modal_country) === false) {
            setmodal_doc_type_error("");
            setmodal_country_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        } else if (validateModal_Frontdoc(modal_front_doc) === false) {
            setmodal_country_error("");
            setmodal_front_doc_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        } else if (validateModal_Backdoc(modal_back_doc) === false) {
            setmodal_front_doc_error("");
            setmodal_back_doc_error(t('ERROR_MSG.ERROR_OPTION'));
            return false;
        } else {
            if (modal_doc_type === "3") {
                if (validateModal_Text(modal_text) === false) {
                    setmodal_text_error(t('ERROR_MSG.ERROR_OPTION'));
                    return false;
                }
            }
            setmodal_back_doc_error("");
            setmodal_text_error("");
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                kyc_id: kyc_id,
                document_type_id: modal_doc_type,
                document_type_title: modal_text,
                document_country: modal_country,
                document_front: modal_front_doc,
                document_baack: modal_back_doc,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            setAddCustomer(false);
            axios.post(process.env.REACT_APP_API_URL + 'updateUserDocuments', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setmodal_doc_type("");
                        setmodal_text("");
                        setmodal_country("");
                        setmodal_front_doc("");
                        setmodal_back_doc("");
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            setSuccess(false);
                            handleLoad();
                            window.location.reload();
                        }, 1000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }


    return (
        <div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header mobile-card-header">
                        <h2 className="text-black font-w600 mb-0">{t('KYC.VERIFY_ACCOUNT')}</h2>
                        {kyc_staus === "Approved" ?
                            <div className="mobileFullWidth">
                                {accountarray.length > 0 ?
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none select-account" id="sel1">
                                            {accountarray[0].account_currency_type === "USD" ?
                                                <option>{t('KYC.MYACCOUNT')} : {accountarray[0].account_number} ( {accountarray[0].account_balance} $ )</option>
                                                : ""}
                                            {accountarray[1].account_currency_type === "EUR" ?
                                                <option>{t('KYC.MYACCOUNT')} : {accountarray[1].account_number} ( {accountarray[1].account_balance} € )</option>
                                                : ""}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    : ""}
                            </div>
                            : ""}
                    </div>
                    <div className="kyc_istruction">
                        <p>{t('KYC.STEPONE')}</p>
                        <p>{t('KYC.STEPTWO')}</p>
                        <p>{t('KYC.STEPTHREE')}</p>
                    </div>
                </div>
            }

            {kyc_id > 0 ?

                <div className="card">
                    {loader === true ?
                        <div>
                            <Loader open_close={loader} />
                        </div>
                        :
                        <div className="card-body p-0">
                            <div className="profile-head">
                                <div className="profile-info">
                                    <div className="profile-details mt-3">
                                        <div className="profile-name px-4">
                                            <h4 className="mb-0 text-success">{t('KYC.AML')}:  {moment(aml_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{aml_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-success">{t('KYC.CIP')}: {moment(cip_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{cip_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-warning">{t('KYC.IDDOC')}: {moment(iddoc_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{iddoc_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-warning">{t('KYC.ADDRESS')}: {moment(address_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{address_kyc_status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="profile-details mb-3">
                                        <div className="profile-name px-4">
                                            <h5 className="mb-0">{t('KYC.CREATED_AT')}: {created_at === "" ? t('KYC.NOT_KYC_START') : moment(created_at).format('MM / DD / YYYY hh:mm:ss')}</h5>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h5 className="mb-0">{t('KYC.UPDATED_AT')}: {updated_at === "" ? t('KYC.NOT_KYC_START') : moment(updated_at).format('MM / DD / YYYY hh:mm:ss')}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                : ""}
            <div className="card mb-1">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_FIRST_NAME')}</label>
                                    <input type="text" className="form-control text-black" onChange={(e) => validateFirstName(e.target.value)} value={first_name} placeholder={t('PLACEHOLDER.PLACEHOLDER_FIRST_NAME')} />
                                    {first_name_error !== "" ? <label className='text-red'>{first_name_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.MIDDLE_NAME')}</label>
                                    <input type="text" className="form-control text-black" onChange={(e) => validateMiddleName(e.target.value)} value={middle_name} placeholder={t('KYC.MIDDLE_PLACE')} />
                                    {middle_name_error !== "" ? <label className='text-red'>{middle_name_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_LAST_NAME')}</label>
                                    <input type="text" className="form-control text-black" onChange={(e) => validateLastName(e.target.value)} value={last_name} placeholder={t('PLACEHOLDER.PLACEHOLDER_LAST_NAME')} />
                                    {last_name_error !== "" ? <label className='text-red'>{last_name_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_EMAIL')}</label>
                                    <input type="email" className="form-control text-black" onChange={(e) => validateEmail(e.target.value)} value={email} placeholder={t('PLACEHOLDER.PLACEHOLDER_EMAIL')} />
                                    {email_error !== "" ? <label className='text-red'>{email_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>{t('LABEL.LABEL_PHONE_NUMBER')}</label>
                                    <div className="form-group position-relative countrybox1">
                                        <select className="form-control webkit-none" value={phonecode} onChange={(e) => setPhonecode(e.target.value)}>
                                            {allcountry && allcountry.map((row) => (
                                                <option value={row.phonecode} key={row.country_id_pk}>+{row.phonecode + " " + row.name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    <div className='country-text-box1'>
                                        <input type="text" className="form-control" value={phonenumber} onChange={(e) => validatePhonenumber(e.target.value)} placeholder='Phone number' />
                                    </div>
                                    {phonenumber_error !== "" ? <label className='text-red'>{phonenumber_error}</label> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('PROFILE.DOB')}</label>
                                    <input type="date" className="form-control text-black" placeholder={t('PROFILE.DOB')} onChange={(e) => validateDOB(e.target.value)} value={dob} max={new Date().toISOString().split("T")[0]} />
                                    {dob_error !== "" ? <label className='text-red'>{dob_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAXID')}</label>
                                    <input type="text" className="form-control text-black" value={taxssn} onChange={(e) => validateTaxssn(e.target.value)} placeholder={t('KYC.TAXID')} />
                                    {taxssn_error !== "" ? <label className='text-red'>{taxssn_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAX_COUNTRY')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={taxcountry} onChange={(e) => validateTaxCountry(e.target.value)}>
                                        <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                {taxcountry_error !== "" ? <label className='text-red'>{taxcountry_error}</label> : ""}
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAX_STATE')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={taxstate} onChange={(e) => validateTaxState(e.target.value)}>
                                        <option value="">{t('PROFILE.SELECT_STATE')}</option>
                                        {allstate && allstate.map((row) => (
                                            <option value={row.id} key={row.id}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                {taxstate_error !== "" ? <label className='text-red'>{taxstate_error}</label> : ""}
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('PROFILE.COUNTRY')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={country} onChange={(e) => validateCountry(e.target.value)}>
                                        <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                {country_error !== "" ? <label className='text-red'>{country_error}</label> : ""}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('KYC.STREET_ADDRESS')}</label>
                                    <input type="text" className="form-control text-black" placeholder={t('KYC.STREET_ADDRESS')} onChange={(e) => validateAddressLine1(e.target.value)} value={addressline1} />
                                    {addressline1_error !== "" ? <label className='text-red'>{addressline1_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('KYC.STREET_ADDRESS_TWO')}</label>
                                    <input type="text" className="form-control text-black" onChange={(e) => validateAddressLine2(e.target.value)} value={addressline2} placeholder={t('KYC.STREET_ADDRESS_TWO')} />
                                    {addressline2_error !== "" ? <label className='text-red'>{addressline2_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('PROFILE.CITY')}</label>
                                    <input type="text" className="form-control text-black" value={city} onChange={(e) => validateCity(e.target.value)} placeholder={t('PROFILE.CITY')} />
                                    {city_error !== "" ? <label className='text-red'>{city_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.REGION')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={region} onChange={(e) => validateRegion(e.target.value)}>
                                        <option value="">{t('ERROR_MSG.ERROR_REGION')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                {region_error !== "" ? <label className='text-red'>{region_error}</label> : ""}
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.POSTAL_CODE')}</label>
                                    <input type="text" className="form-control text-black" value={zipcode} onChange={(e) => validateZipcode(e.target.value)} placeholder={t('KYC.POSTAL_CODE')} />
                                    {zipcode_error !== "" ? <label className='text-red'>{zipcode_error}</label> : ""}
                                </div>
                            </div>
                            {kyc_staus !== "Approved" ?
                                <div className="col-md-12 mt-2">
                                    <button type="button" id="submit" onClick={handlesubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.UPDATE')}</button>
                                </div>
                                : ""}
                        </div>
                    </div>
                }
            </div>
            {kyc_id > 0 ?
                <div className="card">
                    {loader === true ?
                        <div>
                            <Loader open_close={loader} />
                        </div>
                        :
                        <div className="card-body">
                            <div className="col-xs-12">
                                <div className='custom-tab-1'>
                                    {/* <!-- Nav tabs --> */}
                                    <div className='custom-tab-1'>
                                        <Tabs id="controlled-tab-example" className="mb-3">
                                            <Tab eventKey="Documents" title="Documents">
                                                {documentdetails.length > 0 ?
                                                    <div className="me-auto mb-sm-0 mb-3 mt-4">
                                                        <span className="fs-34 text-black font-w600">{documenttypename}</span>
                                                    </div>
                                                    : ""}
                                                {documentdetails.length > 0 ?
                                                    <div className="mt-4 row">
                                                        <div className="col-md-4">
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                                {kyc_staus === "Approved" ?
                                                                    <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{kyc_staus}</span></span>
                                                                    :
                                                                    <span className="text-black fs-18 font-w500"><span className="badge light badge-warning">{kyc_staus}</span></span>
                                                                }
                                                            </div>
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.EXCEPTIONS')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NOEXCEPTION')}</span></span>
                                                            </div>
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.PROOF_IDENTITY')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('LINK.SUCCESS')}</span></span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.PROOF_ADDRESS')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('LINK.SUCCESS')}</span></span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <p className="fs-14 mb-1">{t('PROFILE.COUNTRY')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="text-black fs-18 font-w500">{documentcountryname}</span></span>
                                                            </div>
                                                        </div>
                                                        {documentdetails.length > 0 ?
                                                            <div className="col-md-6">
                                                                <div className="d-sm-flex d-block mb-3">
                                                                    <div className="d-flex me-auto mb-sm-0 mb-3 align-items-center">
                                                                        <img src={process.env.REACT_APP_FILE_PATH_KYC + documentfrontfilename} alt="" className="mr-4" width="160" />
                                                                        <p>{documentfrontfilename}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-sm-flex d-block mb-3">
                                                                    <div className="d-flex me-auto mb-sm-0 mb-3 align-items-center">
                                                                        <img src={process.env.REACT_APP_FILE_PATH_KYC + documentbackfilename} alt="" className="mr-4" width="160" />
                                                                        <p>{documentbackfilename}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            : ""}
                                                    </div>
                                                    : ""}
                                                <hr />
                                                {kyc_staus !== "Approved" && documentarray.length > 0 ?
                                                    <button className="btn btn-primary btn-rounded" onClick={() => setAddCustomer(true)}>+ {t('KYC.NEW_DOC_CHECK')}</button>
                                                    : ""}
                                                <hr />
                                                <div className="col-xs-12 mb-4">
                                                    {kyc_staus !== "Approved" ?
                                                        <>
                                                            <p className="mb-3">{t('KYC.UPLOAD_DOCUMENT')}</p>
                                                            <div className="form-box">
                                                                <div className='basic-form custom_file_input'>

                                                                    <div className='input-group mb-0'>
                                                                        <div className='custom-file'>
                                                                            <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={saveFile} />
                                                                            {fileName_error !== "" ? <label className='text-red'>{fileName_error}</label> : ""}
                                                                        </div>
                                                                        <div className='input-group-append'>
                                                                            <Button onClick={handleupload} className='input-group-text'>{t('BUTTONS.UPLOAD')}</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""}
                                                    <div className="table-responsive file-upload-table mt-5">
                                                        <table className="table table-responsive-sm mb-0 list-table-box">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{t('KYC.FILE_NAME')}</th>
                                                                    <th>{t('KYC.UPLOADON')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {documentarray && documentarray.map((row, i) => (
                                                                    <tr key={i}>
                                                                        <th>{i + 1}</th>
                                                                        <td><a rel="noreferrer" href={process.env.REACT_APP_FILE_PATH_KYC + row.upload_file_name} target="_blank"> {row.upload_file_name}</a></td>
                                                                        <td>{moment(row.created_datetime).format('MM / DD / YYYY hh:mm:ss')}</td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="CIP" title="CIP">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                            {kyc_staus === "Approved" ?
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success"> {t('LINK.SUCCESS')}</span></span>
                                                                :
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-warning"> {cip_kyc_status}</span></span>
                                                            }
                                                        </div>
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.AUTOMATED_EX')}</p>
                                                            <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NO_AUTOMATED_EXCEPTION')}</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label">{t('KYC.EXCEPTIONS')}</label>
                                                        <input type="text" className="form-control" placeholder={t('KYC.EXCEPTIONS')} readOnly />
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label">{t('KYC.DOCUMENT_COUNTRY')}</label>
                                                        <textarea className="form-control" placeholder={t('KYC.DOCUMENT_COUNTRY')} readOnly>{t('KYC.R557')}</textarea>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="AML" title="AML">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                            {kyc_staus === "Approved" ?
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success"> {t('LINK.SUCCESS')}</span></span>
                                                                :
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-warning"> {aml_kyc_status}</span></span>
                                                            }
                                                        </div>
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.AUTOMATED_EX')}</p>
                                                            <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NOEXCEPTION')}</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="Next Step" title="Next Step">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>{t('KYC.FOLLOWING_NEXT')}</p>
                                                        <p className="No-Data">{t('KYC.NO_CURRENT_sTEP')}</p>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                : ""}

            {kyc_id > 0 ?
                <Modal className="modal fade" show={AddCustomer}>
                    <div className="modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('KYC.NEW_DOC_CHECK')}</h3>
                                <button type="button" className="close" onClick={() => setAddCustomer(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('KYC.DOC_TYPE')}</label>
                                            <select className="form-control" value={modal_doc_type} onChange={(e) => validateModal_Doctype(e.target.value)}>
                                                <option value="">{t('KYC.DOC_TYPE')}</option>
                                                {alldoctype && alldoctype.map((row) => (
                                                    <option value={row.document_type_id} key={row.document_type_id}>{row.document_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {modal_doc_type_error !== "" ? <label className='text-red'>{modal_doc_type_error}</label> : ""}
                                    </div>
                                    {ModalDocType ?
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="mb-2 ">{t('KYC.DOC_TYPE')}</label>
                                                <input type="text" className="form-control" value={modal_text} onChange={(e) => validateModal_Text(e.target.value)} placeholder={t('KYC.SELECT_DOCUMENT')} />
                                                {modal_text_error !== "" ? <label className='text-red'>{modal_text_error}</label> : ""}
                                            </div>
                                        </div>
                                        : ""}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('KYC.DOCUMENT_COUNTRY')}</label>
                                            <select className="form-control" value={modal_country} onChange={(e) => validateModal_Country(e.target.value)}>
                                                <option value="">{t('KYC.SELECT_DOCUMENT_COUNTRY')}</option>
                                                {allcountry && allcountry.map((row) => (
                                                    <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {modal_country_error !== "" ? <label className='text-red'>{modal_country_error}</label> : ""}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('KYC.SELECT_DOCUMENT')}</label>
                                            <select className="form-control" value={modal_front_doc} onChange={(e) => validateModal_Frontdoc(e.target.value)}>
                                                <option value="">{t('KYC.SELECT_DOCUMENT')}</option>
                                                {documentarray && documentarray.map((row) => (
                                                    <option value={row.upload_id} key={row.upload_id}>{row.upload_file_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {modal_front_doc_error !== "" ? <label className='text-red'>{modal_front_doc_error}</label> : ""}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('KYC.SELECT_BANK_DOCUMENT')}</label>
                                            <select className="form-control" value={modal_back_doc} onChange={(e) => validateModal_Backdoc(e.target.value)}>
                                                <option value="">{t('KYC.SELECT_BANK_DOCUMENT')}</option>
                                                {documentarray && documentarray.map((row) => (
                                                    <option value={row.upload_id} key={row.upload_id}>{row.upload_file_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {modal_back_doc_error !== "" ? <label className='text-red'>{modal_back_doc_error}</label> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setAddCustomer(false)}>{t('BUTTONS.CANCEL')}</button>
                                <button type="button" onClick={modalupload} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                : ""}
        </div>
    )

}
export default Verify_Your_Account;