import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

import card2 from '../../../images/card/p-card.png';
import dualdot from '../../../images/dual-dot.png';
import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';
import { useTranslation } from "react-i18next";
// password - virtual card

export const PhysicalCard = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const [cardid, setcardid] = useState(props.location.state);
    const [carddetails, setcarddetails] = useState("");
    const [deliverydetails, setdeliverydetails] = useState([]);
    const [accountarray, setaccountarray] = useState([]);
    const [basearray, setbasearray] = useState([]);
    const [countrycode, setcountrycode] = useState([]);
    const [delvalue, setdelvalue] = useState("");
    const [delvalue_error, setdelvalue_error] = useState("");

    const [first_name, setfirst_name] = useState("");
    const [last_name, setlast_name] = useState("");
    const [select_account, setselect_account] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [postalcode, setpostalcode] = useState("");
    const [city, setcity] = useState("");
    const [phonecode, setphonecode] = useState("34");
    const [phonenumber, setphonenumber] = useState("");

    const [first_name_error, setfirst_name_error] = useState("");
    const [last_name_error, setlast_name_error] = useState("");
    const [select_account_error, setselect_account_error] = useState("");
    const [address1_error, setaddress1_error] = useState("");
    const [address2_error, setaddress2_error] = useState("");
    const [postalcode_error, setpostalcode_error] = useState("");
    const [city_error, setcity_error] = useState("");
    const [phonecode_error, setphonecode_error] = useState("");
    const [phonenumber_error, setphonenumber_error] = useState("");

    const [allcountry, setAllCountry] = useState([]);
    const [allstate, setAllState] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [country_error, setCountry_error] = useState("");
    const [state_error, setState_error] = useState("");

    useEffect(() => {
        setcardid(props.location.state);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        console.log(cardid, "-->This is card id")

        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            cardid: cardid,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);

        axios.post(process.env.REACT_APP_API_URL + 'getCardDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcarddetails(res.data);
                    setdeliverydetails(res.delresult);
                    setaccountarray(res.accountarray);
                    setcountrycode(res.countryarray);
                    setbasearray(res.basecurrancy);
                    setAllCountry(res.countryarrays);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const radiochange = (value) => {
        setdelvalue(value);
        if (!validator.isEmpty(value)) {
            setdelvalue_error("");
            return true;
        } else {
            setdelvalue_error("Select delivery option");
            return false;
        }
    }

    const validateFirstname = (value) => {
        setfirst_name(value);
        if (!validator.isEmpty(value)) {
            setfirst_name_error("");
            return true;
        } else {
            setfirst_name_error("Enter your first name");
            return false;
        }
    }

    const validateLastname = (value) => {
        setlast_name(value);
        if (!validator.isEmpty(value)) {
            setlast_name_error("");
            return true;
        } else {
            setlast_name_error("Enter your last name");
            return false;
        }
    }

    const validateAccount = (value) => {
        setselect_account(value);
        if (!validator.isEmpty(value)) {
            setselect_account_error("");
            return true;
        } else {
            setselect_account_error("Select your account");
            return false;
        }
    }

    const validateAddressone = (value) => {
        setaddress1(value);
        if (!validator.isEmpty(value)) {
            setaddress1_error("");
            return true;
        } else {
            setaddress1_error("Enter your address1");
            return false;
        }
    }

    const validateAddresstwo = (value) => {
        setaddress2(value);
        if (!validator.isEmpty(value)) {
            setaddress2_error("");
            return true;
        } else {
            setaddress2_error("Enter your address2");
            return false;
        }
    }

    const validatePostlcode = (value) => {
        setpostalcode(value);
        if (!validator.isEmpty(value)) {
            setpostalcode_error("");
            return true;
        } else {
            setpostalcode_error("Enter your postal code");
            return false;
        }
    }

    const validateCity = (value) => {
        setcity(value);
        if (!validator.isEmpty(value)) {
            setcity_error("");
            return true;
        } else {
            setcity_error("Enter your city");
            return false;
        }
    }

    const validatePhonecode = (value) => {
        setphonecode(value);
        if (!validator.isEmpty(value)) {
            setphonecode_error("");
            return true;
        } else {
            setphonecode_error("Select phone code");
            return false;
        }
    }

    const validatePhonenumber = (value) => {
        setphonenumber(value);
        if (validator.isNumeric(value)) {
            setphonenumber_error("");
            return true;
        } else {
            setphonenumber_error("Enter your phone number");
            return false;
        }
    }

    const validateCountry = (value) => {
        setCountry(value);
        if (!validator.isEmpty(value)) {
            getallStates(value);
            setState("");
            setCountry_error("");
            return true;
        } else {
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }

    const validateState = (value) => {
        setState(value);
        if (!validator.isEmpty(value)) {
            setState_error("");
            return true;
        } else {
            setState_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        }
    }

    const validateCountrysecond = () => {
        if (!validator.isEmpty(country)) {
            setCountry_error("");
            return true;
        } else {
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }


    const handlesubmit = () => {
        if (validateFirstname(first_name) === false) {
            setfirst_name_error("Enter your first name");
            return false;
        } else if (validateLastname(last_name) === false) {
            setfirst_name_error("");
            setlast_name_error("Enter your last name");
            return false;
        } else if (validateAccount(select_account) === false) {
            setlast_name_error("");
            setselect_account_error("Select account");
            return false;
        } else if (validateAddressone(address1) === false) {
            setselect_account_error("");
            setaddress1_error("Enter your address1");
            return false;
        } else if (validateAddresstwo(address2) === false) {
            setaddress1_error("");
            setaddress2_error("Enter your address2");
            return false;
        } else if (validatePostlcode(postalcode) === false) {
            setaddress2_error("");
            setpostalcode_error("Enter your postalcode");
            return false;
        } else if (validateCountrysecond(country) === false) {
            setpostalcode_error("");
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        } else if (validateState(state) === false) {
            setCountry_error("");
            setState_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        } else if (validateCity(city) === false) {
            setState_error("");
            setcity_error("Enter city");
            return false;
        } else if (validatePhonecode(phonecode) === false) {
            setcity_error("");
            setphonecode_error("Select phonecode");
            return false;
        } else if (validatePhonenumber(phonenumber) === false) {
            setphonecode_error("");
            setphonenumber_error("Enter phone number");
            return false;
        } else if (radiochange(delvalue) === false) {
            setphonenumber_error("");
            setdelvalue_error("Select delivery option");
            return false;
        } else {
            setdelvalue_error("");
            console.log("here");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                first_name: first_name,
                last_name: last_name,
                account: select_account,
                address1: address1,
                address2: address2,
                postalcode: postalcode,
                country: country,
                state: state,
                city: city,
                phonecode: phonecode,
                phonenumber: phonenumber,
                delvalue: delvalue,
                cardid: cardid,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'submitphysical', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        history.push("/my-cards");
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setError(true);
                    setErrorMsg("Oops! Something went wrong");
                    setLoader(false);
                });
        }
    }

    const getallStates = (counryid) => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getStatesFromCountry/' + counryid, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllState(res.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }



    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Physical Card</h2>
                </div>
            </div>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card card-form">
                    {carddetails.length > 0 ?
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <div className="col-md-4">
                                    <div className="card-bx mb-0">
                                        <img src={card2} className="img-fluid" alt="" />
                                        <div className="card-info text-white">
                                            <p className="mb-1">{carddetails[0].card_name}</p>
                                            <h2 className="fs-30 text-white mb-sm-2 mb-3">$673,412.66</h2>
                                            <div className="d-flex align-items-center justify-content-between mb-sm-2 mb-3">
                                                <img src={dualdot} alt="" className="dot-img" />
                                                <h4 className="fs-20 text-white mb-0">**** **** **** 1234</h4>
                                            </div>
                                            <div className="d-flex">
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">VALID THRU</p>
                                                    <span>08/21</span>
                                                </div>
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                                                    <span>Franklin Jr.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <p className="fs-26 text-black text-left mt-3 mb-3">{carddetails[0].card_name}</p>
                                    <ul className="benifits">
                                        <div dangerouslySetInnerHTML={{ __html: carddetails[0].card_desc }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : ""}

                    <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2">First Name</label>
                                    <input type="text" className="form-control" placeholder="Please Enter First Name" onChange={(e) => validateFirstname(e.target.value)} value={first_name} />
                                    {first_name_error !== "" ? <label className='text-red'>{first_name_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Please Enter Last Name" onChange={(e) => validateLastname(e.target.value)} value={last_name} />
                                    {last_name_error !== "" ? <label className='text-red'>{last_name_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>Select Account</label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => validateAccount(e.target.value)} value={select_account}>
                                            <option value="">Select Account</option>
                                            {accountarray && accountarray.map((row) => (
                                                <option value={row.account_id_pk} key={row.account_id_pk}>My Account : {row.account_number} ( {row.account_balance} {row.account_currency_type === "USD" ? '$' : '€'} )</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {select_account_error !== "" ? <label className='text-red'>{select_account_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="mb-2">Address Line 1</label>
                                    <input type="text" className="form-control" placeholder="Please Enter Address Line 1" onChange={(e) => validateAddressone(e.target.value)} value={address1} />
                                    {address1_error !== "" ? <label className='text-red'>{address1_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="mb-2">Address Line 2</label>
                                    <input type="text" className="form-control" placeholder="Please Enter Address Line 2" onChange={(e) => validateAddresstwo(e.target.value)} value={address2} />
                                    {address2_error !== "" ? <label className='text-red'>{address2_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="mb-2">Postal Code</label>
                                    <input type="text" className="form-control" placeholder="Please Enter Postal Code" onChange={(e) => validatePostlcode(e.target.value)} value={postalcode} />
                                    {postalcode_error !== "" ? <label className='text-red'>{postalcode_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.COUNTRY')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={country} onChange={(e) => validateCountry(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                            {allcountry && allcountry.map((row) => (
                                                <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {country_error !== "" ? <label className='text-red'>{country_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.STATE')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={state} onChange={(e) => validateState(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_STATE')}</option>
                                            {allstate && allstate.map((row) => (
                                                <option value={row.id} key={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {state_error !== "" ? <label className='text-red'>{state_error}</label> : ""}
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="mb-2">City</label>
                                    <input type="text" className="form-control" placeholder="Please Enter City" onChange={(e) => validateCity(e.target.value)} value={city} />
                                    {city_error !== "" ? <label className='text-red'>{city_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>Phone number </label>
                                    <div className="form-group position-relative countrybox1">
                                        <select className="form-control webkit-none" onChange={(e) => validatePhonecode(e.target.value)} value={phonecode}>
                                            {countrycode && countrycode.map((row) => (
                                                <option value={row.phonecode} key={row.country_id_pk}>+{row.phonecode + " (" + row.name + ")"}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    <div className='country-text-box1'>
                                        <input type="text" className="form-control" placeholder='Phone number' onChange={(e) => validatePhonenumber(e.target.value)} value={phonenumber} />
                                    </div>
                                    {phonecode_error !== "" ? <label className='text-red'>{phonecode_error}</label> : ""}
                                    {phonenumber_error !== "" ? <label className='text-red'>{phonenumber_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <p className="delivery-option">Delivery Option</p>
                                <div className="row">
                                    {deliverydetails.length > 0 && deliverydetails.map((row) => (
                                        <div className="col-md-12">
                                            <div className="address-select">
                                                <div className="optionnew">
                                                    <div className="chkbox">
                                                        <input type="radio" name="optradio" className="form-check-input" onChange={(e) => radiochange(e.target.value)} value={row.deliveryid} key={row.deliveryid} />
                                                    </div>
                                                    <div className="AddressType">
                                                        <p className="AddressTypeName">{row.dname}</p>
                                                        <p className="AddressTypeDay">{row.workingdays}</p>
                                                    </div>
                                                    <div className="priceBox">
                                                        <span style={{ width: "125%" }}>
                                                            {row.joiningamount}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {delvalue_error !== "" ? <label className='text-red'>{delvalue_error}</label> : ""}
                            </div>

                            <div className="col-md-12 mt-4">
                                <Link to="#" onClick={handlesubmit} className="btn btn-primary btn-rounded">Request Card</Link>
                                <Link to="/request-card" className="btn btn-outline-light ml-3 btn-rounded">Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}
export default PhysicalCard;