import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="copyright">
        <p className="float-left">
          © {new Date().getFullYear()} {process.env.REACT_APP_NAME} {t('HOLDING')} &amp; {t('DEVELOP_BY')}
          <Link to="/dashboard" rel="noreferrer">
            &nbsp;{t('DEVELOP_COMPANY')}&nbsp;
          </Link>
        </p>
        <ul className="bottom-links">
          <li> <Link to="/terms">{t('TERMS_TITLE')}</Link></li>
          <li> <Link to="/privacypolicy">{t('PRIVACY_TITLE')}</Link></li>
          <li> <Link to="/contact-us">{t('CONTACT_US_TITLE')}</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
