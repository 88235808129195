import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/profile.png";
import { Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import Loader from '../../pages/Loader';
import axios from 'axios';
import moment from 'moment';
import validator from 'validator';

import Buy from "../../../images/profile/Buy-crypto.png";
import Sell from "../../../images/profile/Sell-crypto.png";
import Deposit from "../../../images/profile/Deposit.png";
import Swap from "../../../images/profile/Swap.png";
import Withdraw from "../../../images/profile/Withdrawal.png";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [RecentActivities, setRecentActivities] = useState(false);
	const [Feedback, setFeedback] = useState(false);
	var path = window.location.pathname.split("/");
	const [first_name, setFirst_name] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [status, setStatus] = useState("");
	const [error, setError] = useState(false);
	const [error_msg, setErrorMsg] = useState("");
	const [success, setSuccess] = useState(false);
	const [success_msg, setSuccessMsg] = useState("");
	const [getphoto, setPhoto] = useState("");
	const [loginlogsarray, setloginlogsarray] = useState([]);
	const [feedbackInput, setFeedbackInput] = useState("");
	const [feedback_error, setFeedback_error] = useState("");
	const [loader, setLoader] = useState(false);
	const [notiarray, setnotiarray] = useState([]);
	const [noticounter, setnoticounter] = useState(0);

	const [tagline, settagline] = useState("");
	const [logo, setlogo] = useState("");
	const [mobilelogo, setmobilelogo] = useState("");
	const [favicon, setfavicon] = useState("");
	const [sitename, setsitename] = useState("");


	// var name = path[path.length - 1].split("-");
	/*  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
	 var finalName = filterName.includes("app")
	   ? filterName.filter((f) => f !== "app")
	   : filterName.includes("ui")
	   ? filterName.filter((f) => f !== "ui")
	   : filterName.includes("uc")
	   ? filterName.filter((f) => f !== "uc")
	   : filterName.includes("basic")
	   ? filterName.filter((f) => f !== "basic")
	   : filterName.includes("jquery")
	   ? filterName.filter((f) => f !== "jquery")
	   : filterName.includes("table")
	   ? filterName.filter((f) => f !== "table")
	   : filterName.includes("page")
	   ? filterName.filter((f) => f !== "page")
	   : filterName.includes("email")
	   ? filterName.filter((f) => f !== "email")
	   : filterName.includes("ecom")
	   ? filterName.filter((f) => f !== "ecom")
	   : filterName.includes("chart")
	   ? filterName.filter((f) => f !== "chart")
	   : filterName.includes("editor")
	   ? filterName.filter((f) => f !== "editor")
	   : filterName; */

	useEffect(() => {
		handleLoad();
		handleNotification();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoad = () => {
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		axios.get(process.env.REACT_APP_API_URL + 'getUserDetails/' + window.sessionStorage.getItem("user_id"), { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setFirst_name(res.data.first_name);
					setLastName(res.data.last_name);
					setEmail(res.data.email_id);
					setStatus(res.data.status);
					if (res.data.image_64 === null || res.data.image_64 === "") {
						setPhoto(profile);
					} else {
						setPhoto(process.env.REACT_APP_FILE_PATH + "" + res.data.image_64);
					}
					setloginlogsarray(res.data.login_logsarray);

					settagline(res.data.logosettings[0].tagline);
					setlogo(process.env.REACT_APP_FILE_PATH_LOGO + res.data.logosettings[0].logo);
					setmobilelogo(process.env.REACT_APP_FILE_PATH_MOBILELOGO + res.data.logosettings[0].mobile_logo);
					setfavicon(process.env.REACT_APP_FILE_PATH_FAVICON + res.data.logosettings[0].favicon);
					setsitename(res.data.logosettings[0].logo_title);
					document.documentElement.style.setProperty('--ourprimary', res.data.colorsettings[0].primary_color);
					document.documentElement.style.setProperty('--ourprimaryhover', res.data.colorsettings[0].primary_hover_color);
					document.documentElement.style.setProperty('--oursecondary', res.data.colorsettings[0].secondary_color);
					document.documentElement.style.setProperty('--ourmenudeafultcolor', res.data.colorsettings[0].menu_default_color);
					document.documentElement.style.setProperty('--ourmenubackgroundcolor', res.data.colorsettings[0].menu_background);
					document.documentElement.style.setProperty('--ourclosebuttoncolor', res.data.colorsettings[0].close_button_color);
					document.documentElement.style.setProperty('--oursinglepagefontcolor', res.data.colorsettings[0].secondary_page_font_color);

				} else {
					setError(true);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleNotification = () => {
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		axios.get(process.env.REACT_APP_API_URL + 'getUserNotification/' + window.sessionStorage.getItem("user_id"), { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setnotiarray(res.data);
					setnoticounter(res.counter);
				}
			})
			.catch(function (error) {
			});
	}


	const validateBox = (value) => {
		setFeedbackInput(value);
		if (!validator.isEmpty(value)) {
			setFeedback_error("");
			return true;
		} else {
			setFeedback_error(t('HEADER.PLACEHOLDER_COMMENT'));
			return false;
		}
	}


	const submitfeedback = () => {
		if (validateBox(feedbackInput) === false) {
			setFeedback_error(t('HEADER.PLACEHOLDER_COMMENT'));
			return false;
		} else {
			setFeedback_error("");

			var jsonpar = {
				user_id: window.sessionStorage.getItem("user_id"),
				feedback: feedbackInput
			}
			const headers = {
				'Content-Type': 'application/json',
				'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
				'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
			}
			setFeedback(false);
			setLoader(true);

			axios.post(process.env.REACT_APP_API_URL + 'updateUserComment', jsonpar, { headers: headers })
				.then(function (res) {
					res = res.data;
					if (res.code === "200") {
						setLoader(false);
						setSuccess(true);
						setSuccessMsg(res.message);
						setTimeout(() => {
							handleLoad();
							setSuccess(false);
						}, 3000);
					} else {
						setLoader(false);
						setError(true);
						setErrorMsg(res.message);
						setTimeout(() => {
							setError(false);
						}, 6000);
					}

				})
				.catch(function (error) {
					setLoader(false);
					setError(true);
					setErrorMsg(t('ERROR_MSG.SOMETHING'));
					setTimeout(() => {
						setError(false);
					}, 6000);
				});
		}
	}

	const updatenoti = (value, type) => {
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		axios.get(process.env.REACT_APP_API_URL + 'updateUserNotification_home/' + value, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					handleNotification();
					if (type === "Internal" || type === "Outgoing") {
						history.push("/fiat-transactions");
					} else {
						history.push("/crypto-transactions");
					}

				}
			})
			.catch(function (error) {
			});
	}


	return (
		<div className="header">
			{error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
			{success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						{/* <div className="header-left">
						<div
							className="dashboard_bar"
							style={{ textTransform: "capitalize" }}
						  >
							{finalName.join(" ").length === 0
							  ? "Dashboard"
							  : finalName.join(" ")}
						</div>
					</div> */}
						{loader === true ?
							<div>
								<Loader open_close={loader} />
							</div>
							:
							<div className="header-left" style={{ color: "#7151A1", fontWeight: "600", fontSize: "17px" }}>
								{/* {tagline} */}
							</div>
						}

						<ul className="navbar-nav header-right">
							<Dropdown as="li" className="nav-item  notification_dropdown">
								<Dropdown.Toggle variant="" className="nav-link  ai-icon i-false" >
									<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M12.6001 4.3008V1.4C12.6001 0.627199 13.2273 0 14.0001 0C14.7715 0 15.4001 0.627199 15.4001 1.4V4.3008C17.4805 4.6004 19.4251 5.56639 20.9287 7.06999C22.7669 8.90819 23.8001 11.4016 23.8001 14V19.2696L24.9327 21.5348C25.4745 22.6198 25.4171 23.9078 24.7787 24.9396C24.1417 25.9714 23.0147 26.6 21.8023 26.6H15.4001C15.4001 27.3728 14.7715 28 14.0001 28C13.2273 28 12.6001 27.3728 12.6001 26.6H6.19791C4.98411 26.6 3.85714 25.9714 3.22014 24.9396C2.58174 23.9078 2.52433 22.6198 3.06753 21.5348L4.20011 19.2696V14C4.20011 11.4016 5.23194 8.90819 7.07013 7.06999C8.57513 5.56639 10.5183 4.6004 12.6001 4.3008ZM14.0001 6.99998C12.1423 6.99998 10.3629 7.73779 9.04973 9.05099C7.73653 10.3628 7.00011 12.1436 7.00011 14V19.6C7.00011 19.817 6.94833 20.0312 6.85173 20.2258C6.85173 20.2258 6.22871 21.4718 5.57072 22.7864C5.46292 23.0034 5.47412 23.2624 5.60152 23.4682C5.72892 23.674 5.95431 23.8 6.19791 23.8H21.8023C22.0445 23.8 22.2699 23.674 22.3973 23.4682C22.5247 23.2624 22.5359 23.0034 22.4281 22.7864C21.7701 21.4718 21.1471 20.2258 21.1471 20.2258C21.0505 20.0312 21.0001 19.817 21.0001 19.6V14C21.0001 12.1436 20.2623 10.3628 18.9491 9.05099C17.6359 7.73779 15.8565 6.99998 14.0001 6.99998Z" fill="#3E4954" />
									</svg>
									<span className="badge light text-white bg-primary rounded-circle">{noticounter}</span>
								</Dropdown.Toggle>
								<Dropdown.Menu align="right" className="mt-2">
									<PerfectScrollbar className="widget-media dz-scroll p-0 max-height380 ps">
										<ul className="timeline-panel cstm-timeline">
											{notiarray && notiarray.map((row) => (
												<li style={{ backgroundColor: row.read === "Yes" ? "" : "#efefef", cursor: "pointer" }}>
													<div className="timeline-panel" onClick={() => updatenoti(row.noti_id, row.transaction_type)}>
														<div className="media">
															{row.transaction_type === "Swap" ?
																<img alt="" width={50} src={Swap} />
																: ""}

															{row.transaction_type === "Outgoing" ?
																<img alt="" width={50} src={Withdraw} />
																: ""}

															{row.transaction_type === "Buy" ?
																<img alt="" width={50} src={Buy} />
																: ""}

															{row.transaction_type === "Sell" ?
																<img alt="" width={50} src={Sell} />
																: ""}

															{row.transaction_type === "Transfer" ?
																<img alt="" width={50} src={Withdraw} />
																: ""}
															{row.transaction_type === "Deposit" ?
																<img alt="" width={50} src={Deposit} />
																: ""}
															{row.transaction_type === "Withdraw" ?
																<img alt="" width={50} src={Withdraw} />
																: ""}

															{row.transaction_type === "Internal" ?
																<img alt="" width={50} src={Withdraw} />
																: ""}
														</div>
														<div className="media-body">
															<h6 className="mb-1">{row.title}<span className='descshow'>{row.description}</span></h6>
															<small className="d-block">
																{moment(row.created_datetime).format('DD MMM YYYY - h:mm:ss a')}
															</small>
														</div>
													</div>
												</li>
											))}
										</ul>
										<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
											<div
												className="ps__thumb-x"
												tabIndex={0}
												style={{ left: 0, width: 0 }}
											/>
										</div>
										<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
											<div
												className="ps__thumb-y"
												tabIndex={0}
												style={{ top: 0, height: 0 }}
											/>
										</div>
									</PerfectScrollbar>
									{/* <Link className="all-notification" to="#">
										See all notifications <i className="ti-arrow-right" />
									</Link> */}
								</Dropdown.Menu>
							</Dropdown>

							<Dropdown as="li" className="nav-item header-profile ">
								<Dropdown.Toggle
									as="a"
									to="#"
									variant=""
									className="nav-link i-false c-pointer"
								>
									<img src={getphoto} width={20} alt="" />
								</Dropdown.Toggle>
								<Dropdown.Menu align="right" className="mt-2">
									<div className="header-info profile-info-box">
										<span className="fs-18 text-black">{t('HEADER.HELLO')}, <strong>{first_name} {last_name}</strong></span>
										<p className="fs-12 mb-0 user-email">{email} </p>
										{status === "Enable" ?
											<p className="verified-profile mb-0"><i className="fa fa-check-circle" aria-hidden="true"></i>
												{t('HEADER.ADMINVERIFY')} </p>
											: <p className="verified-profile mb-0"><i className="fa fa-xmark" aria-hidden="true"></i>
												{t('HEADER.ADMINVERIFYNOT')} </p>
										}
									</div>
									<Link to="#" onClick={() => setRecentActivities(true)} className="dropdown-item ai-icon">
										<i className="fa fa-list-ul" aria-hidden="true"></i>
										<span className="ml-2">{t('HEADER.RECENTTITLE')}  </span>
									</Link>
									<Modal className="modal fade" show={RecentActivities}>
										<div className="modal-dialog-centered " >
											<div className="modal-content">
												<div className="modal-header">
													<h3 className="modal-title">{t('HEADER.RECENTTITLE')}</h3>
													<button type="button" className="close" onClick={() => setRecentActivities(false)}><span>&times;</span>
													</button>
												</div>
												<div className="modal-body">
													{loginlogsarray && loginlogsarray.map((row) => (
														<div key={row.login_log_pk}>
															<h5>{t('HEADER.PREVLOGIN')}</h5>
															<p>{moment(row.logindatetime).format('MM / DD / YYYY HH:MM:SS A')}</p>
															<hr />
														</div>
													))}
												</div>
											</div>
										</div>
									</Modal>
									<Link to="#" onClick={() => setFeedback(true)} className="dropdown-item ai-icon">
										<i className="fa fa-info" aria-hidden="true"></i>
										<span className="ml-2">{t('HEADER.FEEDBACKTITLE')}</span>
									</Link>
									<Modal className="modal fade" show={Feedback}>
										<div className="modal-dialog-centered " >
											<div className="modal-content">
												<div className="modal-header">
													<h3 className="modal-title">{t('HEADER.YOURFEEDBACK')}</h3>
													<button type="button" className="close" onClick={() => setFeedback(false)}><span>&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<p>{t('HEADER.FEEDBACK_NOTE')}</p>
													<textarea placeholder={t('HEADER.PLACEHOLDER_COMMENT')} className='form-control' rows='4' id='comment' onChange={(e) => validateBox(e.target.value)}></textarea>
													{feedback_error !== "" ? <label className='text-red'>{feedback_error}</label> : ""}
												</div>
												<div className="modal-footer">
													<button type="button" className="btn btn-light btn-rounded light" onClick={() => setFeedback(false)}>{t('BUTTONS.CANCEL')}</button>
													<button type="button" onClick={submitfeedback} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
												</div>
											</div>
										</div>
									</Modal>
									<Link className="dropdown-item ai-icon" to="/FAQ">
										<i className="fa fa-question" aria-hidden="true"></i>
										<span className="ml-2">{t('HEADER.FAQ_TITLE')} </span>
									</Link>
									<Link to="/profile" className="dropdown-item ai-icon">
										<i className="fa fa-user-o" aria-hidden="true"></i>
										<span className="ml-2">{t('HEADER.MANAGEPROFILE_TITLE')}</span>
									</Link>
									<LogoutPage />
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
