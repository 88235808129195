import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

import card1 from '../../../images/card/v-card.png';
import dualdot from '../../../images/dual-dot.png';

import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';

export const VirtualCard = (props) => {
    // const { t } = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const [cardid, setcardid] = useState(props.location.state);
    const [carddetails, setcarddetails] = useState("");
    const [accountarray, setaccountarray] = useState([]);
    const [countrycode, setcountrycode] = useState([]);

    const [select_account, setselect_account] = useState("");
    const [phonecode, setphonecode] = useState("34");
    const [phonenumber, setphonenumber] = useState("");
    const [password, setpassword] = useState("");
    const [cnfpassword, setcnfpassword] = useState("");

    const [select_account_error, setselect_account_error] = useState("");
    const [phonecode_error, setphonecode_error] = useState("");
    const [phonenumber_error, setphonenumber_error] = useState("");
    const [password_error, setpassword_error] = useState("");
    const [cnfpassword_error, setcnfpassword_error] = useState("");

    useEffect(() => {
        setcardid(props.location.state);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        console.log(cardid, "-->This is card id")

        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            cardid: cardid,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getCardDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcarddetails(res.data);
                    setaccountarray(res.accountarray);
                    setcountrycode(res.countryarray);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validatePhonecode = (value) => {
        setphonecode(value);
        if (!validator.isEmpty(value)) {
            setphonecode_error("");
            return true;
        } else {
            setphonecode_error("Select phone code");
            return false;
        }
    }

    const validateAccount = (value) => {
        setselect_account(value);
        if (!validator.isEmpty(value)) {
            setselect_account_error("");
            return true;
        } else {
            setselect_account_error("Select your account");
            return false;
        }
    }

    const validatePassword = (value) => {
        setpassword(value);
        if (!validator.isEmpty(value)) {
            setpassword_error("");
            return true;
        } else {
            setpassword_error("Enter your password");
            return false;
        }
    }

    const validateCnfpassword = (value) => {
        setcnfpassword(value);
        if (!validator.isEmpty(value)) {
            if (password === value) {
                setcnfpassword_error("");
                return true;
            } else {
                setcnfpassword_error("Password not match");
                return false;
            }
        } else {
            setcnfpassword("Enter your confirm password");
            return false;
        }
    }

    const validatePhonenumber = (value) => {
        setphonenumber(value);
        if (validator.isNumeric(value)) {
            setphonenumber_error("");
            return true;
        } else {
            setphonenumber_error("Enter your phone number");
            return false;
        }
    }


    const handlesubmit = () => {
        if (validatePhonecode(phonecode) === false) {
            setphonecode_error("Select phonecode");
            return false;
        } else if (validatePhonenumber(phonenumber) === false) {
            setphonecode_error("");
            setphonenumber_error("Enter phone number");
            return false;
        } else if (validateAccount(select_account) === false) {
            setphonenumber_error("");
            setselect_account_error("Select account");
            return false;
        } else if (validatePassword(password) === false) {
            setselect_account_error("");
            setpassword_error("Enter your password");
            return false;
        } else if (validateCnfpassword(cnfpassword) === false) {
            setpassword_error("");
            setcnfpassword_error("Password not match");
            return false;
        } else {
            setcnfpassword_error("");
            console.log("here");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                cardid: cardid,
                phonecode: phonecode,
                phonenumber: phonenumber,
                account: select_account,
                password: password,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'submitvirtual', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        history.push("/my-cards");
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setError(true);
                    setErrorMsg("Oops! Something went wrong");
                    setLoader(false);
                });
        }
    }

    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Virtual Card</h2>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card card-form">
                    {carddetails.length > 0 ?
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <div className="col-md-4">
                                    <div className="card-bx mb-0">
                                        <img src={card1} className="img-fluid" alt="" />
                                        <div className="card-info text-white">
                                            <p className="mb-1">{carddetails[0].card_name}</p>
                                            <h2 className="fs-30 text-white mb-sm-2 mb-3">$673,412.66</h2>
                                            <div className="d-flex align-items-center justify-content-between mb-sm-2 mb-3">
                                                <img src={dualdot} alt="" className="dot-img" />
                                                <h4 className="fs-20 text-white mb-0">**** **** **** 1234</h4>
                                            </div>
                                            <div className="d-flex">
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">VALID THRU</p>
                                                    <span>08/21</span>
                                                </div>
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                                                    <span>Franklin Jr.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <p className="fs-26 text-black text-left mt-3 mb-3">{carddetails[0].card_name}</p>
                                    <ul className="benifits">
                                        <div dangerouslySetInnerHTML={{ __html: carddetails[0].card_desc }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : ""}


                    <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>Phone number </label>
                                    <div className="form-group position-relative countrybox1">
                                        <select className="form-control webkit-none" onChange={(e) => validatePhonecode(e.target.value)} value={phonecode}>
                                            {countrycode && countrycode.map((row) => (
                                                <option value={row.phonecode} key={row.country_id_pk}>+{row.phonecode + " (" + row.name + ")"}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    <div className='country-text-box1'>
                                        <input type="text" className="form-control" placeholder='Phone number' onChange={(e) => validatePhonenumber(e.target.value)} value={phonenumber} />
                                    </div>
                                    {phonecode_error !== "" ? <label className='text-red'>{phonecode_error}</label> : ""}
                                    {phonenumber_error !== "" ? <label className='text-red'>{phonenumber_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>Select Account</label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => validateAccount(e.target.value)} value={select_account}>
                                            <option value="">Select Account</option>
                                            {accountarray && accountarray.map((row) => (
                                                <option value={row.account_id_pk} key={row.account_id_pk}>My Account : {row.account_number} ( {row.account_balance} {row.account_currency_type === "USD" ? '$' : '€'} )</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {select_account_error !== "" ? <label className='text-red'>{select_account_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-2">Create 3DS Password</label>
                                    <input type="text" className="form-control" placeholder="Enter Create 3DS Password" onChange={(e) => validatePassword(e.target.value)} value={password} />
                                    {password_error !== "" ? <label className='text-red'>{password_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-2">Confirm 3DS Password</label>
                                    <input type="text" className="form-control" placeholder="Enter Confirm 3DS Password" onChange={(e) => validateCnfpassword(e.target.value)} value={cnfpassword} />
                                    {cnfpassword_error !== "" ? <label className='text-red'>{cnfpassword_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-12 mt-4">
                                <Link to="#" onClick={handlesubmit} className="btn btn-primary btn-rounded">Request Card</Link>
                                <Link to="/request-card" className="btn btn-outline-light ml-3 btn-rounded">Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}
export default VirtualCard;