import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";

import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import moment from 'moment';
import validator from 'validator';
import Snackbars from '../../pages/Snackbars';

import jsPDF from 'jspdf';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';

const defaultSymbols = [
    {
        proName: "FOREXCOM:SPXUSD",
        title: "S&P 500",
    },
    {
        proName: "FOREXCOM:NSXUSD",
        title: "Nasdaq 100",
    },
    {
        proName: "FX_IDC:EURUSD",
        title: "EUR/USD",
    },
    {
        proName: "BITSTAMP:BTCUSD",
        title: "BTC/USD",
    },
    {
        proName: "BITSTAMP:ETHUSD",
        title: "ETH/USD",
    },
];


export const Crypto_Exchanges = () => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);
    const [allaccount, setAllaccount] = useState([]);
    const [allcrypto, setAllcrypto] = useState([]);

    const [exchnagetra, setexchnagetra] = useState([]);
    const [swaptra, setswaptra] = useState([]);

    const [buy_crypto, setbuy_crypto] = useState("");
    const [buy_crypto_error, setbuy_crypto_error] = useState("");

    const [sell_crypto, setsell_crypto] = useState("");
    const [sell_crypto_error, setsell_crypto_error] = useState("");

    const [swap_from_crypto, setswap_from_crypto] = useState("");
    const [swap_from_crypto_error, setswap_from_crypto_error] = useState("");

    const [swap_to_crypto, setswap_to_crypto] = useState("");
    const [swap_to_crypto_error, setswap_to_crypto_error] = useState("");

    const [buy_account, setbuy_account] = useState("");
    const [buy_account_error, setbuy_account_error] = useState("");

    const [sell_account, setsell_account] = useState("");
    const [sell_account_error, setsell_account_error] = useState("");

    const [buy_amount, setbuy_amount] = useState("");
    const [buy_amount_error, setbuy_amount_error] = useState("");

    const [sell_amount, setsell_amount] = useState("");
    const [sell_amount_error, setsell_amount_error] = useState("");

    const [swap_amount, setswap_amount] = useState("");
    const [swap_amount_error, setswap_amount_error] = useState("");
    const [amountbanner, setamountbanner] = useState("");
    

    const [activeToggle, setActiveToggle] = useState("posts");
    const [OTPVerification, setOTPVerification] = useState(false);
    const [OTPVerificationSell, setOTPVerificationSell] = useState(false);
    const [OTPVerificationSwap, setOTPVerificationSwap] = useState(false);
    const [TransactionDetails, setTransactionDetails] = useState(false);

    const [SMSOTPVerification, setSMSOTPVerification] = useState(false);
    const [SMSOTPVerificationSell, setSMSOTPVerificationSell] = useState(false);
    const [SMSOTPVerificationSwap, setSMSOTPVerificationSwap] = useState(false);

    const [buy_acc_div, setbuy_acc_div] = useState(false);
    const [buy_crypto_div, setbuy_crypto_div] = useState(false);
    const [buy_usd_value, setbuy_usd_value] = useState(0);
    const [buy_euro_value, setbuy_euro_value] = useState(0);
    const [buy_coin_name, setbuy_coin_name] = useState("");
    const [buy_select_value, setbuy_select_value] = useState(0);
    const [buy_select_acc, setbuy_select_acc] = useState("");


    const [sell_acc_div, setsell_acc_div] = useState(false);
    const [sell_crypto_div, setsell_crypto_div] = useState(false);
    const [sell_usd_value, setsell_usd_value] = useState(0);
    const [sell_euro_value, setsell_euro_value] = useState(0);
    const [sell_coin_name, setsell_coin_name] = useState("");
    const [sell_select_value, setsell_select_value] = useState(0);
    const [sell_select_acc, setsell_select_acc] = useState("");

    const [swap_coin_name, setswap_coin_name] = useState("");
    const [swap_coin_amount, setswap_coin_amount] = useState(0);

    const [exchangerate, setexchangerate] = useState(0);
    const [google_auth, setgoogle_auth] = useState("");
    const [google_auth_error, setgoogle_auth_error] = useState("");
    const [google_auth_sell, setgoogle_auth_sell] = useState("");
    const [google_auth_sell_error, setgoogle_auth_sell_error] = useState("");

    const [google_auth_swap, setgoogle_auth_swap] = useState("");
    const [google_auth_swap_error, setgoogle_auth_swap_error] = useState("");

    const [swap_popup_from_value, setswap_popup_from_value] = useState(0);
    const [swap_popup_to_value, setswap_popup_to_value] = useState(0);

    const [buyminamount, setbuyminamount] = useState("");
    const [buymaxamount, setbuymaxamount] = useState("");

    const [sellminamount, setsellminamount] = useState("");
    const [sellmaxamount, setsellmaxamount] = useState("");

    const [swapminamount, setswapminamount] = useState("");
    const [swapmaxamount, setswapmaxamount] = useState("");


    const [buyotp, setbuyotp] = useState("");
    const [sellotp, setsellotp] = useState("");
    const [swapotp, setswapotp] = useState("");

    const [buyotpmessage, setbuyotpmessage] = useState("");
    const [buyotpmessage_error, setbuyotpmessage_error] = useState("");

    const [sellotpmessage, setsellotpmessage] = useState("");
    const [sellotpmessage_error, setsellotpmessage_error] = useState("");

    const [swapotpmessage, setswapotpmessage] = useState("");
    const [swapotpmessage_error, setswapotpmessage_error] = useState("");




    const [error_display_amount_buy, seterror_display_amount_buy] = useState("");
    const [error_display_details_buy, seterror_display_details_buy] = useState("");

    const [error_display_amount_sell, seterror_display_amount_sell] = useState("");
    const [error_display_details_sell, seterror_display_details_sell] = useState("");

    const [error_display_amount_swap, seterror_display_amount_swap] = useState("");
    const [error_display_details_swap, seterror_display_details_swap] = useState("");

    const [TransferAcceptBuy, setTransferAcceptBuy] = useState(false);
    const [TransferDeclineBuy, setTransferDeclineBuy] = useState(false);

    const [TransferAcceptSell, setTransferAcceptSell] = useState(false);
    const [TransferDeclineSell, setTransferDeclineSell] = useState(false);

    const [TransferAcceptSwap, setTransferAcceptSwap] = useState(false);
    const [TransferDeclineSwap, setTransferDeclineSwap] = useState(false);


    const [success_display_credited_amount_buy, setsuccess_display_credited_amount_buy] = useState("");
    const [success_display_debit_fiat_amount_buy, setsuccess_display_debit_fiat_amount_buy] = useState("");
    const [success_display_debited_account_buy, setsuccess_display_debited_account_buy] = useState("");
    const [success_display_debited_cur_buy, setsuccess_display_debited_cur_buy] = useState("");
    const [success_display_refno_buy, setsuccess_display_refno_buy] = useState("");
    const [success_display_details_buy, setsuccess_display_details_buy] = useState("");

    const [success_display_credited_amount_sell, setsuccess_display_credited_amount_sell] = useState("");
    const [success_display_debit_fiat_amount_sell, setsuccess_display_debit_fiat_amount_sell] = useState("");
    const [success_display_debited_account_sell, setsuccess_display_debited_account_sell] = useState("");
    const [success_display_debited_cur_sell, setsuccess_display_debited_cur_sell] = useState("");
    const [success_display_refno_sell, setsuccess_display_refno_sell] = useState("");
    const [success_display_details_sell, setsuccess_display_details_sell] = useState("");

    const [success_display_credited_amount_swap, setsuccess_display_credited_amount_swap] = useState("");
    const [success_display_debit_fiat_amount_swap, setsuccess_display_debit_fiat_amount_swap] = useState("");
    const [success_display_refno_swap, setsuccess_display_refno_swap] = useState("");
    const [success_display_details_swap, setsuccess_display_details_swap] = useState("");

    var ref = React.createRef();

    useEffect(() => {
        handleLoad();
        const script = document.createElement("script");
        script.setAttribute("data-nscript", "afterInteractive");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.type = "text/javascript";
        var scriptHTML = {
            symbols: defaultSymbols,
            showSymbolLogo: true,
            colorTheme: "light",
            isTransparent: false,
            largeChartUrl: undefined,
            displayMode: "adaptive",
            locale: "in",
        }
        script.innerHTML = JSON.stringify(scriptHTML);
        ref.current.appendChild(script);
        // refValue = ref.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/GetAllCryptoExchange', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllaccount(res.data.accounts);
                    setAllcrypto(res.data.usercryptowallet);
                    setexchnagetra(res.data.buyselltra);
                    setswaptra(res.data.swaptra);
                    // setbuyminamount(res.data.buyminamount);
                    // setbuymaxamount(res.data.buymaxamount);
                    // setsellminamount(res.data.buyminamount);
                    // setsellmaxamount(res.data.sellmaxamount);
                    // setswapminamount(res.data.swapminamount);
                    // setswapmaxamount(res.data.swapmaxamount);

                    setbuyotp(res.data.buyotp);
                    setsellotp(res.data.sellotp);
                    setswapotp(res.data.swapotp);

                    setLoader(false);

                    if (res.data.buyselltra.length > 0) {
                        var TempArrfiat = [];
                        res.data.buyselltra.forEach(element => {

                            var status = "";
                            if (element.transfer_status === "Completed") {
                                status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                            } else if (element.transfer_status === "Failed") {
                                status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                            } else {
                                status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                            }
                            var fiatonetemp = {
                                transaction_hash: element.transaction_hash,
                                created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                exchange_type: element.exchange_type,
                                exchnage_pair: element.exchnage_pair,
                                fiat_amount: element.fiat_amount,
                                crypto_amount: element.crypto_amount,
                                transfer_status: status,
                                transfer_statusText: element.transfer_status,
                            };
                            TempArrfiat.push(fiatonetemp);

                        });
                        setexchnagetra(TempArrfiat);
                    }


                    if (res.data.swaptra.length > 0) {
                        var TempArrswap = [];
                        res.data.swaptra.forEach(element => {
                            var from_coin = "";
                            var to_coin = "";
                            var status = "";
                            if (element.swap_status === "Completed") {
                                status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
                            } else if (element.swap_status === "Failed") {
                                status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
                            } else {
                                status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
                            }

                            if (element.coin_code === "BTC") {
                                from_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                            } else if (element.coin_code === "ETH") {
                                from_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                            } else if (element.coin_code === "USDT") {
                                from_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                            }

                            if (element.to_coin_code === "BTC") {
                                to_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
                            } else if (element.to_coin_code === "ETH") {
                                to_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
                            } else if (element.to_coin_code === "USDT") {
                                to_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
                            }

                            var swaptemp = {
                                transaction_hash: element.transaction_hash,
                                created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                from_amount: element.from_amount,
                                from_coin: from_coin,
                                to_amount: element.to_amount,
                                to_coin: to_coin,
                                swap_status: status,
                                swap_statusText: element.swap_status,
                                from_coincode: element.coin_code,
                                to_coincode: element.to_coin_code,
                            };
                            TempArrswap.push(swaptemp);

                        });
                        setswaptra(TempArrswap);
                    }
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
    }



    const swaprate = (fromvalue, tovalue, amount) => {
        if (!validator.isEmpty(fromvalue) && !validator.isEmpty(tovalue) && !validator.isEmpty(amount)) {
            if (fromvalue === tovalue) {
                setswap_to_crypto_error(t("CRYPTOTRANSACTION.SAMECOIN"));
                return false;
            } else {
                var json = {
                    "pair": fromvalue + "/" + tovalue,
                    "swap_amount": parseFloat(amount),
                    "user_id": window.sessionStorage.getItem("user_id"),
                    "fromcrypto": fromvalue,
                    "tocrypto": tovalue,
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                    'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                }
                axios.post(process.env.REACT_APP_API_URL + '../settings/getswaprates/', json, { headers: headers })
                    .then(function (res) {
                        res = res.data;
                        if (res.code === "200") {
                            setswap_coin_amount(parseFloat(res.data).toFixed(8));
                            setswap_coin_name(tovalue);
                            setLoader(false);
                        } else {
                            setswap_coin_name("");
                            setexchangerate(0);
                            setLoader(false);
                            setError(true);
                            setErrorMsg(res.message);
                            setTimeout(() => {
                                setError(false);
                            }, 3000);
                        }
                    })
                    .catch(function (error) {
                        setswap_coin_name("");
                        setexchangerate(0);
                        setLoader(false);
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    });
            }
        }

    }


    const swapchangerate = (from = "", to = "", amount = "") => {
        // console.log(from, " ===FRom");
        // console.log(to, " ===To");
        // console.log(amount, " ===Amount");
        if (!validator.isEmpty(from) && !validator.isEmpty(to) && validator.isNumeric(amount)) {
            var calculate = 0;
            calculate = (exchangerate * amount).toFixed(8);
            setswap_coin_name(to);
            setswap_coin_amount(calculate);
        } else {
            setswap_coin_name("");
            setswap_coin_amount(0);
        }
    }

    const cryptochnagerate = (value) => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + '../settings/getcryptorates/' + value, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setbuy_coin_name(res.data[0].coin_code);
                    setbuy_usd_value(res.data[0].exchangerate + " " + res.basecur[0].curracycode);
                    // setbuy_usd_value(res.data[0].usd_price);
                    // setbuy_euro_value(res.data[0].euro_price);
                    setbuy_crypto_div(true);
                    setLoader(false);
                } else {
                    setbuy_crypto_div(false);
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setbuy_crypto_div(false);
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });

    }

    const cryptochnageratesell = (value) => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + '../settings/getcryptorates/' + value, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setsell_coin_name(res.data[0].coin_code);
                    setsell_usd_value(res.data[0].exchangerate + " " + res.basecur[0].curracycode);
                    // setsell_euro_value(res.data[0].euro_price);
                    setsell_crypto_div(true);
                    setLoader(false);
                } else {
                    setsell_crypto_div(false);
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setsell_crypto_div(false);
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });

    }

    const accountchnagerate = (coin = "", amount = "", account = "") => {
        if (!validator.isEmpty(coin) && !validator.isEmpty(account) && validator.isNumeric(amount)) {
            if (parseFloat(amount) <= 0) {
                setbuy_amount_error("You must enter amount greater than zero.");
                return false;
            }
            setbuy_amount_error("");
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                buy_crypto: coin,
                buy_amount: parseFloat(amount),
                buy_account: account,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + '../settings/getcryptoratesBuy', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        var sss = account.split("|");
                        if (sss[1] === "USD") {
                            setbuy_select_acc("USD");
                            setbuy_select_value(res.data);
                        } else {
                            setbuy_select_acc("EUR");
                            setbuy_select_value(res.data);
                        }
                        setbuy_acc_div(true);
                        
                        setbuyminamount(parseFloat(res.buyminamount).toFixed(8));
                        setbuymaxamount(parseFloat(res.buymaxamount).toFixed(8));
                        setLoader(false);
                    } else {
                        setbuyminamount(parseFloat(res.buyminamount).toFixed(8));
                        setbuymaxamount(parseFloat(res.buymaxamount).toFixed(8));
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        } else {
            setbuy_acc_div(false);
        }
    }

    const accountchnageratesell = (coin = "", amount = "", account = "") => {
        if (!validator.isEmpty(coin) && !validator.isEmpty(account) && amount > 0) {
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                buy_crypto: coin,
                buy_amount: parseFloat(amount),
                buy_account: account,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + '../settings/getcryptoratesSell', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {

                        var sss = account.split("|");
                        if (sss[1] === "USD") {
                            setsell_select_acc("USD");
                            setsell_select_value(res.data);
                        } else {
                            setsell_select_acc("EUR");
                            setsell_select_value(res.data);
                        }
                        setsellminamount(parseFloat(res.sellminamount).toFixed(8));
                        setsellmaxamount(parseFloat(res.sellmaxamount).toFixed(8));
                        setsell_acc_div(true);
                        setLoader(false);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        } else {
            setsell_acc_div(false);
        }
    }


    const validateCrypto = (value) => {
        setbuy_crypto(value);
        if (!validator.isEmpty(value)) {
            setbuyminamount("");
            setbuymaxamount("");
            cryptochnagerate(value);
            accountchnagerate(value, buy_amount, buy_account);
            setbuy_crypto_error("");
            return true;
        } else {
            setbuy_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const validateCryptoSell = (value) => {
        setsell_crypto(value);
        if (!validator.isEmpty(value)) {
            setsellminamount("");
            setsellmaxamount("");
            cryptochnageratesell(value);
            accountchnageratesell(value, sell_amount, sell_account);
            setsell_crypto_error("");
            return true;
        } else {
            setsell_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const getlimit = (value) => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            coinname: value,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getbaselimitcrypto', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setswapminamount(parseFloat(res.minswaplimit).toFixed(8));
                    setswapmaxamount(parseFloat(res.maxswaplimit).toFixed(8));
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateCryptoSwapFrom = (value) => {
        setswap_from_crypto(value);
        if (!validator.isEmpty(value)) {
            setswap_from_crypto_error("");
            // swaprate(value, swap_to_crypto);
            // swapchangerate(value, swap_to_crypto, swap_amount);
            getlimit(value);
            return true;
        } else {
            setswap_from_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const validateCryptoSwapTo = (value) => {
        setswap_to_crypto(value);
        if (!validator.isEmpty(value)) {
            setswap_to_crypto_error("");
            // swaprate(swap_from_crypto, value);
            //swapchangerate(swap_from_crypto, value, swap_amount);
            return true;
        } else {
            setswap_to_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
    }

    const validateAccount = (value) => {
        setbuy_account(value);
        if (!validator.isEmpty(value)) {
            setbuyminamount("");
            setbuymaxamount("");
            accountchnagerate(buy_crypto, buy_amount, value);
            setbuy_account_error("");
            return true;
        } else {
            setbuy_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }

    }

    const validateAccountSell = (value) => {
        setsell_account(value);
        if (!validator.isEmpty(value)) {
            setsellminamount("");
            setsellmaxamount("");
            accountchnageratesell(sell_crypto, sell_amount, value);
            setsell_account_error("");
            return true;
        } else {
            setsell_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }

    }

    const validateAmount = (value) => {
        setbuy_amount(value);
        if (validator.isNumeric(value) && parseFloat(value) > 0) {
            if (parseFloat(buymaxamount) > 0) {
                if (parseFloat(value) < parseFloat(buyminamount)) {
                    setbuy_amount_error("Minimum Amount is " + buyminamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(buymaxamount)) {
                    setbuy_amount_error("Maximum Amount is " + buymaxamount);
                    return false;
                }
            }
            setbuy_amount_error("");
            accountchnagerate(buy_crypto, value, buy_account);

            return true;
        } else {
            setbuy_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        }
    }

    const validateAmountSell = (value) => {
        setsell_amount(value);
        if (validator.isNumeric(value) && parseFloat(value) > 0) {
            if (parseFloat(sellmaxamount) > 0) {
                if (parseFloat(value) < parseFloat(sellminamount)) {
                    setsell_amount_error("Minimum Amount is " + sellminamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(sellmaxamount)) {
                    setsell_amount_error("Maximum Amount is " + sellmaxamount);
                    return false;
                }
            }
            setsell_amount_error("");
            accountchnageratesell(sell_crypto, value, sell_account);
            return true;
        } else {
            setsell_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        }
    }

    const validateAmountSwap = (value) => {
        setswap_amount(value);
        if (validator.isEmpty(swap_from_crypto)) {
            setswap_from_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }

        if (validator.isEmpty(swap_to_crypto)) {
            setswap_to_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        }
        if (validator.isNumeric(value) && parseFloat(value) > 0) {
            if (parseFloat(swapmaxamount) > 0) {
                if (parseFloat(value) < parseFloat(swapminamount)) {
                    setswap_amount_error("Minimum Amount is " + swapminamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(swapmaxamount)) {
                    setswap_amount_error("Maximum Amount is " + swapmaxamount);
                    return false;
                }
            }
            setswap_amount_error("");
            swaprate(swap_from_crypto, swap_to_crypto, value);
            //swapchangerate(swap_from_crypto, swap_to_crypto, value);
            return true;
        } else {
            setswap_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        }
    }


    const validateGoogleAuth = (value) => {
        setgoogle_auth(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_error("");
            return true;
        } else {
            setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }


    const validateGoogleAuthSell = (value) => {
        setgoogle_auth_sell(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_sell_error("");
            return true;
        } else {
            setgoogle_auth_sell_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }

    const validateGoogleAuthSwap = (value) => {
        setgoogle_auth_swap(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_swap_error("");
            return true;
        } else {
            setgoogle_auth_swap_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }

    const buysubmit = () => {
        if (validateCrypto(buy_crypto) === false) {
            setbuy_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateAmount(buy_amount) === false) {
            setbuy_crypto_error();
            setbuy_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        } else if (validateAccount(buy_account) === false) {
            setbuy_amount_error();
            setbuy_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else {
            setbuy_account_error("");
            if (parseFloat(buy_amount) <= 0) {
                setbuy_amount_error("You must enter amount greater than zero");
                return false;
            }
            setbuy_amount_error("");

            if (buyotp === "Google Authentication") {
                setgoogle_auth("");
                setOTPVerification(true);
            } else if (buyotp === "SMS OTP Authentication") {
                setbuyotpmessage("");
                setSMSOTPVerification(true);
            } else {
                finalbuysubmit();
            }
            //setOTPVerification(true);
        }
    };

    const sellsubmit = () => {
        if (validateCryptoSell(sell_crypto) === false) {
            setsell_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateAmountSell(sell_amount) === false) {
            setsell_crypto_error();
            setsell_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        } else if (validateAccountSell(sell_account) === false) {
            setsell_amount_error();
            setsell_account_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else {
            setsell_account_error("");
            if (parseFloat(sell_amount) <= 0) {
                setsell_amount_error("You must enter amount greater than zero");
                return false;
            }
            setsell_amount_error("");
            if (sellotp === "Google Authentication") {
                setgoogle_auth_sell("");
                setOTPVerificationSell(true);
            } else if (sellotp === "SMS OTP Authentication") {
                setsellotpmessage("");
                setSMSOTPVerificationSell(true);
            } else {
                finalsellsubmit();
            }

        }
    };


    const finalbuysubmit = () => {

        if (buyotp === "Google Authentication") {
            if (validateGoogleAuth(google_auth) === false) {
                setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        } if (buyotp === "SMS OTP Authentication") {
            if (validateCodeBuy(buyotpmessage) === false) {
                setbuyotpmessage_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }
        setgoogle_auth_error("");
        setbuyotpmessage_error("");
        if (parseFloat(buy_amount) <= 0) {
            setbuy_amount_error("You must enter amount greater than zero");
            return false;
        }
        setbuy_amount_error("");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            buy_crypto: buy_crypto,
            buy_amount: parseFloat(buy_amount),
            buy_account: buy_account,
            auth_code: google_auth,
            smscode: buyotpmessage,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/submitexchangebuy', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                setOTPVerification(false);
                setSMSOTPVerification(false);
                if (res.code === "200") {

                    if (res.data.success_display_credited_amount_buy != "") {
                        setsuccess_display_credited_amount_buy(res.data.success_display_credited_amount_buy);
                        setsuccess_display_debit_fiat_amount_buy(res.data.success_display_debit_fiat_amount_buy);
                        setsuccess_display_debited_account_buy(res.data.success_display_debited_account_buy);
                        setsuccess_display_debited_cur_buy(res.data.success_display_debited_cur_buy);
                        setsuccess_display_refno_buy(res.data.success_display_refno_buy);
                        setsuccess_display_details_buy(res.data.success_display_details_buy);
                    } else {
                        setsuccess_display_details_buy(res.data.success_display_details_buy);
                    }
                    setTransferAcceptBuy(true);

                    setbuy_crypto("");
                    setbuy_account("");
                    setbuy_amount("");
                    setgoogle_auth("");
                    setbuy_acc_div(false);
                    setbuy_crypto_div(false);
                    // setSuccess(true);
                    // setSuccessMsg(res.message);
                    handleLoad();
                    // setTimeout(() => {
                    //     setSuccess(false);
                    // }, 6000);
                } else {
                    setLoader(false);
                    seterror_display_details_buy(res.data.error_display_details);
                    seterror_display_amount_buy(res.data.error_display_amount);
                    setTransferDeclineBuy(true);
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 6000);
                }
            })
            .catch(function (error) {
                console.log(error, " This is error");
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const finalsellsubmit = () => {


        if (sellotp === "Google Authentication") {
            if (validateGoogleAuthSell(google_auth_sell) === false) {
                setgoogle_auth_sell_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }
        if (sellotp === "SMS OTP Authentication") {
            if (validateCodeSell(sellotpmessage) === false) {
                setsellotpmessage_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }
        setgoogle_auth_sell_error("");
        setsellotpmessage_error("");
        if (parseFloat(sell_amount) <= 0) {
            setsell_amount_error("You must enter amount greater than zero");
            return false;
        }
        setsell_amount_error("");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            buy_crypto: sell_crypto,
            buy_amount: parseFloat(sell_amount),
            buy_account: sell_account,
            auth_code: google_auth_sell,
            smscode: sellotpmessage,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/submitexchangesell', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                setOTPVerificationSell(false);
                setSMSOTPVerificationSell(false);
                if (res.code === "200") {


                    setsuccess_display_credited_amount_sell(res.data.success_display_credited_amount_buy);
                    setsuccess_display_debit_fiat_amount_sell(res.data.success_display_debit_fiat_amount_buy);
                    setsuccess_display_debited_account_sell(res.data.success_display_debited_account_buy);
                    setsuccess_display_debited_cur_sell(res.data.success_display_debited_cur_buy);
                    setsuccess_display_refno_sell(res.data.success_display_refno_buy);
                    setsuccess_display_details_sell(res.data.success_display_details_buy);
                    setTransferAcceptSell(true);

                    setsell_crypto("");
                    setsell_account("");
                    setsell_amount("");
                    setgoogle_auth_sell("");
                    setsell_acc_div(false);
                    setsell_crypto_div(false);
                    // setSuccess(true);
                    // setSuccessMsg(res.message);
                    handleLoad();
                    // setTimeout(() => {
                    //     setSuccess(false);
                    // }, 6000);
                } else {
                    setLoader(false);
                    seterror_display_details_sell(res.data.error_display_details);
                    seterror_display_amount_sell(res.data.error_display_amount);
                    setTransferDeclineSell(true);
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });

    }

    const swapsubmit = () => {
        if (validateCryptoSwapFrom(swap_from_crypto) === false) {
            setswap_from_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateCryptoSwapTo(swap_to_crypto) === false) {
            setswap_from_crypto_error("");
            setswap_to_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateAmountSwap(swap_amount) === false) {
            setswap_to_crypto_error("");
            setswap_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        } else {
            if (parseFloat(swap_amount) <= 0) {
                setswap_amount_error("You must enter amount greater than zero");
                return false;
            }
            setswap_amount_error("");
            if (swap_from_crypto === swap_to_crypto) {
                setswap_to_crypto_error(t("CRYPTOTRANSACTION.SAMECOIN"));
            } else {
                setswap_amount_error("");
                var jsonpar = {
                    user_id: window.sessionStorage.getItem("user_id"),
                    swap_from_crypto: swap_from_crypto,
                    swap_to_crypto: swap_to_crypto,
                    swap_amount: swap_amount
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                    'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                }
                setLoader(true);
                axios.post(process.env.REACT_APP_API_URL + '../settings/swap_preview', jsonpar, { headers: headers })
                    .then(function (res) {
                        res = res.data;
                        setOTPVerificationSell(false);
                        if (res.code === "200") {
                            setswap_popup_from_value(res.data.from_amount);
                            setswap_popup_to_value(res.data.to_amount);
                            setTransactionDetails(true);
                            setLoader(false);
                        } else {
                            setLoader(false);
                            setError(true);
                            setErrorMsg(res.message);
                            setTimeout(() => {
                                setError(false);
                            }, 6000);
                        }
                    })
                    .catch(function (error) {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    });

            }
        }
    }

    const finalauthubmit = () => {
        if (validateCryptoSwapFrom(swap_from_crypto) === false) {
            setswap_from_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateCryptoSwapTo(swap_to_crypto) === false) {
            setswap_from_crypto_error("");
            setswap_to_crypto_error(t("CRYPTOTRANSACTION.SELECTCOIN"));
            return false;
        } else if (validateAmountSwap(swap_amount) === false) {
            setswap_to_crypto_error("");
            setswap_amount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        } else {
            if (swap_from_crypto === swap_to_crypto) {
                setswap_to_crypto_error(t("CRYPTOTRANSACTION.SAMECOIN"));
            } else {
                setswap_amount_error("");
                if (parseFloat(swap_amount) <= 0) {
                    setswap_amount_error("You must enter amount greater than zero");
                    return false;
                }
                setswap_amount_error("");
                setTransactionDetails(false);


                if (swapotp === "Google Authentication") {
                    setOTPVerificationSwap(true);
                } else if (swapotp === "SMS OTP Authentication") {
                    setSMSOTPVerificationSwap(true);
                } else {
                    finalswapsubmit();
                }
            }
        }
    }

    const finalswapsubmit = () => {

        if (swapotp === "Google Authentication") {
            if (validateGoogleAuthSwap(google_auth_swap) === false) {
                setgoogle_auth_swap_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }
        if (swapotp === "SMS OTP Authentication") {
            if (validateCodeSwap(swapotpmessage) === false) {
                setswapotpmessage_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }
        setgoogle_auth_swap_error("");
        setswapotpmessage_error("");
        if (swap_from_crypto === swap_to_crypto) {
            setswap_to_crypto_error(t("CRYPTOTRANSACTION.SAMECOIN"));
        } else {
            setswap_amount_error("");
            if (parseFloat(swap_amount) <= 0) {
                setswap_amount_error("You must enter amount greater than zero");
                return false;
            }
            setswap_amount_error("");
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                swap_from_crypto: swap_from_crypto,
                swap_to_crypto: swap_to_crypto,
                swap_amount: swap_amount,
                auth_code: google_auth_swap,
                smscode: swapotpmessage,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + '../settings/swap_submit', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    setOTPVerificationSell(false);
                    setSMSOTPVerificationSwap(false);
                    if (res.code === "200") {
                        setsuccess_display_credited_amount_swap(res.data.success_display_credited_amount_swap);
                        setsuccess_display_debit_fiat_amount_swap(res.data.success_display_debit_fiat_amount_swap);
                        setsuccess_display_refno_swap(res.data.success_display_refno_swap);
                        setsuccess_display_details_swap(res.data.success_display_details_swap);
                        setTransferAcceptSwap(true);

                        setswap_amount(0);
                        setswap_from_crypto("");
                        setswap_to_crypto("");
                        setgoogle_auth_swap("");
                        setswap_popup_from_value("");
                        setswap_popup_to_value("");
                        setswap_coin_name("");
                        setswap_coin_amount(0);
                        setTransactionDetails(false);
                        setOTPVerificationSwap(false);
                        setLoader(false);
                        // setSuccess(true);
                        // setSuccessMsg(res.message);
                        handleLoad();
                        // setTimeout(() => {
                        //     setSuccess(false);
                        // }, 6000);
                    } else {
                        setLoader(false);
                        seterror_display_details_swap(res.data.error_display_details);
                        seterror_display_amount_swap(res.data.error_display_amount);
                        setTransferDeclineSwap(true);
                        // setError(true);
                        // setErrorMsg(res.message);
                        // setTimeout(() => {
                        //     setError(false);
                        // }, 6000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });

        }

    }


    const validateCodeBuy = (value) => {
        setbuyotpmessage(value);
        if (!validator.isEmpty(value)) {
            setbuyotpmessage_error("");
            return true;
        } else {
            setbuyotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const validateCodeSell = (value) => {
        setsellotpmessage(value);
        if (!validator.isEmpty(value)) {
            setsellotpmessage_error("");
            return true;
        } else {
            setsellotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const validateCodeSwap = (value) => {
        setswapotpmessage(value);
        if (!validator.isEmpty(value)) {
            setswapotpmessage_error("");
            return true;
        } else {
            setswapotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }


    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Swap Transactions";

        var columns = [
            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
            { title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
            { title: t("FIAT.FROM"), field: 'from_coin' },
            { title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
            { title: t("FIAT.TO"), field: 'to_coin' },
            { title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = swaptra.map(rowData =>
            [rowData["transaction_hash"],
            rowData["created_datetime"],
            rowData["from_amount"],
            rowData["from_coincode"],
            rowData["to_amount"],
            rowData["to_coincode"],
            rowData["swap_statusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const handleChangeCSVNdPDFsecond = (val) => {
        var FileName = "Buy/Sell Transactions";

        var columns = [
            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
            { title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
            { title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
            { title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
            { title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
            { title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = exchnagetra.map(rowData =>
            [rowData["transaction_hash"],
            rowData["created_datetime"],
            rowData["exchange_type"],
            rowData["exchnage_pair"],
            rowData["fiat_amount"],
            rowData["crypto_amount"],
            rowData["transfer_statusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    return (
        <div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            <div className="breaking-news bg-primary">
                <div ref={ref} />
            </div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t("CRYPTOTRANSACTION.CRYPTO_EX_LABEL")}</h2>
                </div>
            </div>
            <ul className="nav nav-tabs crypto-tabs-btn">
                <li className="nav-item" onClick={() => setActiveToggle("posts")}>
                    <Link to="#01" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}><i className="flaticon-381-add-1 mt-1"></i>&nbsp; {t("CRYPTOTRANSACTION.BUY_TITLE")}</Link>
                </li>
                <li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
                    <Link to="#02" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}><i className="flaticon-381-substract-2 mt-1"></i>&nbsp; {t("CRYPTOTRANSACTION.SELL_TITLE")}</Link>
                </li>
                <li className="nav-item">
                    <Link to="#03" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}><i className="fa fa-exchange mt-1"></i>&nbsp; {t("CRYPTOTRANSACTION.SWAP_TITLE")}</Link>
                </li>

            </ul>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="custom-tab-1 setTabDesignbtn">

                            <div className="tab-content">
                                <div id="01" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
                                    <div className="profile-personal-info">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.SELECTCOIN")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateCrypto(e.target.value)} value={buy_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.crypto_id} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {buy_crypto_error !== "" ? <label className='text-red'>{buy_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{buy_coin_name !== "" ? buy_coin_name : ""} {t("TRANSACTION.AMOUNT")} </label>
                                                    <input type="text" className="form-control" onChange={(e) => validateAmount(e.target.value)} value={buy_amount} placeholder={t("TRANSACTION.AMOUNT_PLACE")} />
                                                    {buy_amount_error !== "" ? <label className='text-red'>{buy_amount_error}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.PAYMENT_METHOD")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateAccount(e.target.value)} value={buy_account}>
                                                        <option value="">{t('FIAT.SELECT_ACCOUNT')}</option>
                                                        {allaccount && allaccount.map((row) => (
                                                            <option value={row.account_id_pk + "|" + row.account_currency_type} key={row.account_id_pk}>{t('KYC.MYACCOUNT')} : {row.account_number} ( {row.account_balance} {row.account_currency_type === "USD" ? '$' : '€'} ) </option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {buy_account_error !== "" ? <label className='text-red'>{buy_account_error}</label> : ""}
                                            </div>
                                            {parseFloat(buyminamount) > 0 ?
                                                <div className="col-md-12 mt-4">
                                                    <label className="mb-2 mr-3 minmaxclass">Minimum Amount = {buyminamount} </label>
                                                    <label className="mb-2 minmaxclass">Maximum Amount = {buymaxamount} </label>
                                                </div>
                                                : ""}
                                            <div className="col-md-12">
                                                {buy_acc_div === true ?
                                                    <p className="mt-1 mb-4 highlight-text-coin">{t('CRYPTOTRANSACTION.TOTAL')} : <strong>{buy_select_value} {buy_select_acc} </strong></p>
                                                    : ""}
                                                <button type="button" onClick={buysubmit} className="btn btn-primary btn-rounded">{t('CRYPTOTRANSACTION.BUY_TITLE')}</button>
                                                {buy_crypto_div === true ?
                                                    <p className="mt-5">1 {buy_coin_name} = <strong>{buy_usd_value} </strong></p>
                                                    : ""}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="02" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
                                    <div className="profile-personal-info">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.SELECTCOIN")} </span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateCryptoSell(e.target.value)} value={sell_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.crypto_id} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {sell_crypto_error !== "" ? <label className='text-red'>{sell_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{sell_coin_name !== "" ? sell_coin_name : ""} {t("TRANSACTION.AMOUNT")} </label>
                                                    <input type="text" className="form-control" onChange={(e) => validateAmountSell(e.target.value)} value={sell_amount} placeholder={t("TRANSACTION.AMOUNT_PLACE")} />
                                                    {sell_amount_error !== "" ? <label className='text-red'>{sell_amount_error}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>{t("CRYPTOTRANSACTION.TRANFER_TO")}</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateAccountSell(e.target.value)} value={sell_account}>
                                                        <option value="">{t('FIAT.SELECT_ACCOUNT')}</option>
                                                        {allaccount && allaccount.map((row) => (
                                                            <option value={row.account_id_pk + "|" + row.account_currency_type} key={row.account_id_pk}>{t('KYC.MYACCOUNT')} : {row.account_number} ( {row.account_balance} {row.account_currency_type === "USD" ? '$' : '€'} ) </option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {sell_account_error !== "" ? <label className='text-red'>{sell_account_error}</label> : ""}
                                            </div>
                                            {parseFloat(sellminamount) > 0 ?
                                                <div className="col-md-12 mt-4">
                                                    <label className="mb-2 mr-3 minmaxclass">Minimum Amount = {sellminamount} </label>
                                                    <label className="mb-2 minmaxclass">Maximum Amount = {sellmaxamount} </label>
                                                </div>
                                                : ""}
                                            <div className="col-md-12">
                                                {sell_acc_div === true ?
                                                    <p className="mt-1 mb-4 highlight-text-coin">{t('CRYPTOTRANSACTION.TOTAL')} : <strong>{sell_select_value} {sell_select_acc} </strong></p>
                                                    : ""}
                                                <button type="button" onClick={sellsubmit} className="btn btn-primary btn-rounded">{t('CRYPTOTRANSACTION.SELL_TITLE')}</button>
                                                {sell_crypto_div === true ?
                                                    <p className="mt-5">1 {sell_coin_name} = <strong>{sell_usd_value} </strong> </p>
                                                    : ""}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="03" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
                                    <div className="profile-personal-info">
                                        <div className="row position-relative z-111">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="form-label to-hightlight">{t('FIAT.FROM')}</label>
                                                    <label className="mb-2 ">
                                                        <span>{t('CRYPTOTRANSACTION.CRYPTO_ASSET')}</span>
                                                    </label>
                                                    <select className="form-control form-control-lg webkit-none" id="sel1" onChange={(e) => validateCryptoSwapFrom(e.target.value)} value={swap_from_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.coin_code} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {swap_from_crypto_error !== "" ? <label className='text-red'>{swap_from_crypto_error}</label> : ""}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="form-label to-hightlight">{t('FIAT.TO')}</label>
                                                    <label className="mb-2 ">
                                                        <span>{t('CRYPTOTRANSACTION.CRYPTO_ASSET')}</span>
                                                    </label>
                                                    <select className="form-control form-control-lg webkit-none" id="sel1" onChange={(e) => validateCryptoSwapTo(e.target.value)} value={swap_to_crypto}>
                                                        <option value="">{t("CRYPTOTRANSACTION.SELECTCOIN")}</option>
                                                        {allcrypto && allcrypto.map((row) => (
                                                            <option value={row.coin_code} key={row.crypto_wallet_id}>{row.coin_code + " (" + row.total_available + " " + row.coin_code + ")"}</option>
                                                        ))}
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                                {swap_to_crypto_error !== "" ? <label className='text-red'>{swap_to_crypto_error}</label> : ""}
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t("TRANSACTION.AMOUNT")}</label>
                                                    <input type="text" className="form-control" onChange={(e) => validateAmountSwap(e.target.value)} value={swap_amount} placeholder={t("TRANSACTION.AMOUNT_PLACE")} />
                                                    {swap_amount_error !== "" ? <label className='text-red'>{swap_amount_error}</label> : ""}
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <label className="form-label total-value">= {swap_coin_amount} {swap_coin_name}</label>
                                            </div>

                                            {/* <div className="col-md-12 mb-5">
                                                <button type="button" className="exchange-max">Exchange Max</button>
                                            </div> */}
                                            {parseFloat(swapminamount) > 0 ?
                                                <div className="col-md-12 mt-4">
                                                    <label className="mb-2 mr-3 minmaxclass">Minimum Amount = {swapminamount} </label>
                                                    <label className="mb-2 minmaxclass">Maximum Amount = {swapmaxamount} </label>
                                                </div>
                                                : ""}
                                            <div className="col-md-12">
                                                <button type="button" onClick={swapsubmit} className="btn btn-primary btn-rounded">{t("CRYPTOTRANSACTION.PREVIEW_TRANSACT")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card hide-scrolllbar">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {exchnagetra.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.BUYSELLLA")}
                                        columns={[
                                            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                                            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                                            { title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
                                            { title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
                                            { title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
                                            { title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
                                        ]}
                                        data={exchnagetra}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDFsecond("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDFsecond("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.BUYSELLLA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {swaptra.length > 0 ?
                                    <MaterialTable
                                        title={t("TRANSACTION.SWAP_TRA_LA")}
                                        columns={[
                                            { title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
                                            { title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
                                            { title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
                                            { title: t("FIAT.FROM"), field: 'from_coin' },
                                            { title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
                                            { title: t("FIAT.TO"), field: 'to_coin' },
                                            { title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
                                        ]}
                                        data={swaptra}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.SWAP_TRA_LA")} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={OTPVerification}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</label>
                                            <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuth(e.target.value)} value={google_auth} placeholder={t("LABEL.ENTER_GOOGLE_AUTH_CODE")} />
                                            {google_auth_error !== "" ? <label className='text-red'>{google_auth_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t("BUTTONS.CANCEL")}</button>
                                <button type="button" onClick={finalbuysubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={SMSOTPVerification}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                <button type="button" className="close" onClick={() => setSMSOTPVerification(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                            <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCodeBuy(e.target.value)} value={buyotpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                            <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                            <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                        </div>
                                        {buyotpmessage_error !== "" ? <label className='text-red'>{buyotpmessage_error}</label> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setSMSOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                <button type="button" onClick={finalbuysubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }


            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={SMSOTPVerificationSell}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                <button type="button" className="close" onClick={() => setSMSOTPVerificationSell(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                            <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCodeSell(e.target.value)} value={sellotpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                            <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                            <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                        </div>
                                        {sellotpmessage_error !== "" ? <label className='text-red'>{sellotpmessage_error}</label> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setSMSOTPVerificationSell(false)}>{t('BUTTONS.CANCEL')}</button>
                                <button type="button" onClick={finalsellsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={SMSOTPVerificationSwap}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                <button type="button" className="close" onClick={() => setSMSOTPVerificationSwap(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                            <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCodeSwap(e.target.value)} value={swapotpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                            <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                            <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                        </div>
                                        {swapotpmessage_error !== "" ? <label className='text-red'>{swapotpmessage_error}</label> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setSMSOTPVerificationSwap(false)}>{t('BUTTONS.CANCEL')}</button>
                                <button type="button" onClick={finalswapsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }






            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={OTPVerificationSell}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                <button type="button" className="close" onClick={() => setOTPVerificationSell(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")} </label>
                                            <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuthSell(e.target.value)} value={google_auth_sell} placeholder={t("LABEL.ENTER_GOOGLE_AUTH_CODE")} />
                                            {google_auth_sell_error !== "" ? <label className='text-red'>{google_auth_sell_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerificationSell(false)}>{t("BUTTONS.CANCEL")}</button>
                                <button type="button" onClick={finalsellsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={OTPVerificationSwap}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                <button type="button" className="close" onClick={() => setOTPVerificationSwap(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2 ">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")} </label>
                                            <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuthSwap(e.target.value)} value={google_auth_swap} placeholder={t("LABEL.ENTER_GOOGLE_AUTH_CODE")} />
                                            {google_auth_swap_error !== "" ? <label className='text-red'>{google_auth_swap_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerificationSwap(false)}>{t("BUTTONS.CANCEL")}</button>
                                <button type="button" onClick={finalswapsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <Modal className="modal fade" show={TransactionDetails}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t("TRANSACTION.TRANSACTION_DETAILS")}</h3>
                                <button type="button" className="close" onClick={() => setTransactionDetails(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="Deduction-text">{t("CRYPTOTRANSACTION.TOTAL")} {swap_from_crypto} {t("CRYPTOTRANSACTION.DEDUCTION")} : <strong> {swap_popup_from_value} {swap_from_crypto}</strong></p>
                                <p className="text-success collect">{swap_to_crypto} : <strong> {swap_popup_to_value} {swap_to_crypto}</strong></p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={finalauthubmit} className="btn btn-light btn-rounded light">{t("BUTTONS.SUBMIT")}</button>
                                <button onClick={() => setTransactionDetails(false)} className="btn btn-primary btn-rounded">{t("BUTTONS.CANCEL")}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }


            <Modal className="modal fade" show={TransferAcceptBuy}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Buy Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferAcceptBuy(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-check"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                            </div>
                            {success_display_credited_amount_buy === "" || success_display_credited_amount_buy === undefined || success_display_credited_amount_buy === null ? " " :
                                <>
                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Credited Crypto Amount : <span className="text-black">{success_display_credited_amount_buy}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Debited Fiat Amount : <span className="text-black">{success_display_debit_fiat_amount_buy}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Debited from {success_display_debited_cur_buy} account : <span className="text-black">{success_display_debited_account_buy}</span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno_buy}</span></p>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{success_display_details_buy}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferAcceptBuy(false)}> New Buy Crypto Order</Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade" show={TransferAcceptSell}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Sell Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferAcceptSell(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-check"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                            </div>
                            {success_display_credited_amount_sell === "" || success_display_credited_amount_sell === undefined || success_display_credited_amount_sell === null ? " " :
                                <>
                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Debited Crypto Amount : <span className="text-black">{success_display_credited_amount_sell}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Credited Fiat Amount : <span className="text-black">{success_display_debit_fiat_amount_sell}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Debited from {success_display_debited_cur_sell} account : <span className="text-black">{success_display_debited_account_sell}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno_sell}</span></p>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{success_display_details_sell}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferAcceptSell(false)}> New Sell Crypto Order</Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal className="modal fade" show={TransferAcceptSwap}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Swap Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferAcceptSwap(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-check"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                            </div>
                            {success_display_credited_amount_swap === "" || success_display_credited_amount_swap === undefined || success_display_credited_amount_swap === null ? " " :
                                <>
                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Credited Crypto Amount : <span className="text-black">{success_display_credited_amount_swap}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Debited Crypto Amount : <span className="text-black">{success_display_debit_fiat_amount_swap}</span></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mb-1">
                                            <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno_swap}</span></p>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{success_display_details_swap}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferAcceptSwap(false)}> New Swap Order</Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade" show={TransferDeclineBuy}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Buy Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferDeclineBuy(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-times"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount_buy}</span></p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{error_display_details_buy}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferDeclineBuy(false)}> Retry </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal className="modal fade" show={TransferDeclineSell}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Sell Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferDeclineSell(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-times"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount_sell}</span></p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{error_display_details_sell}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferDeclineSell(false)}> Retry </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal className="modal fade" show={TransferDeclineSwap}>
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Swap Crypto Order</h3>
                            <button type="button" className="close" onClick={() => setTransferDeclineSwap(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="check">
                                <i class="fa fa-solid fa-times"></i>
                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount_swap}</span></p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-md-12 mb-3">
                                    <p className="fs-16 mb-1">Details</p>
                                    <span className="text-black">{error_display_details_swap}</span>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <ul className="btm-link">
                                    <li>
                                        <Link to="#" onClick={() => setTransferDeclineSwap(false)}> Retry </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard"> Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="/crypto-transactions"> Transaction List</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Crypto_Exchanges;