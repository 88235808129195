import React, { useState, useEffect, forwardRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Switch from "react-switch";
import pcard from '../../../images/card/p-card.png';
import dualdot from '../../../images/dual-dot.png';
import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';

export const CardSettings = (props) => {

    const history = useHistory();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [cardid, setcardid] = useState(props.match.params.id);
    const [loader, setLoader] = useState("");

    const [cardname, setcardname] = useState("");
    const [cardbalance, setcardbalance] = useState("");
    const [cardnumber, setcardnumber] = useState("");
    const [exp_month, setexp_month] = useState("");
    const [exp_year, setexp_year] = useState("");
    const [card_cus_name, setcard_cus_name] = useState("");



    const [Checked, setChecked] = useState(true);
    const handleChange = (CheckedVal) => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setwithservice(false);
        axios.post(process.env.REACT_APP_API_URL + 'updateoverall', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const [Checkedone, setCheckedone] = useState(true);
    const handleChangeone = (CheckedVal) => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setwithservice(false);
        axios.post(process.env.REACT_APP_API_URL + 'updatecontactless', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const [Checkedtwo, setCheckedtwo] = useState(true);
    const handleChangetwo = (CheckedVal) => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setwithservice(false);
        axios.post(process.env.REACT_APP_API_URL + 'updatewith', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });

    }

    const [Checkedthree, setCheckedthree] = useState(true);
    const handleChangethree = (CheckedVal) => {

        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setwithservice(false);
        axios.post(process.env.REACT_APP_API_URL + 'updatepurchase', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const [activeToggle, setActiveToggle] = useState("Daily");
    const [CurrentPrepaidMaximum, setCurrentPrepaidMaximum] = useState(false);
    const [Withdrawalfromaccount, setWithdrawalfromaccount] = useState(false);
    const [CardStatus, setCardStatus] = useState(false);

    const [cardstatuss, setcardstatuss] = useState("");
    const [cardaccount, setcardaccount] = useState("");
    const [cardcurrancy, setcardcurrancy] = useState("");

    const [international_atm_divide, setinternational_atm_divide] = useState(0);
    const [international_atm_max, setinternational_atm_max] = useState(0);
    const [international_atm_used, setinternational_atm_used] = useState(0);
    const [international_pos_divide, setinternational_pos_divide] = useState(0);
    const [international_pos_max, setinternational_pos_max] = useState(0);
    const [international_pos_used, setinternational_pos_used] = useState(0);
    const [combined_international, setcombined_international] = useState(0);
    const [cardsym, setcardsym] = useState("");

    const [withdrawenable, setwithdrawenable] = useState("");
    const [contactlessenable, setcontactlessenable] = useState("");
    const [purchaseenable, setpurchaseenable] = useState("");

    const [withalert, setwithalert] = useState("");
    const [smsalert, setsmsalert] = useState("");
    const [withservice, setwithservice] = useState(false);

    const [OverallDaily, setOverallDaily] = useState(0);
    const [OverallWeekly, setOverallWeekly] = useState(0);
    const [OverallMonthly, setOverallMonthly] = useState(0);

    const [OverallDaily_error, setOverallDaily_error] = useState("");
    const [OverallWeekly_error, setOverallWeekly_error] = useState("");
    const [OverallMonthly_error, setOverallMonthly_error] = useState("");


    const [DailyPurchase, setDailyPurchase] = useState(0);
    const [DailyWithdrawal, setDailyWithdrawal] = useState(0);
    const [DailyInternet, setDailyInternet] = useState(0);
    const [DailyContactless, setDailyContactless] = useState(0);

    const [WeeklyPurchase, setWeeklyPurchase] = useState(0);
    const [WeeklyWithdrawal, setWeeklyWithdrawal] = useState(0);
    const [WeeklyInternet, setWeeklyInternet] = useState(0);
    const [WeeklyContactless, setWeeklyContactless] = useState(0);

    const [MonthlyPurchase, setMonthlyPurchase] = useState(0);
    const [MonthlyWithdrawal, setMonthlyWithdrawal] = useState(0);
    const [MonthlyInternet, setMonthlyInternet] = useState(0);
    const [MonthlyContactless, setMonthlyContactless] = useState(0);

    const [TransactionPurchase, setTransactionPurchase] = useState(0);
    const [TransactionWithdrawal, setTransactionWithdrawal] = useState(0);
    const [TransactionInternet, setTransactionInternet] = useState(0);
    const [TransactionContactless, setTransactionContactless] = useState(0);

    const [DailyPurchase_error, setDailyPurchase_error] = useState("");
    const [DailyWithdrawal_error, setDailyWithdrawal_error] = useState("");
    const [DailyInternet_error, setDailyInternet_error] = useState("");
    const [DailyContactless_error, setDailyContactless_error] = useState("");

    const [WeeklyPurchase_error, setWeeklyPurchase_error] = useState("");
    const [WeeklyWithdrawal_error, setWeeklyWithdrawal_error] = useState("");
    const [WeeklyInternet_error, setWeeklyInternet_error] = useState("");
    const [WeeklyContactless_error, setWeeklyContactless_error] = useState("");

    const [MonthlyPurchase_error, setMonthlyPurchase_error] = useState("");
    const [MonthlyWithdrawal_error, setMonthlyWithdrawal_error] = useState("");
    const [MonthlyInternet_error, setMonthlyInternet_error] = useState("");
    const [MonthlyContactless_error, setMonthlyContactless_error] = useState("");

    const [TransactionPurchase_error, setTransactionPurchase_error] = useState("");
    const [TransactionWithdrawal_error, setTransactionWithdrawal_error] = useState("");
    const [TransactionInternet_error, setTransactionInternet_error] = useState("");
    const [TransactionContactless_error, setTransactionContactless_error] = useState("");



    useEffect(() => {
        setcardid(props.match.params.id);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = async () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getcarddetailsbyid', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setcardname(res.cardinfo[0].card_name);
                    if (res.data[0].card_currancy === "EUR") {
                        setcardbalance("€" + res.data[0].card_balance);
                        setcardsym("€");
                    } else {
                        setcardbalance("$" + res.data[0].card_balance);
                        setcardsym("$");
                    }

                    setcardnumber(res.data[0].card_number);
                    setexp_month(res.data[0].exp_month);
                    setexp_year(res.data[0].exp_year);
                    setcard_cus_name(res.data[0].card_customer_name);
                    setcardstatuss(res.data[0].usercard_status);
                    setcardaccount(res.accnumber[0].account_number);
                    setcardcurrancy(res.data[0].card_currancy);


                    setinternational_atm_max(res.data[0].atminternationallimit);
                    setinternational_pos_max(res.data[0].poscircuitlimit);
                    setinternational_atm_used(0);
                    setinternational_pos_used(0);

                    var iused = parseFloat(res.data[0].atminternationallimit) / parseFloat(0);
                    var posused = parseFloat(res.data[0].poscircuitlimit) / parseFloat(0);
                    var combine = parseFloat(res.data[0].atminternationallimit) + parseFloat(res.data[0].poscircuitlimit);
                    setinternational_atm_divide(iused);
                    setinternational_pos_divide(posused);
                    setcombined_international(combine);

                    setwithdrawenable(res.data[0].withdrawal_enabled);
                    setcontactlessenable(res.data[0].contactless_enabled);
                    setpurchaseenable(res.data[0].internet_purchase_enabled);
                    setwithalert(res.data[0].withdraw_fromaccount);
                    setsmsalert(res.data[0].smsalert);

                    setChecked(res.data[0].overall_limits_enabled === "Yes" ? true : false);
                    setCheckedone(res.data[0].contactless_enabled === "Yes" ? true : false);
                    setCheckedtwo(res.data[0].withdrawal_enabled === "Yes" ? true : false);
                    setCheckedthree(res.data[0].internet_purchase_enabled === "Yes" ? true : false);

                    setOverallDaily(res.data[0].dailyoverall);
                    setOverallWeekly(res.data[0].weeklyoverall);
                    setOverallMonthly(res.data[0].monthlyoverall);

                    setDailyPurchase(res.displayDaily[0].purchase);
                    setDailyWithdrawal(res.displayDaily[0].withdraw);
                    setDailyInternet(res.displayDaily[0].internet);
                    setDailyContactless(res.displayDaily[0].contactless);

                    setWeeklyPurchase(res.displayWeekly[0].purchase);
                    setWeeklyWithdrawal(res.displayWeekly[0].withdraw);
                    setWeeklyInternet(res.displayWeekly[0].internet);
                    setWeeklyContactless(res.displayWeekly[0].contactless);

                    setMonthlyPurchase(res.displayMonthly[0].purchase);
                    setMonthlyWithdrawal(res.displayMonthly[0].withdraw);
                    setMonthlyInternet(res.displayMonthly[0].internet);
                    setMonthlyContactless(res.displayMonthly[0].contactless);

                    setTransactionPurchase(res.displayTransaction[0].purchase);
                    setTransactionWithdrawal(res.displayTransaction[0].withdraw);
                    setTransactionInternet(res.displayTransaction[0].internet);
                    setTransactionContactless(res.displayTransaction[0].contactless);

                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const smsalertupdate = () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setWithdrawalfromaccount(false);
        axios.post(process.env.REACT_APP_API_URL + 'updatesmsalert', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const withalertupdate = () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: cardid
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setwithservice(false);
        axios.post(process.env.REACT_APP_API_URL + 'updatwithalert', jsonpar, { headers: headers })
            .then(async function (res) {
                res = await res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const updateOverall = () => {
        if (validateOverallDaily(OverallDaily) === false) {
            setOverallDaily_error("Enter valid amount");
        } else if (validateOverallWeekly(OverallWeekly) === false) {
            setOverallDaily_error("");
            setOverallWeekly_error("Enter valid amount");
        } else if (validateOverallMonthly(OverallMonthly) === false) {
            setOverallWeekly_error("");
            setOverallMonthly_error("Enter valid amount");
        } else {
            setOverallMonthly_error("");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                card_id: cardid,
                dailyoverall: OverallDaily,
                weeklyoverall: OverallWeekly,
                monthlyoverall: OverallMonthly,
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }

            setWithdrawalfromaccount(false);
            axios.post(process.env.REACT_APP_API_URL + 'updateoveralldata', jsonpar, { headers: headers })
                .then(async function (res) {
                    res = await res.data;
                    if (res.code === "200") {
                        alert(res.message);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        handleLoad();
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }

    const DailyLimitSubmit = () => {
        if (validateDailyPurchase(DailyPurchase) === false) {
            setDailyPurchase_error("Enter valid amount");
        } else if (validateDailyWithdrawal(DailyWithdrawal) === false) {
            setDailyPurchase_error("");
            setDailyWithdrawal_error("Enter valid amount");
        } else if (validateDailyInternet(DailyInternet) === false) {
            setDailyWithdrawal_error("");
            setDailyInternet_error("Enter valid amount");
        } else if (validateDailyContactless(DailyContactless) === false) {
            setDailyInternet_error("");
            setDailyContactless_error("Enter valid amount");
        } else {
            setDailyContactless_error("");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                card_id: cardid,
                purchase: DailyPurchase,
                withdrawal: DailyWithdrawal,
                internet: DailyInternet,
                contactless: DailyContactless,
                limittype: "Daily",
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'updatecardlimitbytype', jsonpar, { headers: headers })
                .then(async function (res) {
                    res = await res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                        handleLoad();
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }

    const WeeklyLimitSubmit = () => {
        if (validateWeeklyPurchase(WeeklyPurchase) === false) {
            setWeeklyPurchase_error("Enter valid amount");
        } else if (validateWeeklyWithdrawal(WeeklyWithdrawal) === false) {
            setWeeklyPurchase_error("");
            setWeeklyWithdrawal_error("Enter valid amount");
        } else if (validateWeeklyInternet(WeeklyInternet) === false) {
            setWeeklyWithdrawal_error("");
            setWeeklyInternet_error("Enter valid amount");
        } else if (validateWeeklyContactless(WeeklyContactless) === false) {
            setWeeklyInternet_error("");
            setWeeklyContactless_error("Enter valid amount");
        } else {
            setWeeklyContactless_error("");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                card_id: cardid,
                purchase: WeeklyPurchase,
                withdrawal: WeeklyWithdrawal,
                internet: WeeklyInternet,
                contactless: WeeklyContactless,
                limittype: "Weekly",
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'updatecardlimitbytype', jsonpar, { headers: headers })
                .then(async function (res) {
                    res = await res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                        handleLoad();
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }


    const MonthlyLimitSubmit = () => {
        if (validateMonthlyPurchase(MonthlyPurchase) === false) {
            setMonthlyPurchase_error("Enter valid amount");
        } else if (validateMonthlyWithdrawal(MonthlyWithdrawal) === false) {
            setMonthlyPurchase_error("");
            setMonthlyWithdrawal_error("Enter valid amount");
        } else if (validateMonthlyInternet(MonthlyInternet) === false) {
            setMonthlyWithdrawal_error("");
            setMonthlyInternet_error("Enter valid amount");
        } else if (validateMonthlyContactless(MonthlyContactless) === false) {
            setMonthlyInternet_error("");
            setMonthlyContactless_error("Enter valid amount");
        } else {
            setMonthlyContactless_error("");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                card_id: cardid,
                purchase: MonthlyPurchase,
                withdrawal: MonthlyWithdrawal,
                internet: MonthlyInternet,
                contactless: MonthlyContactless,
                limittype: "Monthly",
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            setWithdrawalfromaccount(false);
            axios.post(process.env.REACT_APP_API_URL + 'updatecardlimitbytype', jsonpar, { headers: headers })
                .then(async function (res) {
                    res = await res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                        handleLoad();
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }


    const TransactionLimitSubmit = () => {
        if (validateTransactionPurchase(TransactionPurchase) === false) {
            setTransactionPurchase_error("Enter valid amount");
        } else if (validateTransactionWithdrawal(TransactionWithdrawal) === false) {
            setTransactionPurchase_error("");
            setTransactionWithdrawal_error("Enter valid amount");
        } else if (validateTransactionInternet(TransactionInternet) === false) {
            setTransactionWithdrawal_error("");
            setTransactionInternet_error("Enter valid amount");
        } else if (validateTransactionContactless(TransactionContactless) === false) {
            setTransactionInternet_error("");
            setTransactionContactless_error("Enter valid amount");
        } else {
            setTransactionContactless_error("");
            var jsonpar = {
                userid: window.sessionStorage.getItem("user_id"),
                card_id: cardid,
                purchase: TransactionPurchase,
                withdrawal: TransactionWithdrawal,
                internet: TransactionInternet,
                contactless: TransactionContactless,
                limittype: "Transaction",
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            setWithdrawalfromaccount(false);
            axios.post(process.env.REACT_APP_API_URL + 'updatecardlimitbytype', jsonpar, { headers: headers })
                .then(async function (res) {
                    res = await res.data;
                    if (res.code === "200") {
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                        handleLoad();
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                        setLoader(false);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }

    const validateOverallDaily = (value) => {
        setOverallDaily(value);
        if (validator.isNumeric(value)) {
            setOverallDaily_error("");
            return true;
        } else {
            setOverallDaily_error("Enter valid amount");
            return false;
        }
    }

    const validateOverallWeekly = (value) => {
        setOverallWeekly(value);
        if (validator.isNumeric(value)) {
            setOverallWeekly_error("");
            return true;
        } else {
            setOverallWeekly_error("Enter valid amount");
            return false;
        }
    }

    const validateOverallMonthly = (value) => {
        setOverallMonthly(value);
        if (validator.isNumeric(value)) {
            setOverallMonthly_error("");
            return true;
        } else {
            setOverallMonthly_error("Enter valid amount");
            return false;
        }
    }

    const validateDailyPurchase = (value) => {
        setDailyPurchase(value);
        if (validator.isNumeric(value)) {
            setDailyPurchase_error("");
            return true;
        } else {
            setDailyPurchase_error("Enter valid amount");
            return false;
        }
    }


    const validateDailyWithdrawal = (value) => {
        setDailyWithdrawal(value);
        if (validator.isNumeric(value)) {
            setDailyWithdrawal_error("");
            return true;
        } else {
            setDailyWithdrawal_error("Enter valid amount");
            return false;
        }
    }

    const validateDailyInternet = (value) => {
        setDailyInternet(value);
        if (validator.isNumeric(value)) {
            setDailyInternet_error("");
            return true;
        } else {
            setDailyInternet_error("Enter valid amount");
            return false;
        }
    }

    const validateDailyContactless = (value) => {
        setDailyContactless(value);
        if (validator.isNumeric(value)) {
            setDailyContactless_error("");
            return true;
        } else {
            setDailyContactless_error("Enter valid amount");
            return false;
        }
    }


    const validateWeeklyPurchase = (value) => {
        setWeeklyPurchase(value);
        if (validator.isNumeric(value)) {
            setWeeklyPurchase_error("");
            return true;
        } else {
            setWeeklyPurchase_error("Enter valid amount");
            return false;
        }
    }


    const validateWeeklyWithdrawal = (value) => {
        setWeeklyWithdrawal(value);
        if (validator.isNumeric(value)) {
            setWeeklyWithdrawal_error("");
            return true;
        } else {
            setWeeklyWithdrawal_error("Enter valid amount");
            return false;
        }
    }

    const validateWeeklyInternet = (value) => {
        setWeeklyInternet(value);
        if (validator.isNumeric(value)) {
            setWeeklyInternet_error("");
            return true;
        } else {
            setWeeklyInternet_error("Enter valid amount");
            return false;
        }
    }

    const validateWeeklyContactless = (value) => {
        setWeeklyContactless(value);
        if (validator.isNumeric(value)) {
            setWeeklyContactless_error("");
            return true;
        } else {
            setWeeklyContactless_error("Enter valid amount");
            return false;
        }
    }



    const validateMonthlyPurchase = (value) => {
        setMonthlyPurchase(value);
        if (validator.isNumeric(value)) {
            setMonthlyPurchase_error("");
            return true;
        } else {
            setMonthlyPurchase_error("Enter valid amount");
            return false;
        }
    }


    const validateMonthlyWithdrawal = (value) => {
        setMonthlyWithdrawal(value);
        if (validator.isNumeric(value)) {
            setMonthlyWithdrawal_error("");
            return true;
        } else {
            setMonthlyWithdrawal_error("Enter valid amount");
            return false;
        }
    }

    const validateMonthlyInternet = (value) => {
        setMonthlyInternet(value);
        if (validator.isNumeric(value)) {
            setMonthlyInternet_error("");
            return true;
        } else {
            setMonthlyInternet_error("Enter valid amount");
            return false;
        }
    }

    const validateMonthlyContactless = (value) => {
        setMonthlyContactless(value);
        if (validator.isNumeric(value)) {
            setMonthlyContactless_error("");
            return true;
        } else {
            setMonthlyContactless_error("Enter valid amount");
            return false;
        }
    }

    const validateTransactionPurchase = (value) => {
        setTransactionPurchase(value);
        if (validator.isNumeric(value)) {
            setTransactionPurchase_error("");
            return true;
        } else {
            setTransactionPurchase_error("Enter valid amount");
            return false;
        }
    }


    const validateTransactionWithdrawal = (value) => {
        setTransactionWithdrawal(value);
        if (validator.isNumeric(value)) {
            setTransactionWithdrawal_error("");
            return true;
        } else {
            setTransactionWithdrawal_error("Enter valid amount");
            return false;
        }
    }

    const validateTransactionInternet = (value) => {
        setTransactionInternet(value);
        if (validator.isNumeric(value)) {
            setTransactionInternet_error("");
            return true;
        } else {
            setTransactionInternet_error("Enter valid amount");
            return false;
        }
    }

    const validateTransactionContactless = (value) => {
        setTransactionContactless(value);
        if (validator.isNumeric(value)) {
            setTransactionContactless_error("");
            return true;
        } else {
            setTransactionContactless_error("Enter valid amount");
            return false;
        }
    }

    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
        
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Card Settings</h2>
                    <Link to="/my-cards" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;Back</Link>
                </div>
            </div>

            <div className="card card-form">
                <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                    <div className="row hidecardform1">
                        <div className="col-md-4">
                            <div className="card-bx mb-0 mt-3">
                                <img src={pcard} className="" alt="" />
                                <div className="card-info text-white">
                                    <p className="mb-1">{cardname}</p>
                                    <h2 className="fs-36 text-white mb-sm-2 mb-3">{cardbalance}</h2>
                                    <div className="d-flex align-items-center justify-content-between mb-sm-2 mb-3">
                                        <img src={dualdot} alt="" className="dot-img" />
                                        <h4 className="fs-20 text-white mb-0">{"**** **** **** " + String(cardnumber).slice(-4)}</h4>
                                    </div>
                                    <div className="d-flex">
                                        <div className="me-5">
                                            <p className="fs-14 mb-1 op6">VALID THRU</p>
                                            <span>{exp_month + "/" + exp_year}</span>
                                        </div>
                                        <div>
                                            <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                                            <span>{card_cus_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-7">

                            <div className="row mb-2 mt-4">
                                <div className="col-sm-4 col-12">
                                    <h5 className="f-w-500">Heading<span className="pull-end"> :</span>
                                    </h5>
                                </div>
                                <div className="col-sm-8 col-12 text-right text-right-mobile"><span>{cardname}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-4 col-12">
                                    <h5 className="f-w-500">Bank account<span className="pull-end"> :</span>
                                    </h5>
                                </div>
                                <div className="col-sm-8 col-12 text-right text-right-mobile"><span>{cardaccount} ( {cardcurrancy} )</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-4 col-12">
                                    <h5 className="f-w-500">Card number<span className="pull-end"> :</span>
                                    </h5>
                                </div>
                                <div className="col-sm-8 col-12 text-right text-right-mobile"><span>{cardnumber}</span>
                                </div>
                            </div>

                            <div className="hr-line"></div>
                            <div className="row mb-2">
                                <div className="col-sm-4 col-12">
                                    <h5 className="f-w-500">Card Status<span className="pull-end"> :</span>
                                    </h5>
                                </div>
                                <div className="col-sm-8 col-12 text-right text-right-mobile"><span>{cardstatuss}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-4 col-12">
                                    <h5 className="f-w-500">Expire Date<span className="pull-end"> :</span>
                                    </h5>
                                </div>
                                <div className="col-sm-8 col-12 text-right text-right-mobile"><span>{exp_month}/{exp_year}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">Current Prepaid Maximum Limit<span className="float-right fullmWidth">Standard</span></h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <ul className="position-relative cstm-check">
                                    <li><i className="fa fa-check"></i>Set your daily or monthly cap</li>
                                    <li><i className="fa fa-check"></i>You modify it according to your needs</li>
                                    <li><i className="fa fa-check"></i>Receive an email after the change is made</li>
                                </ul>
                            </div>
                            <div className="col-xs-12 mt-3">
                                <Link onClick={() => setCurrentPrepaidMaximum(true)} className="btn btn-primary float-right btn-rounded">Edit</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">International monthly maximum circuit</h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="mb-3">
                                <h5>ATM International circuit </h5>
                                <div className="progress mb-2">
                                    <div className="progress-bar bg-success progress-animated" style={{ width: international_atm_divide + '%' }}>
                                        <span className="sr-only">50% Complete</span>
                                    </div>
                                </div>
                                <h7 className="text-muted">Used {cardsym}{international_atm_used} <span className="float-right">Maximum {cardsym}{international_atm_max}</span></h7>
                            </div>
                            <div className="mb-3">
                                <h5>POS International circuit</h5>
                                <div className="progress mb-2">
                                    <div className="progress-bar bg-success progress-animated" style={{ width: international_pos_divide + '%' }}>
                                        <span className="sr-only">50% Complete</span>
                                    </div>
                                </div>
                                <h7 className="text-muted">Used {cardsym}{international_pos_used} <span className="float-right">Maximum {cardsym}{international_pos_max} </span></h7>
                            </div>
                            <div className="hr-line mt-0"></div>
                            <div className="mb-3">
                                <h5>Residual ATM + POS <span className="float-right">{cardsym}{combined_international} </span></h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" show={CurrentPrepaidMaximum} size="lg">
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Current Prepaid Maximum Limit</h3>
                            <button type="button" className="close" onClick={() => setCurrentPrepaidMaximum(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body profile-tab">
                            <div className="row mb-4 bg-gray-new">
                                <div className="form-check custom-switch toggle-switch text-end col-12 mt-3">
                                    <Switch onChange={handleChange} checked={Checked} className="right-switch" />
                                    <label className="form-check-label fs-14 text-black float-left authentication-left ml-10" for="customSwitch14">Overall Limits Enabled</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-tabs active-tabs">
                                        <li className="nav-item" onClick={() => setActiveToggle("Daily")}>
                                            <Link to="#01" data-toggle="tab" className={`nav-link mr-2 ${activeToggle === "Daily" ? "active show mr-2" : ""}`}>Daily</Link>
                                        </li>
                                        <li className="nav-item" onClick={() => setActiveToggle("Weekly")}>
                                            <Link to="#02" data-toggle="tab" className={`nav-link mr-2 ${activeToggle === "Weekly" ? "active show mr-2" : ""}`}>Weekly</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#03" data-toggle="tab" onClick={() => setActiveToggle("Monthly")} className={`nav-link mr-2 ${activeToggle === "Monthly" ? "active show mr-2" : ""}`}>Monthly</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#04" data-toggle="tab" onClick={() => setActiveToggle("Transaction")} className={`nav-link mr-2 ${activeToggle === "Transaction" ? "active show mr-2" : ""}`}>Transaction</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#05" data-toggle="tab" onClick={() => setActiveToggle("Overall")} className={`nav-link mr-2 ${activeToggle === "Overall" ? "active show mr-2" : ""}`}>Overall Purchase </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="custom-tab-1 setTabDesignbtn">
                                    <div className="tab-content">

                                        <div id="01" className={`tab-pane fade ${activeToggle === "Daily" ? "active show" : ""}`} >
                                            <div className="profile-personal-info mt-4 col-md-12">
                                                <div className="row position-relative z-111">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Daily Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateDailyPurchase(e.target.value)} value={DailyPurchase} />
                                                            {DailyPurchase_error !== "" ? <label className='text-red'>{DailyPurchase_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Daily Withdrawal</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateDailyWithdrawal(e.target.value)} value={DailyWithdrawal} />
                                                            {DailyWithdrawal_error !== "" ? <label className='text-red'>{DailyWithdrawal_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Daily Internet Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateDailyInternet(e.target.value)} value={DailyInternet} />
                                                            {DailyInternet_error !== "" ? <label className='text-red'>{DailyInternet_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Daily Contactless Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateDailyContactless(e.target.value)} value={DailyContactless} />
                                                            {DailyContactless_error !== "" ? <label className='text-red'>{DailyContactless_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-edit-primary mt-3 mb-2">
                                                    <Link to="#" onClick={DailyLimitSubmit} className="btn btn-primary btn-rounded">Update</Link>
                                                    <button className="btn btn-light light btn-rounded ml-2" onClick={() => setCurrentPrepaidMaximum(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="02" className={`tab-pane fade ${activeToggle === "Weekly" ? "active show" : ""}`}>
                                            <div className="profile-personal-info mt-4 col-md-12">
                                                <div className="row position-relative z-111">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Weekly Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateWeeklyPurchase(e.target.value)} value={WeeklyPurchase} />
                                                            {WeeklyPurchase_error !== "" ? <label className='text-red'>{WeeklyPurchase_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Weekly Withdrawal</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateWeeklyWithdrawal(e.target.value)} value={WeeklyWithdrawal} />
                                                            {WeeklyWithdrawal_error !== "" ? <label className='text-red'>{WeeklyWithdrawal_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Weekly Internet Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateWeeklyInternet(e.target.value)} value={WeeklyInternet} />
                                                            {WeeklyInternet_error !== "" ? <label className='text-red'>{WeeklyInternet_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Weekly Contactless Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateWeeklyContactless(e.target.value)} value={WeeklyContactless} />
                                                            {WeeklyContactless_error !== "" ? <label className='text-red'>{WeeklyContactless_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-edit-primary mt-3 mb-2">
                                                    <Link to="#" onClick={WeeklyLimitSubmit} className="btn btn-primary btn-rounded">Update</Link>
                                                    <button className="btn btn-light light btn-rounded ml-2" onClick={() => setCurrentPrepaidMaximum(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="03" className={`tab-pane fade ${activeToggle === "Monthly" ? "active show" : ""}`}>
                                            <div className="profile-personal-info mt-4 col-md-12">
                                                <div className="row position-relative z-111">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Monthly Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateMonthlyPurchase(e.target.value)} value={MonthlyPurchase} />
                                                            {MonthlyPurchase_error !== "" ? <label className='text-red'>{MonthlyPurchase_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Monthly Withdrawal</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateMonthlyWithdrawal(e.target.value)} value={MonthlyWithdrawal} />
                                                            {MonthlyWithdrawal_error !== "" ? <label className='text-red'>{MonthlyWithdrawal_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Monthly Internet Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateMonthlyInternet(e.target.value)} value={MonthlyInternet} />
                                                            {MonthlyInternet_error !== "" ? <label className='text-red'>{MonthlyInternet_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Monthly Contactless Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateMonthlyContactless(e.target.value)} value={MonthlyContactless} />
                                                            {MonthlyContactless_error !== "" ? <label className='text-red'>{MonthlyContactless_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-edit-primary mt-3 mb-2">
                                                    <Link to="#" onClick={MonthlyLimitSubmit} className="btn btn-primary btn-rounded">Update</Link>
                                                    <button className="btn btn-light light btn-rounded ml-2" onClick={() => setCurrentPrepaidMaximum(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="04" className={`tab-pane fade ${activeToggle === "Transaction" ? "active show" : ""}`}>
                                            <div className="profile-personal-info mt-4 col-md-12">
                                                <div className="row position-relative z-111">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Transaction Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateTransactionPurchase(e.target.value)} value={TransactionPurchase} />
                                                            {TransactionPurchase_error !== "" ? <label className='text-red'>{TransactionPurchase_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Transaction Withdrawal</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateTransactionWithdrawal(e.target.value)} value={TransactionWithdrawal} />
                                                            {TransactionWithdrawal_error !== "" ? <label className='text-red'>{TransactionWithdrawal_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Transaction Internet Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateTransactionInternet(e.target.value)} value={TransactionInternet} />
                                                            {TransactionInternet_error !== "" ? <label className='text-red'>{TransactionInternet_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Transaction Contactless Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateTransactionContactless(e.target.value)} value={TransactionContactless} />
                                                            {TransactionContactless_error !== "" ? <label className='text-red'>{TransactionContactless_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-edit-primary mt-3 mb-2">
                                                    <Link to="#" onClick={TransactionLimitSubmit} className="btn btn-primary btn-rounded">Update</Link>
                                                    <button className="btn btn-light light btn-rounded ml-2" onClick={() => setCurrentPrepaidMaximum(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="05" className={`tab-pane fade ${activeToggle === "Overall" ? "active show" : ""}`}>
                                            <div className="profile-personal-info mt-4 col-md-12">
                                                <div className="row position-relative z-111">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Daily Overall Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateOverallDaily(e.target.value)} value={OverallDaily} />
                                                            {OverallDaily_error !== "" ? <label className='text-red'>{OverallDaily_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Weekly Overall Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateOverallWeekly(e.target.value)} value={OverallWeekly} />
                                                            {OverallWeekly_error !== "" ? <label className='text-red'>{OverallWeekly_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-2">Monthly Overall Purchase</label>
                                                            <input type="text" className="form-control text-black" onChange={(e) => validateOverallMonthly(e.target.value)} value={OverallMonthly} />
                                                            {OverallMonthly_error !== "" ? <label className='text-red'>{OverallMonthly_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-edit-primary mt-3 mb-2">
                                                    <Link to="#" onClick={updateOverall} className="btn btn-primary btn-rounded">Update</Link>
                                                    <button className="btn btn-light light btn-rounded ml-2" onClick={() => setCurrentPrepaidMaximum(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">Withdrawal from account</h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <ul className="position-relative cstm-check">
                                    <li><i className="fa fa-check"></i>Set your daily or monthly cap</li>
                                    <li><i className="fa fa-check"></i>You modify it according to your needs</li>
                                    <li><i className="fa fa-check"></i>Receive an email after the change is made</li>
                                </ul>
                            </div>
                            <div className="col-xs-12 mt-3">
                                <Link onClick={() => setwithservice(true)} className="btn btn-primary float-right btn-rounded">{withalert === "Yes" ? "Active" : "Inactive"}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">3D Secure <span className="float-right">Active</span></h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <ul className="position-relative cstm-check">
                                    <li><i className="fa fa-check"></i>The 3D Secure protection service (Mastercard Identity Checke Verified by Visa) guarantees extra protection for your online purchases to prevent any illegal use of the card. The 3D Secure service is free.</li>
                                    <li><i className="fa fa-check"></i>For greater security in online payments, some sites require an additional security code: enable your card for the 3D Secure service to receive it via SMS with each purchase.</li>
                                    <li><i className="fa fa-check"></i>The mobile number associated with the 3D Secure service is the same as the one associated with the Digital Banking service. If you wish to change it, call Customer Service on the toll-free number 9898989898 or contact your branch.</li>
                                    <li><i className="fa fa-check"></i>Together with the SMS, use the Online PIN you have chosen to securely complete your online payments.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" show={Withdrawalfromaccount}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">SMS alert Services Confirmation</h3>
                            <button type="button" className="close" onClick={() => setWithdrawalfromaccount(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="mb-0">Are you sure you want to change status of this Services?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-light btn-rounded light" onClick={() => setWithdrawalfromaccount(false)}>Close</button>
                            <Link to="#" onClick={smsalertupdate} className="btn btn-primary btn-rounded">Yes</Link>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade" show={withservice}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Withdraw From Account Service Confirmation</h3>
                            <button type="button" className="close" onClick={() => setwithservice(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="mb-0">Are you sure you want to change status of this Services?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-light btn-rounded light" onClick={() => setwithservice(false)}>Close</button>
                            <Link to="#" onClick={withalertupdate} className="btn btn-primary btn-rounded">Yes</Link>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">Card status</h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <ul className="position-relative cstm-check">
                                    <li><i className="fa fa-check"></i>ATM: <strong>{withdrawenable === "Yes" ? "Active" : "Inactive"}</strong></li>
                                    <li><i className="fa fa-check"></i>POS: <strong>{contactlessenable === "Yes" ? "Active" : "Inactive"}</strong></li>
                                    <li><i className="fa fa-check"></i>Web: <strong>{purchaseenable === "Yes" ? "Active" : "Inactive"}</strong></li>
                                </ul>
                            </div>
                            <div className="col-xs-12 mt-3">
                                <Link onClick={() => setCardStatus(true)} className="btn btn-primary float-right btn-rounded">Edit</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex pb-0">
                            <div className="col-sm-12 p-0">
                                <h4 className="fs-20 text-black">SMS Alert</h4>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                            <div className="row hidecardform1">
                                <ul className="position-relative cstm-check">
                                    <li><i className="fa fa-check"></i>Receive an SMS every time you make a payment or withdrawal with your card for an amount equal to or greater than the threshold you set.</li>
                                    <li><i className="fa fa-check"></i>Keep an eye on your expenses.</li>
                                    <li><i className="fa fa-check"></i>The mobile number associated with the SMS Alert service is the same as the one associated with the Digital Banking service</li>
                                </ul>
                            </div>
                            <div className="col-xs-12 mt-3">
                                <Link onClick={() => setWithdrawalfromaccount(true)} className="btn btn-primary float-right btn-rounded">{smsalert === "Yes" ? "Active" : "Inactive"}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal fade" show={CardStatus}>
                    <div className="modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Card Status</h3>
                                <button type="button" className="close" onClick={() => setCardStatus(false)}><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row bg-gray-modal-switch">
                                    <div className="form-check custom-switch toggle-switch text-end col-12 mt-3">
                                        <Switch onChange={handleChangeone} checked={Checkedone} className="right-switch" />
                                        <label className="form-check-label fs-14 text-black float-left ml-0">Contactless Enabled</label>
                                    </div>
                                </div>
                                <div className="row bg-gray-modal-switch">
                                    <div className="form-check custom-switch toggle-switch text-end col-12 mt-3">
                                        <Switch onChange={handleChangetwo} checked={Checkedtwo} className="right-switch" />
                                        <label className="form-check-label fs-14 text-black float-left ml-0">Withdrawal Enabled</label>
                                    </div>
                                </div>
                                <div className="row bg-gray-modal-switch">
                                    <div className="form-check custom-switch toggle-switch text-end col-12 mt-3">
                                        <Switch onChange={handleChangethree} checked={Checkedthree} className="right-switch" />
                                        <label className="form-check-label fs-14 text-black float-left ml-0">Internet Purchase Enabled</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )

}
export default CardSettings;