import React, { useState,useEffect } from "react";

/// React router dom
import { Link } from "react-router-dom";
import axios from 'axios';

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   const [logo, setlogo] = useState("");
   const [mobilelogo, setmobilelogo] = useState("");

   useEffect(() => {
      handleLoad();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleLoad = () => {
    
      const headers = {
         'Content-Type': 'application/json',
         'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
      }
      axios.get(process.env.REACT_APP_API_URL + 'getUserDetails/' + window.sessionStorage.getItem("user_id"), { headers: headers })
        .then(function (res) {
            res = res.data;
            if (res.code === "200") {
               setlogo(process.env.REACT_APP_FILE_PATH_LOGO + res.data.logosettings[0].logo);
               setmobilelogo(process.env.REACT_APP_FILE_PATH_MOBILELOGO + res.data.logosettings[0].mobile_logo);
              
               let link = document.querySelector("link[rel~='icon']");
               if (!link) {
                  link = document.createElement('link');
                  link.rel = 'icon';
                  document.getElementsByTagName('head')[0].appendChild(link);
               }
               link.href = process.env.REACT_APP_FILE_PATH_FAVICON + res.data.logosettings[0].favicon;
         
            } else {

            }
         })
         .catch(function (error) {

         });
   }
   return (
      <div className="nav-header">
         <Link to="/dashboard" className="brand-logo" id="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />
            <img className="Mobile-Logo" src={mobilelogo} alt="" />
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
