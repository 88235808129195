import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import profile from "../../../images/profile/profile.png";
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import Loader from '../../pages/Loader';

const Profile = () => {
	const { t } = useTranslation();
	const [activeToggle, setActiveToggle] = useState("posts");
	const [first_name, setFirst_name] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [customerid, setCustomerid] = useState("");
	const [dob, setDob] = useState("Not Set Yet");
	const [phonecode, setPhonecode] = useState("");
	const [phonenumber, setPhoneNumber] = useState("");
	const [timezone, setTimezone] = useState("Not Set Yet");
	const [address, setAddress] = useState("Not Set Yet");
	const [error, setError] = useState(false);
	const [error_msg, setErrorMsg] = useState("");
	const [success, setSuccess] = useState(false);
	const [success_msg, setSuccessMsg] = useState("");
	const [LoginChecked, setLoginchecked] = useState(false);
	const [LoginType, setLoginType] = useState("");
	const [TransactionChecked, setTransactionChecked] = useState(false);
	const [TransactionType, setTransactionType] = useState("");
	const [loader, setLoader] = useState(false);
	const [getphoto, setPhoto] = useState("");

	const [FiatDeposit_Checked, setFiatDeposit_Checked] = useState(false);
	const [InternalTransfer_Checked, setInternalTransfer_Checked] = useState(false);
	const [Withdrawal_Checked, setWithdrawal_Checked] = useState(false);
	const [CryptoDeposit_Checked, setCryptoDeposit_Checked] = useState(false);
	const [CryptoTransfer_Checked, setCryptoTransfer_Checked] = useState(false);
	const [CryptoWithdrawal_Checked, setCryptoWithdrawal_Checked] = useState(false);
	const [Buy_Checked, setBuy_Checked] = useState(false);
	const [Sell_Checked, setSell_Checked] = useState(false);
	const [Swap_Checked, setSwap_Checked] = useState(false);
	const [CardAccount_Checked, setCardAccount_Checked] = useState(false);
	const [CardApproved_Checked, setCardApproved_Checked] = useState(false);
	const [Issue_Checked, setIssue_Checked] = useState(false);
	const [AccountCard_Checked, setAccountCard_Checked] = useState(false);


	const [Email_FiatDeposit_Checked, setEmail_FiatDeposit_Checked] = useState(false);
	const [Email_InternalTransfer_Checked, setEmail_InternalTransfer_Checked] = useState(false);
	const [Email_Withdrawal_Checked, setEmail_Withdrawal_Checked] = useState(false);
	const [Email_CryptoDeposit_Checked, setEmail_CryptoDeposit_Checked] = useState(false);
	const [Email_CryptoTransfer_Checked, setEmail_CryptoTransfer_Checked] = useState(false);
	const [Email_CryptoWithdrawal_Checked, setEmail_CryptoWithdrawal_Checked] = useState(false);
	const [Email_Buy_Checked, setEmail_Buy_Checked] = useState(false);
	const [Email_Sell_Checked, setEmail_Sell_Checked] = useState(false);
	const [Email_Swap_Checked, setEmail_Swap_Checked] = useState(false);
	const [Email_CardAccount_Checked, setEmail_CardAccount_Checked] = useState(false);
	const [Email_CardApproved_Checked, setEmail_CardApproved_Checked] = useState(false);
	const [Email_Issue_Checked, setEmail_Issue_Checked] = useState(false);
	const [Email_AccountCard_Checked, setEmail_AccountCard_Checked] = useState(false);

	useEffect(() => {
		handleLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoad = () => {
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}
		setLoader(true);
		axios.get(process.env.REACT_APP_API_URL + 'getUserProfileDetails/' + window.sessionStorage.getItem("user_id"), { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {

					setFirst_name(res.data.first_name);
					setLastName(res.data.last_name);
					setEmail(res.data.email_id);
					setCustomerid(res.data.customer_id);
					setDob(res.data.dob);
					setPhonecode(res.data.phonecode);
					setPhoneNumber(res.data.phone_number);
					setAddress(res.data.address);
					setTimezone(res.data.timezone);

					for (var i = 0; i < res.data.notification.length; i++) {
						if (res.data.notification[i].notification_type === "Buy") {
							if (res.data.notification[i].notification_status === "Enable") {
								setBuy_Checked(true);
							} else {
								setBuy_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Sell") {
							if (res.data.notification[i].notification_status === "Enable") {
								setSell_Checked(true);
							} else {
								setSell_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Swap") {
							if (res.data.notification[i].notification_status === "Enable") {
								setSwap_Checked(true);
							} else {
								setSwap_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Account Card") {
							if (res.data.notification[i].notification_status === "Enable") {
								setAccountCard_Checked(true);
							} else {
								setAccountCard_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Card Account") {
							if (res.data.notification[i].notification_status === "Enable") {
								setCardAccount_Checked(true);
							} else {
								setCardAccount_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Card Approved") {
							if (res.data.notification[i].notification_status === "Enable") {
								setCardApproved_Checked(true);
							} else {
								setCardApproved_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Issue") {
							if (res.data.notification[i].notification_status === "Enable") {
								setIssue_Checked(true);
							} else {
								setIssue_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Crypto Deposit") {
							if (res.data.notification[i].notification_status === "Enable") {
								setCryptoDeposit_Checked(true);
							} else {
								setCryptoDeposit_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Internal Transfer") {
							if (res.data.notification[i].notification_status === "Enable") {
								setInternalTransfer_Checked(true);
							} else {
								setInternalTransfer_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Cypto Transfer") {
							if (res.data.notification[i].notification_status === "Enable") {
								setCryptoTransfer_Checked(true);
							} else {
								setCryptoTransfer_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Fiat Deposit") {
							if (res.data.notification[i].notification_status === "Enable") {
								setFiatDeposit_Checked(true);
							} else {
								setFiatDeposit_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Withdrawal") {
							if (res.data.notification[i].notification_status === "Enable") {
								setWithdrawal_Checked(true);
							} else {
								setWithdrawal_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Crypto Withdrawal") {
							if (res.data.notification[i].notification_status === "Enable") {
								setCryptoWithdrawal_Checked(true);
							} else {
								setCryptoWithdrawal_Checked(false);
							}
						}

						if (res.data.notification[i].notification_type === "Login") {
							if (res.data.notification[i].notification_status === "Enable") {
								setLoginchecked(true);
							} else {
								setLoginchecked(false);
							}
							setLoginType(res.data.notification[i].notification_type);
						}

						if (res.data.notification[i].notification_type === "Transaction") {
							if (res.data.notification[i].notification_status === "Enable") {
								setTransactionChecked(true);
							} else {
								setTransactionChecked(false);
							}
							setTransactionType(res.data.notification[i].notification_type);
						}

					}


					for (var i = 0; i < res.data.emailnotification.length; i++) {
						if (res.data.emailnotification[i].notification_type === "Buy") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_Buy_Checked(true);
							} else {
								setEmail_Buy_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Sell") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_Sell_Checked(true);
							} else {
								setEmail_Sell_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Swap") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_Swap_Checked(true);
							} else {
								setEmail_Swap_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Account Card") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_AccountCard_Checked(true);
							} else {
								setEmail_AccountCard_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Card Account") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_CardAccount_Checked(true);
							} else {
								setEmail_CardAccount_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Card Approved") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_CardApproved_Checked(true);
							} else {
								setEmail_CardApproved_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Issue") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_Issue_Checked(true);
							} else {
								setEmail_Issue_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Crypto Deposit") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_CryptoDeposit_Checked(true);
							} else {
								setEmail_CryptoDeposit_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Internal Transfer") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_InternalTransfer_Checked(true);
							} else {
								setEmail_InternalTransfer_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Cypto Transfer") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_CryptoTransfer_Checked(true);
							} else {
								setEmail_CryptoTransfer_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Fiat Deposit") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_FiatDeposit_Checked(true);
							} else {
								setEmail_FiatDeposit_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Withdrawal") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_Withdrawal_Checked(true);
							} else {
								setEmail_Withdrawal_Checked(false);
							}
						}

						if (res.data.emailnotification[i].notification_type === "Crypto Withdrawal") {
							if (res.data.emailnotification[i].notification_status === "Enable") {
								setEmail_CryptoWithdrawal_Checked(true);
							} else {
								setEmail_CryptoWithdrawal_Checked(false);
							}
						}

					}

					if (res.data.image_64 === null || res.data.image_64 === "") {
						setPhoto(profile);
					} else {
						setPhoto(process.env.REACT_APP_FILE_PATH + "" + res.data.image_64);
					}
					setLoader(false);
				} else {
					setLoader(false);
					setError(true);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeLogin = () => {
		var changel = "Enable";
		if (LoginChecked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": LoginType,
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeTransaction = () => {
		var changeT = "Enable";
		if (TransactionChecked === true) {
			changeT = "Disable";
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": TransactionType,
			"status": changeT,
		}
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		setLoader(true);
		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setError(true);
				setLoader(false);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});

	}


	const handleChangeFiatDeposit = () => {
		var changel = "Enable";
		if (FiatDeposit_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Fiat Deposit",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeInternalTransfer = () => {
		var changel = "Enable";
		if (InternalTransfer_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Internal Transfer",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeWithdrawal = () => {
		var changel = "Enable";
		if (Withdrawal_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Withdrawal",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeCryptoDeposit = () => {
		var changel = "Enable";
		if (CryptoDeposit_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Deposit",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeCryptoTransfer = () => {
		var changel = "Enable";
		if (CryptoTransfer_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Transfer",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeCryptoWithdrawal = () => {
		var changel = "Enable";
		if (CryptoWithdrawal_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Withdrawal",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeBuy = () => {
		var changel = "Enable";
		if (Buy_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Buy",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeSell = () => {
		var changel = "Enable";
		if (Sell_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Sell",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeSwap = () => {
		var changel = "Enable";
		if (Swap_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Swap",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeCardAccount = () => {
		var changel = "Enable";
		if (CardAccount_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Card Account",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeAccountCard = () => {
		var changel = "Enable";
		if (AccountCard_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Account Card",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeCardApproved = () => {
		var changel = "Enable";
		if (CardApproved_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Card Approved",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const handleChangeIssue = () => {
		var changel = "Enable";
		if (Issue_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Issue",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}



	const EmailhandleChangeFiatDeposit = () => {
		var changel = "Enable";
		if (Email_FiatDeposit_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Fiat Deposit",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeInternalTransfer = () => {
		var changel = "Enable";
		if (Email_InternalTransfer_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Internal Transfer",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeWithdrawal = () => {
		var changel = "Enable";
		if (Email_Withdrawal_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Withdrawal",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeCryptoDeposit = () => {
		var changel = "Enable";
		if (Email_CryptoDeposit_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Deposit",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeCryptoTransfer = () => {
		var changel = "Enable";
		if (Email_CryptoTransfer_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Transfer",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeCryptoWithdrawal = () => {
		var changel = "Enable";
		if (Email_CryptoWithdrawal_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Crypto Withdrawal",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeBuy = () => {
		var changel = "Enable";
		if (Email_Buy_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Buy",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeSell = () => {
		var changel = "Enable";
		if (Email_Sell_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Sell",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeSwap = () => {
		var changel = "Enable";
		if (Email_Swap_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Swap",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeCardAccount = () => {
		var changel = "Enable";
		if (Email_CardAccount_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Card Account",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeAccountCard = () => {
		var changel = "Enable";
		if (Email_AccountCard_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Account Card",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeCardApproved = () => {
		var changel = "Enable";
		if (Email_CardApproved_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Card Approved",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}

	const EmailhandleChangeIssue = () => {
		var changel = "Enable";
		if (Email_Issue_Checked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}

		var jsonpar = {
			"user_id": window.sessionStorage.getItem("user_id"),
			"notification_type": "Issue",
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateUserNotificationEmail', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 1000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 6000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 6000);
			});
	}


	return (
		<Fragment>
			<div>
				<div className="row">
					{error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
					{success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
					<div className="col-lg-12">
						<div className="page-titles mb-0	">
							<h2 className="text-black font-w600 mb-0">{t('PROFILE.MAIN_TITLE')}</h2>
						</div>
					</div>

					<div className="col-lg-12">
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="profile-info">
									<div className="profile-photo">
										<img src={getphoto} className="img-fluid rounded-circle" alt="profile" />
									</div>
									<div className="profile-details">
										<div className="profile-name px-5 pt-2">
											<h4 className="text-primary mb-0">{first_name + " " + last_name}</h4>
											<p>{t('PROFILE.FULLNAME')}</p>
										</div>
										<div className="profile-name px-5 pt-2">
											<h4 className="text-primary mb-0">{email}</h4>
											<p>{t('PROFILE.EMAIL')}</p>
										</div>
										<div className="profile-name px-5 pt-2">
											<h4 className="text-primary mb-0">{customerid}</h4>
											<p>{t('PROFILE.CUSTOMERID')}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loader === true ?
					<div>
						<Loader open_close={loader} />
					</div>
					:
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="profile-tab">
										<div className="custom-tab-1">
											<ul className="nav nav-tabs">
												<li className="nav-item" onClick={() => setActiveToggle("posts")}>
													<Link to="#01" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}>{t('PROFILE.PERSONAL_INFO')}</Link>
												</li>
												<li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
													<Link to="#02" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}>{t('PROFILE.SECURITY')}</Link>
												</li>
												<li className="nav-item">
													<Link to="#03" data-toggle="tab" onClick={() => setActiveToggle("NotificationSettings")} className={`nav-link ${activeToggle === "NotificationSettings" ? "active show" : ""}`}>Notification Settings</Link>
												</li>
												<li className="nav-item">
													<Link to="#05" data-toggle="tab" onClick={() => setActiveToggle("Notification")} className={`nav-link ${activeToggle === "Notification" ? "active show" : ""}`}>{t('PROFILE.NOTIFICATION')}</Link>
												</li>
											</ul>
											<div className="tab-content">
												<div id="01" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500"> {t('PROFILE.FULLNAME')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{first_name + " " + last_name}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.EMAIL')} <span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{email}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500"> {t('PROFILE.CUSTOMERID')} <span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{customerid}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.DOB')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{dob === null ? "Not Set Yet" : dob}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500"> {t('LABEL.LABEL_PHONE_NUMBER')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>+{phonecode} {phonenumber}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.TIMEZONE')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{timezone === "" ? "Not Set Yet" : timezone}</span>
															</div>
														</div>

														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.ADDRESS')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{address === "" ? "Not Set Yet" : address}</span>
															</div>
														</div>
														<div className="mb-2 mt-4">
															<Link to="/editprofile" className="btn btn-primary btn-rounded">{t('LINK.EDITPROFILE')}</Link>
														</div>
													</div>
												</div>
												<div id="02" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<h5 className="f-w-500">{t('ERROR_MSG.NODATA_FOUND')}</h5>
															</div>
														</div>
													</div>
												</div>
												<div id="03" className={`tab-pane fade ${activeToggle === "NotificationSettings" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-md-6">
																<div className="Notifications-box">
																	<p class="internal-title">Internal Notifications</p>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fiat fund received (fiat deposit)</label>
																			<Form.Check type="switch" onChange={handleChangeFiatDeposit} checked={FiatDeposit_Checked} id="N14" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer with in the system (Internal Transfer)</label>
																			<Form.Check type="switch" onChange={handleChangeInternalTransfer} checked={InternalTransfer_Checked} id="N2" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer outside the system (Wire Transfer/ Withdrawal)</label>
																			<Form.Check type="switch" onChange={handleChangeWithdrawal} checked={Withdrawal_Checked} id="N3" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund received (crypto deposit)</label>
																			<Form.Check type="switch" onChange={handleChangeCryptoDeposit} checked={CryptoDeposit_Checked} id="N4" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund transfer with in the system (Internal Transfer)</label>
																			<Form.Check type="switch" onChange={handleChangeCryptoTransfer} checked={CryptoTransfer_Checked} id="N5" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund transfer outside the system ( Crypto withdrawal)</label>
																			<Form.Check type="switch" onChange={handleChangeCryptoWithdrawal} checked={CryptoWithdrawal_Checked} id="N6" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When buy the crypto currency</label>
																			<Form.Check type="switch" onChange={handleChangeBuy} checked={Buy_Checked} id="N7" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When sell the crypto currency</label>
																			<Form.Check type="switch" onChange={handleChangeSell} checked={Sell_Checked} id="N8" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When swap the crypto currency</label>
																			<Form.Check type="switch" onChange={handleChangeSwap} checked={Swap_Checked} id="N9" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer to the card (account card)</label>
																			<Form.Check type="switch" onChange={handleChangeAccountCard} checked={AccountCard_Checked} id="N10" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer from card to account (account card)</label>
																			<Form.Check type="switch" onChange={handleChangeCardAccount} checked={CardAccount_Checked} id="N11" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When account card approved</label>
																			<Form.Check type="switch" onChange={handleChangeCardApproved} checked={CardApproved_Checked} id="N12" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When pay the account issuance fee</label>
																			<Form.Check type="switch" onChange={handleChangeIssue} checked={Issue_Checked} id="N13" className="coin-swithc-cstm" />
																		</div>
																	</div>

																</div>
															</div>

															<div className="col-md-6">
																<div className="Notifications-box">
																	<p class="internal-title">Email Notifications</p>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fiat fund received (fiat deposit)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeFiatDeposit} checked={Email_FiatDeposit_Checked} id="N14" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer with in the system (Internal Transfer)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeInternalTransfer} checked={Email_InternalTransfer_Checked} id="N15" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer outside the system (Wire Transfer/ Withdrawal)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeWithdrawal} checked={Email_Withdrawal_Checked} id="N16" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund received (crypto deposit)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeCryptoDeposit} checked={Email_CryptoDeposit_Checked} id="N17" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund transfer with in the system (Internal Transfer)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeCryptoTransfer} checked={Email_CryptoTransfer_Checked} id="N18" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When crypto fund transfer outside the system ( Crypto withdrawal)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeCryptoWithdrawal} checked={Email_CryptoWithdrawal_Checked} id="N19" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When buy the crypto currency</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeBuy} checked={Email_Buy_Checked} id="N20" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When sell the crypto currency</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeSell} checked={Email_Sell_Checked} id="N21" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When swap the crypto currency</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeSwap} checked={Email_Swap_Checked} id="N22" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer to the card (account card)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeAccountCard} checked={Email_AccountCard_Checked} id="N23" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When fund transfer from card to account (account card)</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeCardAccount} checked={Email_CardAccount_Checked} id="N24" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When account card approved</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeCardApproved} checked={Email_CardApproved_Checked} id="N25" className="coin-swithc-cstm" />
																		</div>
																	</div>

																	<div className="settings-toggle-btn mt-3">
																		<div className="Setting-tones">
																			<label className="lable-text">When pay the account issuance fee</label>
																			<Form.Check type="switch" onChange={EmailhandleChangeIssue} checked={Email_Issue_Checked} id="N26" className="coin-swithc-cstm" />
																		</div>
																	</div>

																</div>
															</div>
														</div>
													</div>
												</div>
												<div id="05" className={`tab-pane fade ${activeToggle === "Notification" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<div className="Setting-tones">
																	<label className="lable-text">{t('PROFILE.NOTIFICATION_TONES')} ( {t('PROFILE.LOGIN_TEXT')} )</label>
																	<Form.Check type="switch" onChange={handleChangeLogin} id="custom-switch" className="coin-swithc-cstm" checked={LoginChecked} />
																</div>
																<hr />
																<div className="Setting-tones">
																	<label className="lable-text">{t('PROFILE.NOTIFICATION_TONES')} ( {t('PROFILE.TRANSACTION_TEXT')} )</label>
																	<Form.Check type="switch" onChange={handleChangeTransaction} id="custom-switch1" className="coin-swithc-cstm" checked={TransactionChecked} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
};

export default Profile;
