import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import validator from 'validator';

export const Fiat_Outgoing_Transfers = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [accountarray, setAccountarray] = useState([]);
    const [OTPVerification, setOTPVerification] = useState(false);
    const [DeleteVerification, setDeleteVerification] = useState(false);

    const [account, setaccount] = useState("");
    const [swiftcode, setswiftcode] = useState("");
    const [accountname, setaccountname] = useState("");
    const [address, setaddress] = useState("");
    const [country, setcountry] = useState("");
    const [abatitle, setabatitle] = useState("");
    const [location, setlocation] = useState("");
    const [customer_name, setcustomer_name] = useState("");
    const [customer_address, setcustomer_address] = useState("");
    const [customer_iban, setcustomer_iban] = useState("");
    const [refmessage, setrefmessage] = useState("");
    const [amount, setamount] = useState("");
    const [currancy, setcurrancy] = useState("");
    const [message, setmessage] = useState("");
    const [otpmessage, setotpmessage] = useState("");
    const [otpmessage_error, setotpmessage_error] = useState("");

    const [minamount, setminamount] = useState("");
    const [maxamount, setmaxamount] = useState("");



    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    const [account_error, setaccount_error] = useState("");
    const [swiftcode_error, setswiftcode_error] = useState("");
    const [accountname_error, setaccountname_error] = useState("");
    const [address_error, setaddress_error] = useState("");
    const [country_error, setcountry_error] = useState("");
    const [abatitle_error, setabatitle_error] = useState("");
    const [location_error, setlocation_error] = useState("");
    const [customer_name_error, setcustomer_name_error] = useState("");
    const [customer_address_error, setcustomer_address_error] = useState("");
    const [customer_iban_error, setcustomer_iban_error] = useState("");
    const [refmessage_error, setrefmessage_error] = useState("");
    const [amount_error, setamount_error] = useState("");
    const [currancy_error, setcurrancy_error] = useState("");
    const [message_error, setmessage_error] = useState("");
    const [allcountry, setAllCountry] = useState([]);
    const [template_option, settemplate_option] = useState("");
    const [templatename, settemplatename] = useState("");
    const [checkboxval, setcheckboxval] = useState(false);
    const [templatename_error, settemplatename_error] = useState("");
    const [templatearray, settemplatearray] = useState([]);
    const [deleteLink, setdeleteLink] = useState(false);


    const [GoogleVerification, setGoogleVerification] = useState(false);
    const [transferotp, settransferotp] = useState("");
    const [transferotpmessage, settransferotpmessage] = useState("");
    const [transferotpmessage_error, settransferotpmessage_error] = useState("");
    const [google_auth, setgoogle_auth] = useState("");
    const [google_auth_error, setgoogle_auth_error] = useState("");

    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);

    const [success_display_cur, setsuccess_display_cur] = useState("");
    const [success_display_amount, setsuccess_display_amount] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_fromaccount, setsuccess_display_fromaccount] = useState("");
    const [success_display_fromaccount_cur, setsuccess_display_fromaccount_cur] = useState("");
    const [success_display_toaccount, setsuccess_display_toaccount] = useState("");
    const [success_display_toaccount_cur, setsuccess_display_toaccount_cur] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_cur, seterror_display_cur] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");
    const [hidetemplatediv, sethidetemplatediv] = useState(false);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getuseraccoundsbyid/' + window.sessionStorage.getItem("user_id"), { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    settransferotp(res.outgoingotp);
                    setAccountarray(res.data);
                    setAllCountry(res.getallcountry);
                    // setminamount(res.minamount);
                    // setmaxamount(res.maxamount);
                    settemplatearray(res.templatearray);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const getlimit = (value) => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            coinname: value,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getbaselimit', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setminamount(res.minamount);
                    setmaxamount(res.maxamount);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateGoogleAuth = (value) => {
        setgoogle_auth(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_error("");
            return true;
        } else {
            setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }


    const validateCode = (value) => {
        setotpmessage(value);
        if (!validator.isEmpty(value)) {
            setotpmessage_error("");
            return true;
        } else {
            setotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const validateCodeTransfer = (value) => {
        settransferotpmessage(value);
        if (!validator.isEmpty(value)) {
            settransferotpmessage_error("");
            return true;
        } else {
            settransferotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }


    const validateAccount = (value) => {
        const myArray = value.split("|");
        setaccount(value);
        setcurrancy(myArray[1]);

        if (!validator.isEmpty(myArray[0])) {
            getlimit(myArray[1]);
            setaccount_error("");
            return true;
        } else {
            setaccount_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }
    }

    const validateSwiftcode = (value) => {
        setswiftcode(value);
        if (!validator.isEmpty(value)) {
            setswiftcode_error("");
            return true;
        } else {
            setswiftcode_error(t('FIAT.SWIFT_PLACE'));
            return false;
        }
    }

    const validateName = (value) => {
        setaccountname(value);
        if (!validator.isEmpty(value)) {
            setaccountname_error("");
            return true;
        } else {
            setaccountname_error(t('FIAT.NAME_PLACE'));
            return false;
        }
    }

    const validateAddress = (value) => {
        setaddress(value);
        if (!validator.isEmpty(value)) {
            setaddress_error("");
            return true;
        } else {
            setaddress_error(t('FIAT.ADDRESS_PLACE'));
            return false;
        }
    }

    const validateCountry = (value) => {
        setcountry(value);
        if (!validator.isEmpty(value)) {
            setcountry_error("");
            return true;
        } else {
            setcountry_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        }
    }

    const validateAba = (value) => {
        setabatitle(value);
        if (!validator.isEmpty(value)) {
            setabatitle_error("");
            return true;
        } else {
            setabatitle_error(t('FIAT.ABA_PLACE'));
            return false;
        }
    }

    const validateLocation = (value) => {
        setlocation(value);
        if (!validator.isEmpty(value)) {
            setlocation_error("");
            return true;
        } else {
            setlocation_error(t('FIAT.LOCATION_PLACE'));
            return false;
        }
    }

    const validateCustomername = (value) => {
        setcustomer_name(value);
        if (!validator.isEmpty(value)) {
            setcustomer_name_error("");
            return true;
        } else {
            setcustomer_name_error(t('FIAT.NAME_PLACE'));
            return false;
        }
    }
    const validateCustomeraddress = (value) => {
        setcustomer_address(value);
        if (!validator.isEmpty(value)) {
            setcustomer_address_error("");
            return true;
        } else {
            setcustomer_address_error(t('FIAT.ADDRESS_PLACE'));
            return false;
        }
    }
    const validateCustomeriban = (value) => {
        setcustomer_iban(value);
        if (!validator.isEmpty(value)) {
            setcustomer_iban_error("");
            return true;
        } else {
            setcustomer_iban_error(t('FIAT.IBAN_PLACE'));
            return false;
        }
    }
    const validateRefmessage = (value) => {
        setrefmessage(value);
        if (!validator.isEmpty(value)) {
            setrefmessage_error("");
            return true;
        } else {
            setrefmessage_error(t('FIAT.REFMESSAGE_PLACE'));
            return false;
        }
    }

    const validateAmount = (value) => {
        setamount(value);
        if (validator.isNumeric(value) && parseFloat(value) > 0) {
            if (parseFloat(maxamount) > 0) {
                if (parseFloat(value) < parseFloat(minamount)) {
                    setamount_error("Minimum Amount is " + minamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(maxamount)) {
                    setamount_error("Maximum Amount is " + maxamount);
                    return false;
                }
            }
            setamount_error("");
            return true;
        } else {
            setamount_error(t('FIAT.AMOUNT_TRANSFER_PLACE'));
            return false;
        }
    }

    const validateCurrancy = (value) => {
        setcurrancy(value);
        if (!validator.isEmpty(value)) {
            setcurrancy_error("");
            return true;
        } else {
            setcurrancy_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        }
    }

    const validateMessage = (value) => {
        setmessage(value);
        if (!validator.isEmpty(value)) {
            setmessage_error("");
            return true;
        } else {
            setmessage_error(t('FIAT.MESSAGE_PLACE'));
            return false;
        }
    }

    const deletetemplate = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }

        axios.get(process.env.REACT_APP_API_URL + 'deletetemplate/' + template_option, { headers: headers })
            .then(function (res) {
                res = res.data;
                setDeleteVerification(false);
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                    handleLoad();
                } else {
                }

            })
            .catch(function (error) {
            });
    }


    const validateTemplate = (value) => {
        settemplate_option(value);
        if (!validator.isEmpty(value)) {
            setdeleteLink(true);
            sethidetemplatediv(true);
            setcheckboxval(false);
            settemplatename("");

            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }

            axios.get(process.env.REACT_APP_API_URL + 'getfiatwithdetails/' + value, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setaccount(res.data[0].account_id_fk + "|" + res.data[0].bank_currency);
                        setswiftcode(res.data[0].switch_code);
                        setaccountname(res.data[0].bank_name);
                        setaddress(res.data[0].address1);
                        setcountry(res.data[0].country_id);
                        setabatitle(res.data[0].label);
                        setlocation(res.data[0].region);
                        setcustomer_name(res.data[0].bank_account_name);
                        setcustomer_address(res.data[0].address2);
                        setcustomer_iban(res.data[0].iban_number);
                        setrefmessage(res.data[0].referance);
                        setamount(res.data[0].amount);
                        setcurrancy(res.data[0].bank_currency);
                        setmessage(res.data[0].message);
                        setotpmessage("");
                        setOTPVerification(false);
                    } else {
                        setaccount("");
                        setswiftcode("");
                        setaccountname("");
                        setaddress("");
                        setcountry("");
                        setabatitle("");
                        setlocation("");
                        setcustomer_name("");
                        setcustomer_address("");
                        setcustomer_iban("");
                        setrefmessage("");
                        setamount("");
                        setcurrancy("");
                        setmessage("");
                        setotpmessage("");
                        setOTPVerification(false);
                        setError(true);
                        setErrorMsg(res.message);
                    }

                })
                .catch(function (error) {
                    setaccount("");
                    setswiftcode("");
                    setaccountname("");
                    setaddress("");
                    setcountry("");
                    setabatitle("");
                    setlocation("");
                    setcustomer_name("");
                    setcustomer_address("");
                    setcustomer_iban("");
                    setrefmessage("");
                    setamount("");
                    setcurrancy("");
                    setmessage("");
                    setotpmessage("");
                    setOTPVerification(false);
                });

            return true;
        } else {
            setdeleteLink(false);
            sethidetemplatediv(false);
            setaccount("");
            setswiftcode("");
            setaccountname("");
            setaddress("");
            setcountry("");
            setabatitle("");
            setlocation("");
            setcustomer_name("");
            setcustomer_address("");
            setcustomer_iban("");
            setrefmessage("");
            setamount("");
            setcurrancy("");
            setmessage("");
            setotpmessage("");
            setOTPVerification(false);
            setdeleteLink(false);
        }
    }

    const validateCheckbox = (value) => {
        setcheckboxval(value);
    }

    const validateTemplateName = (value) => {
        settemplatename(value);
        if (validator.isEmpty(value)) {
            return false;
        } else {
            return true;
        }
    }

    const finalsubmit = () => {
        var accountid = account.split("|");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            account: accountid[0],
            swiftcode: swiftcode,
            accountname: accountname,
            address: address,
            country: country,
            abatitle: abatitle,
            location: location,
            customer_name: customer_name,
            customer_address: customer_address,
            customer_iban: customer_iban,
            refmessage: refmessage,
            amount: amount,
            currancy: currancy,
            message: message,
            otp: otpmessage,
            auth_code: google_auth,
            templatename: templatename,
            checkboxval: checkboxval,
            template_option: template_option,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        setError(false);
        setErrorMsg("");
        axios.post(process.env.REACT_APP_API_URL + 'outgoingwire', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setsuccess_display_cur(res.data.success_display_cur);
                    setsuccess_display_amount(res.data.success_display_amount);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_fromaccount(res.data.success_display_fromaccount);
                    setsuccess_display_fromaccount_cur(res.data.success_display_fromaccount_cur);
                    setsuccess_display_details(res.data.success_display_details);

                    setTransferAccept(true);

                    setaccount("");
                    setswiftcode("");
                    setaccountname("");
                    setaddress("");
                    setcountry("");
                    setabatitle("");
                    setlocation("");
                    setcustomer_name("");
                    setcustomer_address("");
                    setcustomer_iban("");
                    setrefmessage("");
                    setamount("");
                    setcurrancy("");
                    setmessage("");
                    setotpmessage("");
                    settemplate_option("");
                    settemplatename("");
                    setcheckboxval(false);
                    setOTPVerification(false);
                    setGoogleVerification(false);
                    sethidetemplatediv(false);
                    setdeleteLink(false);
                    setTimeout(() => {
                        //history.push('/fiat');

                        handleLoad();
                        // setLoader(false);
                        // history.push({
                        //     pathname: '/fiat',
                        //     openSnackbar: true,
                        //     openSnackbarmsg: res.message
                        // });

                        //handleLoad();
                    }, 1000);
                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_cur(res.data.error_display_cur);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    setOTPVerification(false);
                    setLoader(false);
                    sethidetemplatediv(false);
                    setdeleteLink(false);
                    setotpmessage("");
                }

            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setdeleteLink(false);
                sethidetemplatediv(false);
                setotpmessage("");
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handlesubmit = () => {

        if (validateAccount(account) === false) {
            setaccount_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else if (validateSwiftcode(swiftcode) === false) {
            setaccount_error("");
            setswiftcode_error(t('FIAT.SWIFT_PLACE'));
            return false;
        } else if (validateName(accountname) === false) {
            setswiftcode_error("");
            setaccountname_error(t('FIAT.NAME_PLACE'));
            return false;
        } else if (validateAddress(address) === false) {
            setaccountname_error("");
            setaddress_error(t('FIAT.ADDRESS_PLACE'));
            return false;
        } else if (validateCountry(country) === false) {
            setaddress_error("");
            setcountry_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        } else if (validateAba(abatitle) === false) {
            setcountry_error("");
            setabatitle_error(t('FIAT.ABA_PLACE'));
            return false;
        } else if (validateLocation(location) === false) {
            setabatitle_error("");
            setlocation_error(t('FIAT.LOCATION_PLACE'));
            return false;
        } else if (validateCustomername(customer_name) === false) {
            setlocation_error("");
            setcustomer_name_error(t('FIAT.NAME_PLACE'));
            return false;
        } else if (validateCustomeraddress(customer_address) === false) {
            setcustomer_name_error("");
            setcustomer_address_error(t('FIAT.ADDRESS_PLACE'));
            return false;
        } else if (validateCustomeriban(customer_iban) === false) {
            setcustomer_address_error("");
            setcustomer_iban_error(t('FIAT.IBAN_PLACE'));
            return false;
        } else if (validateRefmessage(refmessage) === false) {
            setcustomer_iban_error("");
            setrefmessage_error(t('FIAT.REFMESSAGE_PLACE'));
            return false;
        } else if (validateAmount(amount) === false) {
            setrefmessage_error("");
            setamount_error(t('FIAT.AMOUNT_TRANSFER_PLACE'));
            return false;
        } else if (validateCurrancy(currancy) === false) {
            setamount_error("");
            setcurrancy_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        } else if (validateMessage(message) === false) {
            setcurrancy_error("");
            setmessage_error(t('FIAT.MESSAGE_PLACE'));
            return false;
        } else {

            if (checkboxval === true) {
                if (validateTemplateName(templatename) === false) {
                    settemplatename_error("Enter Beneficiary Name");
                    return false;
                }
            }
            setmessage_error("");
            settemplatename_error("");

            if (transferotp === "Google Authentication") {
                setGoogleVerification(true);
            } else if (transferotp === "SMS OTP Authentication") {

                console.log(transferotp);
                var jsonpar = {
                    user_id: window.sessionStorage.getItem("user_id")
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                    'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                }
                document.getElementById("submit").disabled = true;
                document.getElementById("submit").textContent = t('PROCESSING');
                axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
                    .then(function (res) {
                        res = res.data;
                        if (res.code === "200") {
                            setotpmessage("");
                            setOTPVerification(true);
                            setSuccess(true);
                            setSuccessMsg(res.message);
                            setTimeout(() => {
                                setSuccess(false);
                            }, 6000);
                        } else {
                            setError(true);
                            setErrorMsg(res.message);
                            setTimeout(() => {
                                setError(false);
                            }, 6000);
                        }

                    })
                    .catch(function (error) {
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    });
            } else {
                finalsubmit();
            }
        }
    }

    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        document.getElementById("submit").disabled = true;
        document.getElementById("submit").textContent = t('PROCESSING');
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    return (
        <>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header page-title-space">
                        <h2 className="text-black font-w600 mb-0">{t('FIAT.OUTGOING_TITLE')}</h2>
                        <div className="float-right">
                            <Link to="/fiat" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t('LINK.BACK')}</Link>
                            {templatearray.length > 0 ?
                                <div className="form-group position-relative float-left" style={{ marginLeft: '10px',marginRight:"15px" }}>
                                    <select style={{ minWidth: '200px', paddingRight: '35px' }} className="form-control webkit-none" onChange={(e) => validateTemplate(e.target.value)} value={template_option}>
                                        <option value="" selected>Choose Beneficiary</option>
                                        {templatearray && templatearray.map((row) => (
                                            <option value={row.fiat_with_trans_id} key={row.fiat_with_trans_id}>{row.temp_name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                : ""}
                            {deleteLink ?
                                <Link to="#" onClick={(e) => setDeleteVerification(true)} className="back-to-page link-template-select" style={{marginRight:"30px"}}>Delete</Link>
                                : ""}
                        </div>

                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
                    {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
                    <div className="card-body">
                        <div className="row position-relative z-111">
                            <div className="col-md-12">
                                <div className="form-group position-relative">
                                    <h4 className="card-title mb-3">{t('FIAT.SELECT_DEPOIST_ACCOUNT')}</h4>
                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateAccount(e.target.value)} value={account}>
                                        {accountarray.length > 0 ?
                                            <>
                                                <option value="">{t('FIAT.SELECT_DEPOIST_ACCOUNT')}</option>
                                                <option value={accountarray[0].account_id_pk + "|USD"}>{t('KYC.MYACCOUNT')} : {accountarray[0].account_number} ( {accountarray[0].available_balance} $ )</option>
                                                <option value={accountarray[1].account_id_pk + "|EUR"}>{t('KYC.MYACCOUNT')} : {accountarray[1].account_number} ( {accountarray[1].available_balance} € )</option>
                                            </>
                                            :
                                            <option value="">{t('FIAT.ACCOUNT_NOTFOUND')}</option>
                                        }
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                                {account_error !== "" ? <label className='text-red'>{account_error}</label> : ""}
                            </div>

                        </div>
                        <div className="col-xl-12 p-0">
                            <hr />
                            <div className="mt-4 row">
                                <div className="col-md-12">
                                    <h4 className="card-title mb-4">{t('FIAT.BENIFICIARY_TITLE')}</h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.SWIFT_TITLE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateSwiftcode(e.target.value)} value={swiftcode} placeholder={t('FIAT.SWIFT_PLACE')} />
                                        {swiftcode_error !== "" ? <label className='text-red'>{swiftcode_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.BANKNAME')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateName(e.target.value)} value={accountname} placeholder={t('FIAT.NAME_PLACE')} />
                                        {accountname_error !== "" ? <label className='text-red'>{accountname_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.ADDRESS')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateAddress(e.target.value)} value={address} placeholder={t('FIAT.ADDRESS_PLACE')} />
                                        {address_error !== "" ? <label className='text-red'>{address_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.COUNTRY')}</label>
                                        <div className="form-group position-relative mb-0">
                                            <select className="form-control webkit-none" value={country} onChange={(e) => validateCountry(e.target.value)}>
                                                <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                                {allcountry && allcountry.map((row) => (
                                                    <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                                ))}
                                            </select>
                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                        </div>
                                        {country_error !== "" ? <label className='text-red'>{country_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.ABA_TITLE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateAba(e.target.value)} value={abatitle} placeholder={t('FIAT.ABA_PLACE')} />
                                        {abatitle_error !== "" ? <label className='text-red'>{abatitle_error}</label> : ""} </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.LOCATION')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateLocation(e.target.value)} value={location} placeholder={t('FIAT.LOCATION_PLACE')} />
                                        {location_error !== "" ? <label className='text-red'>{location_error}</label> : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 row">
                                <div className="col-md-12">
                                    <h4 className="card-title mb-4">{t('FIAT.BENCUS_TITLE')}</h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.NAME_TITLE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateCustomername(e.target.value)} value={customer_name} placeholder={t('FIAT.NAME_PLACE')} />
                                        {customer_name_error !== "" ? <label className='text-red'>{customer_name_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.ADDRESS')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateCustomeraddress(e.target.value)} value={customer_address} placeholder={t('FIAT.ADDRESS_PLACE')} />
                                        {customer_address_error !== "" ? <label className='text-red'>{customer_address_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.IBAN_TITLE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateCustomeriban(e.target.value)} value={customer_iban} placeholder={t('FIAT.IBAN_PLACE')} />
                                        {customer_iban_error !== "" ? <label className='text-red'>{customer_iban_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.REFMESSAGE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateRefmessage(e.target.value)} value={refmessage} placeholder={t('FIAT.REFMESSAGE_PLACE')} />
                                        {refmessage_error !== "" ? <label className='text-red'>{refmessage_error}</label> : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 row">
                                <div className="col-md-12">
                                    <h4 className="card-title mb-4">{t('FIAT.TRANSFER_DETAILS')}</h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.AMOUNT_TO_TRANSFER')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateAmount(e.target.value)} value={amount} placeholder={t('FIAT.AMOUNT_TRANSFER_PLACE')} />
                                        {amount_error !== "" ? <label className='text-red'>{amount_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.CURRENCY_TITLE')}</label>
                                        <select className="form-control webkit-none" id="sel1" onChange={(e) => validateCurrancy(e.target.value)} value={currancy} disabled>
                                            <option value="USD">USD</option>
                                            <option value="EUR">EUR</option>
                                        </select>
                                        {currancy_error !== "" ? <label className='text-red'>{currancy_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('FIAT.MESSAGE_TITLE')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateMessage(e.target.value)} value={message} placeholder={t('FIAT.MESSAGE_PLACE')} />
                                        {message_error !== "" ? <label className='text-red'>{message_error}</label> : ""}
                                    </div>
                                </div>
                                {hidetemplatediv === false ?
                                    <>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group position-relative csmt-cehckbox-new">
                                                <label className="mb-2"></label>
                                                <input type="checkbox" onChange={(e) => validateCheckbox(e.target.checked)} checked={checkboxval} value={checkboxval} /> <span className="lable-checkbox">Save beneficiary</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="Beneficiary name" onChange={(e) => validateTemplateName(e.target.value)} value={templatename} />
                                            {templatename_error !== "" ? <label className='text-red'>{templatename_error}</label> : ""}
                                        </div>
                                    </>
                                    : ""}

                                {parseInt(minamount) > 0 ?
                                    <div className="col-md-12 mt-5">
                                        <label className="mb-2 minmaxclass">Min Amount = {minamount} </label>
                                        <label className="mb-2 ml-3 minmaxclass">Max Amount = {maxamount} </label>
                                    </div>
                                    : ""}
                                <div className="col-md-12 mt-4">
                                    <button type="button" id="submit" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('BUTTONS.CONTINUE')}</button>
                                    <Link to="/fiat" className="btn btn-outline-light ml-3 btn-rounded">{t('BUTTONS.CANCEL')}</Link>
                                </div>
                                <Modal className="modal fade" show={OTPVerification}>
                                    <div className="modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                                <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                                            <input minLength={6} maxLength={6} type="text" onChange={(e) => validateCode(e.target.value)} value={otpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                                            <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                                        </div>
                                                        {otpmessage_error !== "" ? <label className='text-red'>{otpmessage_error}</label> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                                <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal className="modal fade" show={DeleteVerification}>
                                    <div className="modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Delete Template</h3>
                                                <button type="button" className="close" onClick={() => setDeleteVerification(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        Are you sure you want to delete this template ?
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-light btn-rounded light" onClick={() => setDeleteVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                                <button type="button" onClick={deletetemplate} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal className="modal fade" show={GoogleVerification}>
                                    <div className="modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                                <button type="button" className="close" onClick={() => setGoogleVerification(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="mb-2 ">{t("FIAT.OTP_CODE_TITLE")}</label>
                                                            <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuth(e.target.value)} value={google_auth} placeholder={t("FIAT.OTP_CODE_PLACE")} />
                                                            {google_auth_error !== "" ? <label className='text-red'>{google_auth_error}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button className="btn btn-light btn-rounded light" onClick={() => setGoogleVerification(false)}>{t("BUTTONS.CANCEL")}</button>
                                                <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>


                                <Modal className="modal fade" show={TransferAccept}>
                                    <div className="modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Fiat Account Outgoing Wire Transfer</h3>
                                                <button type="button" className="close" onClick={() => setTransferAccept(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="check">
                                                    <i class="fa fa-solid fa-check"></i>
                                                    <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Amount : <span className="text-black">{success_display_amount}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Transfer from {success_display_fromaccount_cur} account : <span className="text-black">{success_display_fromaccount}</span></p>
                                                    </div>
                                                </div>


                                                <div className="row mt-1">
                                                    <div className="col-md-12 mb-3">
                                                        <p className="fs-16 mb-1">Details</p>
                                                        <span className="text-black">{success_display_details}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mb-2">
                                                    <ul className="btm-link">
                                                        <li>
                                                            <Link to="#" onClick={() => setTransferAccept(false)}> New Withdrawal</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dashboard"> Dashboard</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/fiat-transactions"> Transaction List</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal className="modal fade" show={TransferDecline}>
                                    <div className="modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Fiat Account Outgoing Wire Transfer</h3>
                                                <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="check">
                                                    <i class="fa fa-solid fa-times"></i>
                                                    <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-md-12 mb-3">
                                                        <p className="fs-16 mb-1">Details</p>
                                                        <span className="text-black">{error_display_details}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mb-2">
                                                    <ul className="btm-link">
                                                        <li>
                                                            <Link to="#" onClick={() => setTransferDecline(false)}> Retry</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dashboard"> Dashboard</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/fiat-transactions"> Transaction List</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )

}
export default Fiat_Outgoing_Transfers;