import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { DatePicker } from "@y0c/react-datepicker";
import moment from 'moment';
import card1 from '../../../images/card/v-card.png';
import Loader from '../../pages/Loader';
import axios from 'axios';
import jsPDF from 'jspdf';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';


export const MyCardTransactions = () => {
    //const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [from_date, setfrom_date] = useState("");
    const [to_date, setto_date] = useState("");
    const [cardsval, setcardsval] = useState("");
    const [cardarray, setcardarray] = useState("");
    const [original_array, setoriginal_array] = useState([]);
    const [temp_array, settemp_array] = useState([]);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Card Transactions";

        var columns = [
            { title: 'Date', field: 'Date' },
            { title: 'Card Type', field: 'CardType' },
            { title: 'Account Number', field: 'AccountNumber' },
            { title: 'Card Number', field: 'CardNumber' },
            { title: 'Debit / Credit', field: 'DebitCredit' },
            { title: 'Amount', field: 'Amount' },
            { title: 'Status', field: 'Status', align: 'center' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = original_array.map(rowData =>
            [rowData["Date"],
            rowData["CardType"],
            rowData["AccountNumber"],
            rowData["CardNumber"],
            rowData["DebitCredit"],
            rowData["Amount"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const handleLoad = () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getuserCardsTransactions', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    settemp_array(res.data);
                    setcardarray(res.allcards);
                    if (res.data.length > 0) {
                        var TempArrfiat = [];
                        res.data.forEach(element => {
                            var cur = "$";
                            var status = <Link className="btn btn-outline-warning btn-rounded">Pending</Link>;
                            if (element.issue_status === "Completed") {
                                status = <Link to="#" className="btn btn-outline-success btn-rounded">Completed</Link>;
                            }

                            if (element.card_currancy === "EUR") {
                                cur = "€";
                            }
                            var swaptemp = {
                                Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                                CardType: element.cardtypename,
                                AccountNumber: element.account_number,
                                CardNumber: "**** **** **** " + String(element.card_number).slice("-4"),
                                DebitCredit: element.cardtra_type,
                                Amount: cur + element.amount,
                                Status: status,
                                StatusText: element.issue_status,
                            };
                            TempArrfiat.push(swaptemp);

                        });
                        setoriginal_array(TempArrfiat);
                    }
                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateFromdate = (value) => {
        setfrom_date(value);
        filter(value, "", "");
    }

    const validateTodate = (value) => {
        setto_date(value);
        filter("", value, "");
    }

    const validateCards = (value) => {
        setcardsval(value);
        filter("", "", value);
    }

    const filter = (fromdate = "", todate = "", cardid = "") => {
        var tempfrom = from_date;
        var temptodate = to_date;
        var tempcardid = cardsval;

        if (fromdate !== "") {
            tempfrom = fromdate;
        }

        if (todate !== "") {
            temptodate = todate;
        }
        if (cardid !== "") {
            if(parseInt(cardid) > 0){
            tempcardid = cardid;
            }else{
                tempcardid = "";
            }
        }

        

        var TempArr = [];
        temp_array.forEach(element => {
            var cur = "$";
            var status = <Link className="btn btn-outline-warning btn-rounded">Pending</Link>;
            if (element.issue_status === "Completed") {
                status = <Link to="#" className="btn btn-outline-success btn-rounded">Completed</Link>;
            }

            if (element.card_currancy === "EUR") {
                cur = "€";
            }
            var temp = {
                Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                CardType: element.cardtypename,
                AccountNumber: element.account_number,
                CardNumber: "**** **** **** " + String(element.card_number).slice("-4"),
                DebitCredit: element.cardtra_type,
                Amount: cur + element.amount,
                Status: status
            };

            
            if (tempfrom === "" && temptodate === "" && tempcardid === "") {
                TempArr.push(temp);
            }
            else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && temptodate === "" && tempcardid === "") {
                TempArr.push(temp);
            }
            else if (tempfrom === "" && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD')) && tempcardid === "") {
                TempArr.push(temp);
            }
            else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD')) && tempcardid === "") {
                TempArr.push(temp);
            }
            else if ((tempfrom !== "" && moment(element.created_datetime).format('YYYY-MM-DD') >= moment(tempfrom).format('YYYY-MM-DD')) && (temptodate !== "" && moment(element.created_datetime).format('YYYY-MM-DD') <= moment(temptodate).format('YYYY-MM-DD')) && tempcardid === element.card_id_fk) {
                TempArr.push(temp);
            }
            else if (tempfrom === "" && temptodate === "" && tempcardid === element.card_id_fk) {
                TempArr.push(temp);
            }
        });
        setoriginal_array(TempArr);

    }

    return (
        <>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Transactions</h2>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card height-cstm-chart">
                            <div className="card-body bdr-btm-card">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="mb-2 ">
                                                <span>From Date</span>
                                            </label>
                                            <div className="col-xs-12">
                                                <input type="date" onChange={(e) => validateFromdate(e.target.value)} value={from_date} className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="mb-2 ">
                                                <span>To Date</span>
                                            </label>
                                            <div className="col-xs-12">
                                                <input type="date" onChange={(e) => validateTodate(e.target.value)} value={to_date} className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group position-relative">
                                            <label className="mb-2 ">
                                                <span>Select Card</span>
                                            </label>
                                            <select className="form-control webkit-none" value={cardsval} onChange={(event) => validateCards(event.target.value)}>
                                                <option value="0">Select Card</option>
                                                {cardarray && cardarray.map((row) => (
                                                    <option value={row.card_id} key={row.card_id}>{row.card_number} ( {row.cardtypename} )</option>
                                                ))}
                                            </select>
                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="card-body table-action-center">
                                <div className="table-responsive">
                                    {original_array.length > 0 ?
                                        <MaterialTable
                                            title="Card Transactions"
                                            columns={[
                                                { title: 'Date', field: 'Date' },
                                                { title: 'Card Type', field: 'CardType' },
                                                { title: 'Account Number', field: 'AccountNumber' },
                                                { title: 'Card Number', field: 'CardNumber' },
                                                { title: 'Debit / Credit', field: 'DebitCredit' },
                                                { title: 'Amount', field: 'Amount' },
                                                { title: 'Status', field: 'Status', align: 'center' },
                                            ]}
                                            data={original_array}
                                            options={{
                                                exportMenu: [
                                                    { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                    { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                                ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                            }}
                                        />
                                        : <div className="d-sm-flex d-block border-0 pb-0">
                                            <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                                <h4 className="fs-20 text-black mb-1 nofoundTitle">Card Transactions Overview</h4>
                                                <span className="fs-12 nofoundText">No Data Found</span>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )

}
export default MyCardTransactions;