import { useState, useEffect } from "react";
import Snackbars from './Snackbars';

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  let timer;
  let padding;
  const [error, setError] = useState(false);
  const [error_msg, setErrorMsg] = useState("");

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);



  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      setError(true);
      setErrorMsg(window.sessionStorage.getItem("message"));
      setTimeout(() => {
        setError(false);
      }, 6000);

      var autologouttime = parseInt(window.sessionStorage.getItem("autologouttime")) - parseInt(window.sessionStorage.getItem("paddingtime"));
      setTimeout(() => {
        logoutAction();
      }, parseInt(autologouttime));
    }, parseInt(window.sessionStorage.getItem("paddingtime")));
  };

  const logoutAction = () => {
    if (window.sessionStorage.getItem("autologout") === "Yes") {
      setError(true);
      setErrorMsg(window.sessionStorage.getItem("aftermsg"));
      setTimeout(() => {
        setError(false);
      }, 6000);
      if (window.sessionStorage.getItem("demouser") === "Yes") {
        window.sessionStorage.removeItem("demouser");
        localStorage.clear();
        sessionStorage.clear();
        window.location.pathname = "/demologin";
      } else {
        window.sessionStorage.removeItem("demouser");
        localStorage.clear();
        sessionStorage.clear();
        window.location.pathname = "/login";
      }
    }
  };
  return (
    <div>
      {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
      {children}
    </div>
  )
};

export default AppLogout;