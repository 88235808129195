import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Loader from '../../../jsx/pages/Loader';

export const Terms = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [desc, setdesc] = useState("");
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + '../settings/getpages/1', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setdesc(res.data[0].page_description);
                }
                setTimeout(() => {
                    setLoader(false);
                }, 500);
            })
            .catch(function (error) {
                setLoader(false);
            });
    }
    return (

        <div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header page-title-space">
                        <h2 className="text-black font-w600 mb-0">{t("LABEL.TERMS_LABEL")}</h2>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card widget-stat widget-stat-custom card">
                    <div className="card-body">
                        <div className="terms-body" id="terms">
                            <div dangerouslySetInnerHTML={{ __html: desc }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Terms;