import React, { Fragment, useState, useEffect } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import axios from 'axios';

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
   const [toggle, setToggle] = useState("");
   const [Kyc, setKyc] = useState("Pending");
   const onClick = (name) => setToggle(toggle === name ? "" : name);

   useEffect(() => {
      handleLoad();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleLoad = () => {
      const headers = {
         'Content-Type': 'application/json',
         'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
         'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
      }
      axios.get(process.env.REACT_APP_API_URL + 'checkkyc/' + window.sessionStorage.getItem("user_id"), { headers: headers })
         .then(function (res) {
            res = res.data;
            if (res.data.length > 0) {
               setKyc(res.data[0].kyc_status);
            }
         })
         .catch(function (error) {

         });
   }

   return (
      <Fragment>
         <NavHader />
         <SideBar onClick={() => onClick2()} onClick3={() => onClick3()} kyc={Kyc} />
         <Header
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            onBox={() => onClick("box")}
            onClick={() => ClickToAddEvent()}
         />

      </Fragment>
   );
};

export default JobieNav;
