/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, withRouter } from "react-router-dom";
import $ from 'jquery';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {

  /// Open menu
  toggleFunc2() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      $(".nav-control").trigger("click");
    }

  }

  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }

  state = {
    loveEmoji: false,
  };

  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let
      dashBoard = [
        "dashboard",
        "my-wallet",
        "invoices",
        "cards-center",
        "transactions",
        "transactions-details",
        "task",
      ],

      accounts = [
        "fiat",
        "crypto",
        "my-cards",
        "top-up-card",
        "my-cards-transactions",
        "transfer-account",
        "request-card",
        "card-settings",
        "fiat-incoming-transfers",
        "fiat-outgoing-transfers",
        "fiat-internal-transfers"
      ],

      accountsSub = [
        "my-cards",
        "top-up-card",
        "my-cards-transactions",
        "transfer-account",
        "request-card",
        "card-settings",
        "fiat-incoming-transfers",
        "fiat-outgoing-transfers",
        "fiat-internal-transfers"
      ],

      cryptoexchanges = [
        "crypto-exchanges",
      ],

      // businesscard = [
      //   "business-card",
      //   'transactions',
      //   'create-card',
      // ],

      transaction = [
        "fiat-transactions",
        "crypto-transactions",
        "wallets-deposit-transaction",
        "wallets-withdrawal-transaction"
      ],

      usersactivitylogs = [
        "users-activity-logs",
      ],

      management = [
        "news",
        "faq"
      ],

      verifyyouraccount = [
        "verify-your-account",
      ]


    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">

            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "" ? "mm-active" : ""}`} to="/dashboard" onClick={(e) => this.toggleFunc2()}>
                <i className="flaticon-381-home-2"></i>
                <span className="nav-text">Dashboard </span>
              </Link>
            </li>

            <li className={`${accounts.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user-7"></i>
                <span className="nav-text">Accounts</span>
              </Link>
              <ul className={`${accounts.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={`${path === "fiat" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/fiat" : "/verify-your-account"} onClick={() => this.toggleFunc2()}>Fiat Accounts</Link>
                </li>
                <li>
                  <Link className={`${path === "crypto" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/crypto" : "/verify-your-account"} onClick={() => this.toggleFunc2()}>Crypto Accounts</Link>
                </li>

                <li className={`${accountsSub.includes(path) ? "mm-active" : ""}`}>
                  <Link className={`${path === "" ? "mm-active has-arrow ai-icon" : " has-arrow ai-icon"}`} to="" onClick={() => this.toggleFunc2()}>Card Accounts</Link>
                  <ul className={`${accountsSub.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                    <li>
                      <Link className={`${path === "my-cards" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/my-cards" : "/verify-your-account"} onClick={() => this.toggleFunc2()} >My cards</Link>
                    </li>

                    <li>
                      <Link className={`${path === "top-up-card" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/top-up-card" : "/verify-your-account"} onClick={() => this.toggleFunc2()} >Top up Card</Link>
                    </li>

                    <li>
                      <Link className={`${path === "my-cards-transactions" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/my-cards-transactions" : "/verify-your-account"} onClick={() => this.toggleFunc2()} >Transactions</Link>
                    </li>
                    <li>
                      <Link className={`${path === "transfer-account" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/transfer-account" : "/verify-your-account"} onClick={() => this.toggleFunc2()} >Transfer account</Link>
                    </li>
                    <li>
                      <Link className={`${path === "request-card" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/request-card" : "/verify-your-account"} onClick={() => this.toggleFunc2()} >Request card</Link>
                    </li>
                  </ul>
                </li>

              </ul>
            </li>

            <li className={`${cryptoexchanges.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "crypto-exchanges" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/crypto-exchanges" : "/verify-your-account"} onClick={() => this.toggleFunc2()}>
                <i className="flaticon-381-networking-1"></i>
                <span className="nav-text">Crypto Exchange</span>
              </Link>
            </li>

            {/* <li className={`${businesscard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-id-card"></i>
                <span className="nav-text">Business Card</span>
              </Link>
               <ul className={`${businesscard.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={`${path === "business-card" ? "mm-active" : ""}`} to="/business-card" onClick={() => this.toggleFunc2()}>Business Cards</Link>
                </li>
                <li>
                  <Link className={`${path === "transactions" ? "mm-active" : ""}`} to="/transactions" onClick={() => this.toggleFunc2()}>Transactions</Link>
                </li>
                <li>
                  <Link className={`${path === "create-card" ? "mm-active" : ""}`} to="/create-card" onClick={() => this.toggleFunc2()}>Create Card</Link>
                </li>

              </ul>
            </li> */}

            <li className={`${transaction.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-list"></i>
                <span className="nav-text">Transactions</span>
              </Link>
              <ul className={`${transaction.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={`${path === "fiat-transactions" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/fiat-transactions" : "/verify-your-account"} onClick={() => this.toggleFunc2()}>Fiat</Link >
                </li>
                <li>
                  <Link className={`${path === "crypto-transactions" ? "mm-active" : ""}`} to={this.props.kyc === "Approved" ? "/crypto-transactions" : "/verify-your-account"} onClick={() => this.toggleFunc2()}>Crypto</Link>
                </li>
              </ul>
            </li>

            <li className={`${usersactivitylogs.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "users-activity-logs" ? "mm-active" : ""}`} to="/users-activity-logs" onClick={() => this.toggleFunc2()}>
                <i className="flaticon-381-note"></i>
                <span className="nav-text">Activity Logs</span>
              </Link>
            </li>

            <li className={`${management.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "news" ? "mm-active" : ""}`} to="/news" onClick={() => this.toggleFunc2()}>
                <i className="flaticon-381-news"></i>
                <span className="nav-text">News</span>
              </Link>
            </li>

            <li className={`${verifyyouraccount.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "verify-your-account" ? "mm-active" : ""}`} to="/verify-your-account" onClick={() => this.toggleFunc2()}>
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Verify Your Account</span>
              </Link>
            </li>

          </MM>
          <div className="copyright">
            <p> <strong>{process.env.REACT_APP_NAME} </strong> © {new Date().getFullYear()} All Rights Reserved </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default withRouter(SideBar);
