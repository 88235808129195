import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';

export const TransferAccount = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);
    const [cardarray, setcardarray] = useState([]);
    const [accountarray, setaccountarray] = useState([]);

    const [select_card, setselect_card] = useState("");
    const [select_card_error, setselect_card_error] = useState("");
    const [select_account, setselect_account] = useState("");
    const [amount, setamount] = useState("");
    const [amount_error, setamount_error] = useState("");
    const [message, setmessage] = useState("");
    const [message_error, setmessage_error] = useState("");

    const [account_balance, setaccount_balance] = useState(0);
    const [card_balance, setcard_balance] = useState(0);
    const [compare_card_balance, setcompare_card_balance] = useState(0);
    const [accountval, setaccountval] = useState(0);

    const [OTPVerification, setOTPVerification] = useState(false);
    const [otpmessage, setotpmessage] = useState("");
    const [otpmessage_error, setotpmessage_error] = useState("");
    const [transferotp, settransferotp] = useState("");

    const [GoogleAuthenticator, setGoogleAuthenticator] = useState(false);
    const [authcode, setauthcode] = useState("");
    const [authcode_error, setauthcode_error] = useState("");

    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);
    const [success_display_from_card, setsuccess_display_from_card] = useState("");
    const [success_display_to_card, setsuccess_display_to_card] = useState("");
    const [success_display_from_account, setsuccess_display_from_account] = useState("");
    const [success_display_to_account, setsuccess_display_to_account] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");
    const [currancy, setcurrancy] = useState("");

    const [minamount, setminamount] = useState("");
    const [maxamount, setmaxamount] = useState("");


    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getusercards_topup', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcardarray(res.data);
                    settransferotp(res.accounttopup);
                    setminamount(res.minibuy);
                    setmaxamount(res.maxbuy);
                    //setaccountarray(res.accountarray);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const findcards = (values) => {
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            card_id: values
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getuseraccountByCards', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var symbole = '$';
                    if (res.data[0].account_currency_type === "EUR") {
                        var symbole = "€";
                    }
                    setselect_account(res.data[0].account_id_pk);
                    setaccount_balance(symbole + res.data[0].available_balance);
                    setaccountval(res.data[0].account_number);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateCode = (value) => {
        setotpmessage(value);
        if (!validator.isEmpty(value)) {
            setotpmessage_error("");
            return true;
        } else {
            setotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        document.getElementById("submit").disabled = true;
        document.getElementById("submit").textContent = t('PROCESSING');
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handlesubmit = () => {
        if (validateCard(select_card) === false) {
            setselect_card_error("Select Card");
            return false;
        } else if (validateAmount(amount) === false) {
            setselect_card_error("");
            setamount_error("Enter Proper Amount");
            return false;
        } else if (validateMessage(message) === false) {
            setamount_error("");
            setmessage_error("Enter Message");
            return false;
        } else {
            setmessage_error("");
            setauthcode("");
            if (transferotp === "Google Authentication") {
                setGoogleAuthenticator(true);
            } else if (transferotp === "SMS OTP Authentication") {
                var jsonpar = {
                    user_id: window.sessionStorage.getItem("user_id")
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                    'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                }
                setLoader(true);
                axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
                    .then(function (res) {
                        res = res.data;
                        if (res.code === "200") {
                            setotpmessage("");
                            setOTPVerification(true);
                            setLoader(false);
                            setSuccess(true);
                            setSuccessMsg(res.message);
                            setTimeout(() => {
                                setSuccess(false);
                            }, 6000);
                        } else {
                            setLoader(false);
                            setError(true);
                            setErrorMsg(res.message);
                            setTimeout(() => {
                                setError(false);
                            }, 6000);
                        }

                    })
                    .catch(function (error) {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    });
            } else {
                finalsubmit();
            }
        }

    }


    const getlimit = (value) => {
        console.log(value);
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            coinname: value,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getbaselimit', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setminamount(res.minaccounttopup);
                    setmaxamount(res.maxaccounttopup);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const finalsubmit = () => {
        setauthcode_error("");
        setGoogleAuthenticator(false);
        setOTPVerification(false);
        var jsonpar = {
            userid: window.sessionStorage.getItem("user_id"),
            select_account: select_account,
            select_card: select_card,
            amount: amount,
            message: message,
            authcode: authcode,
            otp: otpmessage,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'accounttopup', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setsuccess_display_from_card(res.data.success_display_from_card);
                    setsuccess_display_to_card(res.data.success_display_to_card);
                    setsuccess_display_from_account(res.data.success_display_from_account);
                    setsuccess_display_to_account(res.data.success_display_to_account);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_details(res.data.success_display_details);
                    setcurrancy(res.data.currancy);
                    setTransferAccept(true);

                    setselect_account("");
                    setselect_card("");
                    setamount("");
                    setmessage("");
                    setauthcode("");
                    setaccountval("");
                    setaccount_balance(0);
                    setcard_balance(0);
                    setcompare_card_balance(0);
                    // setSuccess(true);
                    // setSuccessMsg(res.message);
                    setLoader(false);
                    handleLoad();
                    // setTimeout(() => {
                    //     setSuccess(false);
                    // }, 6000);

                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    // setError(true);
                    // setErrorMsg(res.message);
                    setLoader(false);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 6000);
                }
            })
            .catch(function (error) {
                setError(true);
                setErrorMsg("Oops! Something went wrong");
                setLoader(false);
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateAuthcode = (value) => {
        setauthcode(value);
        if (!validator.isEmpty(value)) {
            setauthcode_error("");
            return true;
        } else {
            setauthcode_error("Enter 6 digit google authetication code");
            return false;
        }
    }

    const validateCard = (value) => {
        setselect_card(value);
        if (!validator.isEmpty(value)) {
            setselect_card_error("");
            var symbole = "$";
            for (var i = 0; i < cardarray.length; i++) {
                if (cardarray[i].card_id === value) {
                    if (cardarray[i].card_currancy === "EUR") {
                        symbole = "€";
                        getlimit(cardarray[i].card_currancy);
                    } else {
                        getlimit(cardarray[i].card_currancy);
                    }
                    setcard_balance(symbole + cardarray[i].card_balance);
                    setcompare_card_balance(cardarray[i].card_balance);
                }
            }
            findcards(value);
            return true;
        } else {
            setselect_card_error("Select your card");
            return false;
        }
    }

    const validateAmount = (value) => {
        setamount(value);
        if (!validator.isNumeric(value) && parseFloat(value) > 0) {
            setamount_error("Enter amount");
            return false;
        } else if (parseFloat(value) > parseFloat(compare_card_balance)) {
            setamount_error("Not enough balance");
            return false;
        } else {
            if (parseFloat(maxamount) > 0) {
                if (parseFloat(value) < parseFloat(minamount)) {
                    setamount_error("Minimum Amount is " + minamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(maxamount)) {
                    setamount_error("Maximum Amount is " + maxamount);
                    return false;
                }
            }
            setamount_error("");
            return true;
        }
    }

    const validateMessage = (value) => {
        setmessage(value);
        if (!validator.isEmpty(value)) {
            setmessage_error("");
            return true;
        } else {
            setmessage_error("Enter Casual");
            return false;
        }
    }
    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <div className="card">
                <div className="page-title-space">
                    <h2 className="text-black font-w600 mb-0 fullWidth">Transfer Account</h2>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card card-form">
                    <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>Paying From</label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => validateCard(e.target.value)} value={select_card}>
                                            <option value="">Select Card</option>
                                            {cardarray && cardarray.map((row) => (
                                                <option value={row.card_id} key={row.card_id}>{row.card_number} ( {row.cardtypename} )</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {select_card_error !== "" ? <label className='text-red'>{select_card_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className="mb-2">Account</label>
                                    <input type="text" className="form-control" value={accountval} readOnly />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-2 mb-2 mt-2">
                            <div className="col-md-9 col-12">
                                <h5 className="f-w-500">Prepaid Card Balance<span className="pull-end"> :</span>
                                </h5>
                            </div>
                            <div className="col-md-3 col-12 text-right text-left-mobile"><span>{card_balance}</span>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-9 col-12">
                                <h5 className="f-w-500">Account Balance<span className="pull-end"> :</span>
                                </h5>
                            </div>
                            <div className="col-md-3 col-12 text-right text-left-mobile"><span>{account_balance}</span>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="mb-2">Amount</label>
                                            <input type="text" className="form-control" placeholder="Please Enter Amount" onChange={(e) => validateAmount(e.target.value)} value={amount} />
                                            {amount_error !== "" ? <label className='text-red'>{amount_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="mb-2">Causal</label>
                                            <input type="text" className="form-control text-black" onChange={(e) => validateMessage(e.target.value)} value={message} />
                                            {message_error !== "" ? <label className='text-red'>{message_error}</label> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {parseInt(minamount) > 0 ?
                                <div className="col-md-12 mt-4">
                                    <label className="mb-2 mr-3 minmaxclass">Min Amount = {minamount} </label>
                                    <label className="mb-2 minmaxclass">Max Amount = {maxamount} </label>
                                </div>
                                : ""}

                            <div className="col-md-12 mt-4">
                                <Link to="#" onClick={handlesubmit} className="btn btn-primary btn-rounded">Continue</Link>
                                <Link to="/my-cards" className="btn btn-outline-light ml-3 btn-rounded">Cancel</Link>
                            </div>

                            <Modal className="modal fade" show={OTPVerification}>
                                <div className="modal-dialog-centered " >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                            <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                                        <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCode(e.target.value)} value={otpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                                        <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                                        <label className="mb-2 ">{"Verification code has been sent to your registered mobile number"}</label>
                                                    </div>
                                                    {otpmessage_error !== "" ? <label className='text-red'>{otpmessage_error}</label> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                            <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal className="modal fade" show={GoogleAuthenticator}>
                                <div className="modal-dialog-centered " >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Google Authenticator</h3>
                                            <button type="button" className="close" onClick={() => setGoogleAuthenticator(false)}><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="mb-2 ">Google Authenticator Code</label>
                                                        <input type="text" maxLength="6" className="form-control text-black" placeholder="Please Enter Google Authenticator Code" onChange={(e) => validateAuthcode(e.target.value)} value={authcode} />
                                                        {authcode_error !== "" ? <label className='text-red'>{authcode_error}</label> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-light btn-rounded light" onClick={() => setGoogleAuthenticator(false)}>Close</button>
                                            <Link to="#" onClick={finalsubmit} className="btn btn-primary btn-rounded">Submit</Link>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal className="modal fade" show={TransferAccept}>
                                <div className="modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Card Fund Withdrawal</h3>
                                            <button type="button" className="close" onClick={() => setTransferAccept(false)}><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="check">
                                                <i class="fa fa-solid fa-check"></i>
                                                <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                                            </div>
                                            {success_display_refno === "" || success_display_refno === undefined || success_display_refno === null ? " " :
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-1">
                                                            <p className="fs-16 mb-1">Withdrawal Card Amount : <span className="text-black">{success_display_from_card}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-1">
                                                            <p className="fs-16 mb-1">Credited Amount to Account : <span className="text-black">{success_display_to_card}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-1">
                                                            <p className="fs-16 mb-1">Withdrawal Card Number : <span className="text-black">{success_display_from_account}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-1">
                                                            <p className="fs-16 mb-1">Credited {currancy} Account Number : <span className="text-black">{success_display_to_account}</span></p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 mb-1">
                                                            <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="row mt-1">
                                                <div className="col-md-12 mb-3">
                                                    <p className="fs-16 mb-1">Details</p>
                                                    <span className="text-black">{success_display_details}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3 mb-2">
                                                <ul className="btm-link">
                                                    <li>
                                                        <Link to="#" onClick={() => setTransferAccept(false)}>New Card Withdrawal</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/dashboard"> Dashboard</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/my-cards-transactions"> Transaction List</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>



                            <Modal className="modal fade" show={TransferDecline}>
                                <div className="modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Card Fund Withdrawal</h3>
                                            <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="check">
                                                <i class="fa fa-solid fa-times"></i>
                                                <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mb-1">
                                                    <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-md-12 mb-3">
                                                    <p className="fs-16 mb-1">Details</p>
                                                    <span className="text-black">{error_display_details}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3 mb-2">
                                                <ul className="btm-link">
                                                    <li>
                                                        <Link to="#" onClick={() => setTransferDecline(false)}> Retry </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/dashboard"> Dashboard</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/my-cards-transactions"> Transaction List</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>


                        </div>

                    </div>
                </div>
            }
        </>
    )

}
export default TransferAccount;