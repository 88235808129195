import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Table, Modal } from 'react-bootstrap';

import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";

import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
// import moment from 'moment';
import validator from 'validator';
import Snackbars from '../../pages/Snackbars';

export const Wallets_Withdrawal_Transaction = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    const [cypto_wallet_id, setcypto_wallet_id] = useState("");
    const [account_id, setaccount_id] = useState("");
    const [withaddress, setwithaddress] = useState("");

    const [coincode, setCoincode] = useState("");
    const [coinname, setCoinname] = useState("");
    const [icon, setIcon] = useState(0);
    const [total, setTotal] = useState(0);
    const [available, setAvailable] = useState(0);
    //const [pending, setPending] = useState(0);

    const [withamount, setWithamount] = useState(0);
    const [withamount_error, setWithamount_error] = useState("");

    const [google_auth, setgoogle_auth] = useState("");
    const [google_auth_error, setgoogle_auth_error] = useState("");
    const [OTPVerification, setOTPVerification] = useState(false);
    const [displaynote, setdisplaynote] = useState("");

    const [minamount, setminamount] = useState("");
    const [maxamount, setmaxamount] = useState("");
    const [usdamount, setusdamount] = useState("");
    const [euramount, seteuramount] = useState("");

    const [display_usdamount, setdisplay_usdamount] = useState(0);
    const [display_euramount, setdisplay_euramount] = useState(0);


    const [SMSOTPVerification, setSMSOTPVerification] = useState(false);
    const [transferotp, settransferotp] = useState("");
    const [transferotpmessage, settransferotpmessage] = useState("");
    const [transferotpmessage_error, settransferotpmessage_error] = useState("");

    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);
    const [success_display_amount, setsuccess_display_amount] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_toaccount, setsuccess_display_toaccount] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");

    useEffect(() => {
        if (window.localStorage.getItem("account_id") === "" || window.localStorage.getItem("account_id") === null || window.localStorage.getItem("account_id") === undefined) {
            history.push("/crypto");
        } else {
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        setcypto_wallet_id(window.localStorage.getItem("cypto_wallet_id"));
        setaccount_id(window.localStorage.getItem("account_id"));
        setwithaddress(window.localStorage.getItem("withaddress"));
        setLoader(true);
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            crypto_wallet_id: window.localStorage.getItem("cypto_wallet_id"),
            account_id: window.localStorage.getItem("account_id"),
            withaddress: window.localStorage.getItem("withaddress")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }

        axios.post(process.env.REACT_APP_API_URL + '../settings/GetWithdrawDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                window.localStorage.removeItem("cypto_wallet_id");
                window.localStorage.removeItem("account_id");
                window.localStorage.removeItem("withaddress");
                if (res.code === "200") {
                    settransferotp(res.data.otp);
                    setLoader(false);
                    if (res.data.coincode === "BTC") {
                        setCoinname("Bitcoin");
                        setIcon(btcicon);
                    } else if (res.data.coincode === "ETH") {
                        setCoinname("Ethereum");
                        setIcon(ethicon);
                    }
                    else if (res.data.coincode === "USDT") {
                        setCoinname("USDT");
                        setIcon(usdticon);
                    }
                    setCoincode(res.data.coincode);
                    setTotal(res.data.walletbalance);
                    setAvailable(res.data.available);
                    setminamount(res.data.minamount);
                    setmaxamount(res.data.maxamount);
                    setusdamount(res.data.usdamount);
                    seteuramount(res.data.euroamount);
                    //setPending(res.data.pending);
                    setdisplaynote(res.data.note);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const withsubmit = () => {
        if (validateAmount(withamount) === false) {
            setWithamount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        } else if (withaddress === "" || withaddress === null || withaddress === undefined) {
            setWithamount_error("");
            setError(true);
            setErrorMsg(t('CRYPTOTRANSACTION.WALLET_ADDRESS_PLACE'));
            setTimeout(() => {
                setError(false);
            }, 6000);
            return false;
        } else {
            if (parseFloat(withamount) <= 0) {
                setWithamount_error("You must enter amount greater than zero");
                return false;
            }
            setgoogle_auth("");
            setWithamount_error("");

            if (transferotp === "Google Authentication") {
                setOTPVerification(true);
            } else if (transferotp === "SMS OTP Authentication") {
                setSMSOTPVerification(true);
            } else {
                finalsubmit();
            }
        }
    }

    const finalsubmit = () => {

        if (transferotp === "Google Authentication") {
            if (validateGoogleAuth(google_auth) === false) {
                setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        } if (transferotp === "SMS OTP Authentication") {
            if (validateCodeTransfer(transferotpmessage) === false) {
                settransferotpmessage_error(t("FIAT.OTP_CODE_PLACE"));
                return false;
            }
        }

        setgoogle_auth_error("");
        settransferotpmessage_error("");
        if (parseFloat(withamount) <= 0) {
            setWithamount_error("You must enter amount greater than zero");
            return false;
        }
        setWithamount_error("");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            crypto_wallet_id: cypto_wallet_id,
            account_id: account_id,
            withdraw_address: withaddress,
            withdraw_amount: withamount,
            auth_code: google_auth,
            smscode: transferotpmessage
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/withdrawsubmit', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setOTPVerification(false);
                    setSMSOTPVerification(false);
                    setsuccess_display_amount(res.data.success_display_amount);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_toaccount(res.data.success_display_toaccount);
                    setsuccess_display_details(res.data.success_display_details);

                    setTransferAccept(true);
                    setLoader(false);
                    setTimeout(() => {
                        history.push({
                            pathname: '/crypto',
                            openSnackbar: true,
                            openSnackbarmsg: res.message,
                            resdisplay: res.data
                        });
                        // history.push("/crypto");
                    }, 6000);
                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateCodeTransfer = (value) => {
        settransferotpmessage(value);
        if (!validator.isEmpty(value)) {
            settransferotpmessage_error("");
            return true;
        } else {
            settransferotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateAmount = (value) => {
        setWithamount(value);
        if (validator.isNumeric(value)) {
            setdisplay_usdamount(parseFloat(usdamount) * value);
            setdisplay_euramount(parseFloat(euramount) * value);
            if (parseFloat(minamount) > 0) {
                if (parseFloat(value) < parseFloat(minamount)) {
                    setWithamount_error("Minimum Amount is " + parseFloat(minamount).toFixed(6));
                    return false;
                }

                if (parseFloat(value) > parseFloat(maxamount)) {
                    setWithamount_error("Maximum Amount is " + parseFloat(maxamount).toFixed(6));
                    return false;
                }
            }
            if (parseFloat(value) > parseFloat(available)) {
                setWithamount_error("Not enough balance");
                return false;
            }

            setWithamount_error("");
            return true;
        } else {
            setWithamount_error(t("TRANSACTION.AMOUNT_PLACE"));
            return false;
        }
    }

    const validateGoogleAuth = (value) => {
        setgoogle_auth(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_error("");
            return true;
        } else {
            setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }

    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header page-title-space">
                        <h2 className="text-black font-w600 mb-0">{coinname} {t('CRYPTOTRANSACTION.WITHDRAW_TITLE')} </h2>
                        <Link to="/crypto" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t("LINK.BACK")}</Link>
                    </div>
                </div>
            }
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="row">
                    <div className="col-md-6">
                        <div className="card height-auto">
                            <div className="card-body">
                                <div className="media ai-icon">
                                    <span className="me-3 bgl-primary text-primary inner-icon">
                                        <img alt="" src={icon} className="bitimg" />
                                    </span>
                                    <div className="media-body">
                                        <h4 className="mb-1 coinname-new">{coinname}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="col-md-12">
                                    <div className="table-cstm-border">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>{t("CRYPTOTRANSACTION.TOTAL_BALANCE")}</th>
                                                    <th>{t("CRYPTOTRANSACTION.AVAILABLE_BALANCE")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{parseFloat(total)} {coincode}</td>
                                                    <td>{parseFloat(available)} {coincode}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card height-auto">
                            <div className="card-body">
                                <div dangerouslySetInnerHTML={{ __html: displaynote }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card height-auto">
                            <div className="card-body p-4">
                                <h4 className="card-title mb-5">{t('CRYPTOTRANSACTION.WITH_TITLE')}</h4>
                                <div className="col-md-12 mt-4">
                                    <div className="form-group position-relative mt-4">
                                        <label className="mb-2 ">{coinname} {t('CRYPTOTRANSACTION.WALLET_ADDRESS')}</label>
                                        <input type="text" className="form-control" value={withaddress} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">{t('TRANSACTION.AMOUNT')}</label>
                                        <input type="text" className="form-control" onChange={(e) => validateAmount(e.target.value)} value={withamount} placeholder={t('TRANSACTION.AMOUNT_PLACE')} />
                                        <span className="btc-price">{coincode}</span>
                                    </div>
                                    {withamount_error !== "" ? <label className='text-red'>{withamount_error}</label> : ""}
                                </div>

                                {maxamount > 0 ?
                                    <div className="col-md-12 mt-4">
                                        <label className="mb-2 mr-3 minmaxclass">Min Amount = {minamount.toFixed(6)} </label>
                                        <label className="mb-2 minmaxclass">Max Amount = {maxamount.toFixed(6)} </label>
                                    </div>
                                    : ""}

                                {display_usdamount > 0 ?
                                    <div className="col-md-12 mt-4">
                                        <label className="mb-2 mr-3 minmaxclass">USD Amount = {display_usdamount.toFixed(2)} </label>
                                        <label className="mb-2 minmaxclass">EURO Amount = {display_euramount.toFixed(2)} </label>
                                    </div>
                                    : ""}

                                <div className="col-md-12 mb-4">
                                    <button type="button" onClick={withsubmit} className="btn btn-primary btn-rounded btn-block">{t('CRYPTOTRANSACTION.WITH_TITLE')}</button>
                                </div>
                            </div>
                        </div>
                        <Modal className="modal fade" show={OTPVerification}>
                            <div className="modal-dialog-centered " >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                        <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t("FIAT.OTP_CODE_TITLE")}</label>
                                                    <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuth(e.target.value)} value={google_auth} placeholder={t("FIAT.OTP_CODE_PLACE")} />
                                                    {google_auth_error !== "" ? <label className='text-red'>{google_auth_error}</label> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t("BUTTONS.CANCEL")}</button>
                                        <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal className="modal fade" show={SMSOTPVerification}>
                            <div className="modal-dialog-centered " >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                        <button type="button" className="close" onClick={() => setSMSOTPVerification(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                                    <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCodeTransfer(e.target.value)} value={transferotpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                                    <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                                    <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                                </div>
                                                {transferotpmessage_error !== "" ? <label className='text-red'>{transferotpmessage_error}</label> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setSMSOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                        <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                        <Modal className="modal fade" show={TransferAccept}>
                            <div className="modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Crypto Fund withdrawal</h3>
                                        <button type="button" className="close" onClick={() => history.push("/crypto")}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check">
                                            <i class="fa fa-solid fa-check"></i>
                                            <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                                        </div>
                                        {success_display_refno === "" || success_display_refno === undefined || success_display_refno === null ? " " :
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Amount : <span className="text-black">{success_display_amount}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Transfer to : <span className="text-black">{success_display_toaccount}</span></p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="row mt-1">
                                            <div className="col-md-12 mb-3">
                                                <p className="fs-16 mb-1">Details</p>
                                                <span className="text-black">{success_display_details}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-2">
                                            <ul className="btm-link">
                                                <li>
                                                    <Link to="/crypto"> New Crypto Transfer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/dashboard"> Dashboard</Link>
                                                </li>
                                                <li>
                                                    <Link to="/crypto-transactions"> Transaction List</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal className="modal fade" show={TransferDecline}>
                            <div className="modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Crypto Fund withdrawal</h3>
                                        <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check">
                                            <i class="fa fa-solid fa-times"></i>
                                            <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 mb-1">
                                                <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-md-12 mb-3">
                                                <p className="fs-16 mb-1">Details</p>
                                                <span className="text-black">{error_display_details}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-2">
                                            <ul className="btm-link">
                                                <li>
                                                    <Link to="/crypto"> Retry </Link>
                                                </li>
                                                <li>
                                                    <Link to="/dashboard"> Dashboard</Link>
                                                </li>
                                                <li>
                                                    <Link to="/crypto-transactions"> Transaction List</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            }
        </>
    )

}
export default Wallets_Withdrawal_Transaction;