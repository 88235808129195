import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import Loader from '../../pages/Loader';
import validator from 'validator';
import profile from "../../../images/profile/profile.png";

export const EditProfile = () => {
    const { t } = useTranslation();
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [customerid, setCustomerid] = useState("");
    const [dob, setDob] = useState("");
    const [phonecode, setPhonecode] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [timezone, setTimezone] = useState("");
    const [addressline1, setAddressline1] = useState("");
    const [addressline2, setAddressline2] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);
    const [allcountry, setAllCountry] = useState([]);
    const [allstate, setAllState] = useState([]);
    const [alltimezone, setAllTimezone] = useState([]);
   // const [alllang, setAllLang] = useState([]);
    //const [lang, setLang] = useState("");

    const [first_name_error, setFirst_name_error] = useState("");
    const [last_name_error, setLastName_error] = useState("");
    const [dob_error, setDob_error] = useState("");
    const [timezone_error, setTimezone_error] = useState("");
    const [addressline1_error, setAddressline1_error] = useState("");
    const [addressline2_error, setAddressline2_error] = useState("");
    const [country_error, setCountry_error] = useState("");
    const [state_error, setState_error] = useState("");
    const [city_error, setCity_error] = useState("");
    const [zipcode_error, setZipcode_error] = useState("");
    //const [lang_error, setLang_error] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [getphoto, setPhoto] = useState("");
    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };


    useEffect(() => {
        // console.log(new Date().toISOString().split("T")[0]);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getEditProfileDetails/' + window.sessionStorage.getItem("user_id"), { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setFirst_name(res.data.first_name);
                    setLastName(res.data.last_name);
                    setEmail(res.data.email_id);
                    setCustomerid(res.data.customer_id);
                    if (res.data.dob === null) {
                        setDob("");
                    } else {
                        setDob(res.data.dob);
                    }
                    setPhonecode(res.data.phonecode);
                    setPhoneNumber(res.data.phone_number);
                    setTimezone(res.data.timezone);
                    setAddressline1(res.data.addressline1);
                    setAddressline2(res.data.addressline2);
                    setCountry(res.data.country);
                    setState(res.data.state);
                    if (res.data.state > 0) {
                        getallStates(res.data.country);
                    }
                    setZipcode(res.data.zipcode);
                    setCity(res.data.city);
                    setAllCountry(res.data.countryarray);
                    //setAllLang(res.data.langarray);
                    setAllTimezone(res.data.timezonearray);
                    //setLang(res.data.lang_id);
                    setLoader(false);
                    if (res.data.lang_id > 0 && res.data.lang_id === "2") {
                        window.sessionStorage.setItem("languagesessionuser", 'fr');
                    } else {
                        window.sessionStorage.setItem("languagesessionuser", 'en');
                    }

                    if (res.data.image_64 === null || res.data.image_64 === "") {
                        setPhoto(profile);
                    } else {
                        setPhoto(process.env.REACT_APP_FILE_PATH + "" + res.data.image_64);
                    }
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const getallStates = (counryid) => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getStatesFromCountry/' + counryid, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllState(res.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const validateFirstName = (value) => {
        setFirst_name(value);
        if (!validator.isEmpty(value)) {
            setFirst_name_error("");
            return true;
        } else {
            setFirst_name_error(t('ERROR_MSG.ERROR_FIRSTNAME'));
            return false;
        }
    }

    const validateLastName = (value) => {
        setLastName(value);
        if (!validator.isEmpty(value)) {
            setLastName_error("");
            return true;
        } else {
            setLastName_error(t('ERROR_MSG.ERROR_LASTNAME'));
            return false;
        }
    }

    const validateDOB = (value) => {
        setDob(value);
        if (validator.isDate(value)) {
            setDob_error("");
            return true;
        } else {
            setDob_error(t('ERROR_MSG.ERROR_DOB'));
            return false;
        }
    }

    const validateAddressLine1 = (value) => {
        setAddressline1(value);
        if (!validator.isEmpty(value)) {
            setAddressline1_error("");
            return true;
        } else {
            setAddressline1_error(t('ERROR_MSG.ERROR_ADDRESS1'));
            return false;
        }
    }

    const validateAddressLine2 = (value) => {
        setAddressline2(value);
        if (!validator.isEmpty(value)) {
            setAddressline2_error("");
            return true;
        } else {
            setAddressline2_error(t('ERROR_MSG.ERROR_ADDRESS2'));
            return false;
        }
    }

    const validateCountry = (value) => {
        setCountry(value);
        if (!validator.isEmpty(value)) {
            getallStates(value);
            setState("");
            setCountry_error("");
            return true;
        } else {
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }

    const validateCountrysecond = () => {
        if (!validator.isEmpty(country)) {
            setCountry_error("");
            return true;
        } else {
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        }
    }

    const validateState = (value) => {
        setState(value);
        if (!validator.isEmpty(value)) {
            setState_error("");
            return true;
        } else {
            setState_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        }
    }

    const validateCity = (value) => {
        setCity(value);
        if (!validator.isEmpty(value)) {
            setCity_error("");
            return true;
        } else {
            setCity_error(t('ERROR_MSG.ERROR_CITY'));
            return false;
        }
    }

    const validateZipcode = (value) => {
        setZipcode(value);
        if (!validator.isEmpty(value)) {
            setZipcode_error("");
            return true;
        } else {
            setZipcode_error(t('ERROR_MSG.ERROR_ZIPCODE'));
            return false;
        }
    }

    const validateTimezone = (value) => {
        setTimezone(value);
        if (!validator.isEmpty(value)) {
            setTimezone_error("");
            return true;
        } else {
            setTimezone_error(t('ERROR_MSG.ERROR_TIMEZONE'));
            return false;
        }
    }

    // const validateLang = (value) => {
    //     setLang(value);
    //     if (!validator.isEmpty(value)) {
    //         setLang_error("");
    //         return true;
    //     } else {
    //         setLang_error(t('ERROR_MSG.ERROR_LANG'));
    //         return false;
    //     }
    // }

    const handlesubmit = () => {
        if (validateFirstName(first_name) === false) {
            setFirst_name_error(t('ERROR_MSG.ERROR_FIRSTNAME'));
            return false;
        } else if (validateLastName(last_name) === false) {
            setFirst_name_error("");
            setLastName_error(t('ERROR_MSG.ERROR_LASTNAME'));
            return false;
        } else if (validateDOB(dob) === false) {
            setLastName_error("");
            setDob_error(t('ERROR_MSG.ERROR_DOB'));
            return false;
        } else if (validateAddressLine1(addressline1) === false) {
            setDob_error("");
            setAddressline1_error(t('ERROR_MSG.ERROR_ADDRESS1'));
            return false;
        } else if (validateAddressLine2(addressline2) === false) {
            setAddressline1_error("");
            setAddressline2_error(t('ERROR_MSG.ERROR_ADDRESS2'));
            return false;
        } else if (validateCountrysecond(country) === false) {
            setAddressline2_error("");
            setCountry_error(t('ERROR_MSG.ERROR_COUNTRY'));
            return false;
        } else if (validateState(state) === false) {
            setCountry_error("");
            setState_error(t('ERROR_MSG.ERROR_STATE'));
            return false;
        } else if (validateCity(city) === false) {
            setState_error("");
            setCity_error(t('ERROR_MSG.ERROR_CITY'));
            return false;
        } else if (validateZipcode(zipcode) === false) {
            setCity_error("");
            setZipcode_error(t('ERROR_MSG.ERROR_ZIPCODE'));
            return false;
        } else if (validateTimezone(timezone) === false) {
            setZipcode_error("");
            setTimezone_error(t('ERROR_MSG.ERROR_TIMEZONE'));
            return false;
        } else {
            setTimezone_error("");

            // else if (validateLang(lang) === false) {
            //     setTimezone_error("");
            //     setLang_error(t('ERROR_MSG.ERROR_LANG'));
            //     return false;
            // } 

            const formData = new FormData();
            formData.append("user_id", window.sessionStorage.getItem("user_id"));
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("dob", dob);
            formData.append("timezone", timezone);
            formData.append("country_id", country);
            formData.append("state_id", state);
            formData.append("addressline1", addressline1);
            formData.append("addressline2", addressline2);
            formData.append("city", city);
            formData.append("zipcode", zipcode);
            formData.append("lang_id", "1");

            if (fileName !== "") {
                formData.append("file", file);
                formData.append("fileName", fileName);
            } else {
                formData.append("fileName", "");
            }

           // setLang_error("");

            const headers = {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                'Content-Length': '<calculated when request is sent>',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);

            axios.post(process.env.REACT_APP_API_URL + 'updateUserProfile', formData, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            //handleLoad();
                            window.location.reload();
                            setSuccess(false);
                        }, 1000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }


    return (
        <>

            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t('LINK.EDITPROFILE')}</h2>

                    <div className="profile profile-photo" style={{ maxWidth: "100px" }}>
                        <img src={getphoto} className="img-fluid rounded-circle" alt="profile" />
                    </div>
                </div>
            </div>
            <div className="card">
                {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
                {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.CUSTOMERID')}</label>
                                    <input type="text" className="form-control" value={customerid} readOnly />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.EMAIL')}</label>
                                    <input type="email" className="form-control" value={email} readOnly />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>{t('LABEL.LABEL_PHONE_NUMBER')}</label>
                                    <div className="form-group position-relative countrybox1">
                                        <select className="form-control webkit-none" value={phonecode} disabled>
                                            {allcountry && allcountry.map((row) => (
                                                <option value={row.phonecode} key={row.country_id_pk}>+{row.phonecode}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    <div className='country-text-box1'>
                                        <input type="text" className="form-control" placeholder='Phone number' value={phonenumber} readOnly />
                                    </div>

                                </div> 
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('LABEL.LABEL_FIRST_NAME')}</label>
                                    <input type="text" className="form-control" onChange={(e) => validateFirstName(e.target.value)} value={first_name} />
                                    {first_name_error !== "" ? <label className='text-red'>{first_name_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('LABEL.LABEL_LAST_NAME')}</label>
                                    <input type="text" className="form-control" onChange={(e) => validateLastName(e.target.value)} value={last_name} />
                                    {last_name_error !== "" ? <label className='text-red'>{last_name_error}</label> : ""}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.DOB')}</label>
                                    <input type="date" className="form-control " onChange={(e) => validateDOB(e.target.value)} value={dob} max={new Date().toISOString().split("T")[0]} />
                                    {dob_error !== "" ? <label className='text-red'>{dob_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.ADDRESS_LINE_ONE')}</label>
                                    <input type="text" className="form-control" onChange={(e) => validateAddressLine1(e.target.value)} value={addressline1} />
                                    {addressline1_error !== "" ? <label className='text-red'>{addressline1_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.ADDRESS_LINE_TWO')}</label>
                                    <input type="text" className="form-control " onChange={(e) => validateAddressLine2(e.target.value)} value={addressline2} />
                                    {addressline2_error !== "" ? <label className='text-red'>{addressline2_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.COUNTRY')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={country} onChange={(e) => validateCountry(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                            {allcountry && allcountry.map((row) => (
                                                <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {country_error !== "" ? <label className='text-red'>{country_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.STATE')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={state} onChange={(e) => validateState(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_STATE')}</option>
                                            {allstate && allstate.map((row) => (
                                                <option value={row.id} key={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {state_error !== "" ? <label className='text-red'>{state_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.CITY')}</label>
                                    <input type="text" className="form-control " value={city} onChange={(e) => validateCity(e.target.value)} />
                                    {city_error !== "" ? <label className='text-red'>{city_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.ZIPCODE')}</label>
                                    <input type="text" className="form-control " value={zipcode} onChange={(e) => validateZipcode(e.target.value)} />
                                    {zipcode_error !== "" ? <label className='text-red'>{zipcode_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.TIMEZONE')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={timezone} onChange={(e) => validateTimezone(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_TIMEZONE')}</option>
                                            {alltimezone && alltimezone.map((row) => (
                                                <option value={row.tid} key={row.tid}>{row.gmtdata} {row.timezonedata}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {timezone_error !== "" ? <label className='text-red'>{timezone_error}</label> : ""}
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.LANGUAGE')}</label>
                                    <div className="form-group position-relative mb-0">
                                        <select className="form-control webkit-none" value={lang} onChange={(e) => validateLang(e.target.value)}>
                                            <option value="">{t('PROFILE.SELECT_LANGUAGE')}</option>
                                            {alllang && alllang.map((row) => (
                                                <option value={row.language_id_pk} key={row.language_id_pk}>{row.language_name}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {lang_error !== "" ? <label className='text-red'>{lang_error}</label> : ""}
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">{t('PROFILE.UPLOAD_PHOTO')}</label>
                                    <input type="file" className="form-control " accept="image/png, image/jpeg, image/jpg" onChange={saveFile} />
                                </div>
                            </div>
                            <div className="col-md-12 mt-2">
                                <button type="button" id="submit" onClick={handlesubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.UPDATE')}</button>
                                <Link to="/profile" className="btn btn-outline-light ml-3 btn-rounded">{t('BUTTONS.CANCEL')}</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    )

}
export default EditProfile;