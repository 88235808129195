import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Dropdown, Modal } from "react-bootstrap";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import moment from 'moment';
import Snackbars from '../../pages/Snackbars';
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import validator from 'validator';


import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";

import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';

export const Home = () => {
	const { t } = useTranslation();
	const [USDModal, setUSDModal] = useState(false);
	const [EURModal, setEURModal] = useState(false);
	const [modal_usd, setmodal_usd] = useState("");
	const [modal_eur, setmodal_eur] = useState("");
	const [usdmodalemail_error, setusdmodalemail_error] = useState("");
	const [eurmodalemail_error, seteurmodalemail_error] = useState("");
	const [accountarray, setAccountarray] = useState([]);
	const [fiataccount, setfiataccount] = useState([]);
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(false);
	const [success_msg, setSuccessMsg] = useState("");
	const [allUsercrypto, setUserCryptoWallet] = useState([]);
	const [exchnagetra, setexchnagetra] = useState([]);
	const [swaptra, setswaptra] = useState([]);

	useEffect(() => {
		handleLoad();
		handleLoadCrypto();
		handleLoadTransaction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeCSVNdPDF = (val) => {
		var FileName = "Swap Transactions";

		var columns = [
			{ title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
			{ title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
			{ title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
			{ title: t("FIAT.FROM"), field: 'from_coin' },
			{ title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
			{ title: t("FIAT.TO"), field: 'to_coin' },
			{ title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
		];

		const columnTitles = columns.map(columnDef => columnDef.title);

		const FileData = swaptra.map(rowData =>
			[rowData["transaction_hash"],
			rowData["created_datetime"],
			rowData["from_amount"],
			rowData["from_coincode"],
			rowData["to_amount"],
			rowData["to_coincode"],
			rowData["swap_statusText"]]
		);
		if (val === "CSV") {
			const builder = new CsvBuilder(FileName + `.csv`)
				.setColumns(columnTitles)
				.addRows(FileData)
				.exportFile();

			return builder;
		}
		else if (val === "PDF") {
			const doc = new jsPDF();

			doc.autoTable({
				head: [columnTitles],
				body: FileData,
			});

			doc.save(FileName + `.pdf`);
		}
	}

	const handleChangeCSVNdPDFsecond = (val) => {
		var FileName = "Buy/Sell Transactions";

		var columns = [
			{ title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
			{ title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
			{ title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
			{ title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
			{ title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
			{ title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
			{ title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
		];

		const columnTitles = columns.map(columnDef => columnDef.title);

		const FileData = exchnagetra.map(rowData =>
			[rowData["transaction_hash"],
			rowData["created_datetime"],
			rowData["exchange_type"],
			rowData["exchnage_pair"],
			rowData["fiat_amount"],
			rowData["crypto_amount"],
			rowData["transfer_statusText"]]
		);
		if (val === "CSV") {
			const builder = new CsvBuilder(FileName + `.csv`)
				.setColumns(columnTitles)
				.addRows(FileData)
				.exportFile();

			return builder;
		}
		else if (val === "PDF") {
			const doc = new jsPDF();

			doc.autoTable({
				head: [columnTitles],
				body: FileData,
			});

			doc.save(FileName + `.pdf`);
		}
	}

	const handleChangeCSVNdPDFthird = (val) => {
		var FileName = "Crypto Accounts";

		var columns = [
			{ title: t("CRYPTOTRANSACTION.COIN_CODE"), field: 'coin_code' },
			{ title: t("CRYPTOTRANSACTION.WALLET_BALANCE"), field: 'wallet_balance' },
			{ title: t("CRYPTOTRANSACTION.TOTAL_UNAVAIL"), field: 'total_unavailable' },
			{ title: t("CRYPTOTRANSACTION.TOTAL_AVAIL"), field: 'total_available' },
		];

		const columnTitles = columns.map(columnDef => columnDef.title);

		const FileData = allUsercrypto.map(rowData =>
			[rowData["coin_code"],
			rowData["wallet_balance"],
			rowData["total_unavailable"],
			rowData["total_available"]]
		);
		if (val === "CSV") {
			const builder = new CsvBuilder(FileName + `.csv`)
				.setColumns(columnTitles)
				.addRows(FileData)
				.exportFile();

			return builder;
		}
		else if (val === "PDF") {
			const doc = new jsPDF();

			doc.autoTable({
				head: [columnTitles],
				body: FileData,
			});

			doc.save(FileName + `.pdf`);
		}
	}

	const handleChangeCSVNdPDFfour = (val) => {
		var FileName = "Fiat Transactions";

		var columns = [
			{ title: t('TRANSACTION.DATE_LA'), field: 'date' },
			{ title: t('TRANSACTION.TRANSACTION_DETAILS'), field: 'transaction_details' },
			{ title: t('TRANSACTION.DEBIT_LA'), field: 'type' },
			{ title: t('TRANSACTION.AMOUNT'), field: 'amount' },
			{ title: t('TRANSACTION.BALANCE_LA'), field: 'balance' },
		];

		const columnTitles = columns.map(columnDef => columnDef.title);

		const FileData = fiataccount.map(rowData =>
			[rowData["date"],
			rowData["transaction_details"],
			rowData["type"],
			rowData["amountText"],
			rowData["balance"]]
		);
		if (val === "CSV") {
			const builder = new CsvBuilder(FileName + `.csv`)
				.setColumns(columnTitles)
				.addRows(FileData)
				.exportFile();

			return builder;
		}
		else if (val === "PDF") {
			const doc = new jsPDF();

			doc.autoTable({
				head: [columnTitles],
				body: FileData,
			});

			doc.save(FileName + `.pdf`);
		}
	}

	const handleLoad = () => {
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}
		setLoader(true);
		axios.get(process.env.REACT_APP_API_URL + 'getuseraccoundsbyid/' + window.sessionStorage.getItem("user_id"), { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setAccountarray(res.data);
					var jsonpar = {
						user_id: window.sessionStorage.getItem("user_id")
					}
					axios.post(process.env.REACT_APP_API_URL + 'GetAllInternal', jsonpar, { headers: headers })
						.then(function (res) {
							res = res.data;
							if (res.code === "200") {
								var TempArrfiat = [];
								res.data.forEach(element => {
									var amount = "";
									var current_balance = "";
									if (element.type === "Debit") {
										amount = <div><span className="me-2 oi-icon bgl-danger"><svg width="18" height="18" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M6.57624 0.769646C6.77936 1.18944 6.59993 1.69725 6.18014 1.90038C5.01217 2.46236 4.02363 3.33579 3.31947 4.42928C2.59837 5.54986 2.21582 6.84986 2.21582 8.19048C2.21582 12.0363 5.34394 15.1644 9.18978 15.1644C13.0356 15.1644 16.1637 12.0363 16.1637 8.19048C16.1637 6.84986 15.7812 5.54985 15.0635 4.4259C14.3627 3.33241 13.3708 2.45897 12.2028 1.89699C11.783 1.69387 11.6036 1.18944 11.8067 0.766262C12.0098 0.34647 12.5143 0.167042 12.9374 0.370167C14.3898 1.06756 15.6187 2.1509 16.4887 3.51183C17.3825 4.90663 17.8564 6.52486 17.8564 8.19048C17.8564 10.5061 16.9559 12.6829 15.3174 14.3181C13.6822 15.9566 11.5054 16.8571 9.18978 16.8571C6.87415 16.8571 4.69733 15.9566 3.06217 14.3181C1.42363 12.6795 0.523111 10.5061 0.523111 8.19048C0.523111 6.52486 0.99707 4.90663 1.89421 3.51183C2.76764 2.15428 3.99655 1.06756 5.44551 0.370167C5.86868 0.170427 6.37311 0.34647 6.57624 0.769646Z" fill="#FF2E2E"></path>
											<path d="M5.89551 7.7402C5.72962 7.57431 5.64837 7.35765 5.64837 7.14098C5.64837 6.92431 5.72962 6.70765 5.89551 6.54176L8.02493 4.41233C8.33639 4.10088 8.74941 3.93161 9.18613 3.93161C9.62285 3.93161 10.0393 4.10426 10.3473 4.41233L12.4768 6.54176C12.8085 6.87353 12.8085 7.40843 12.4768 7.7402C12.145 8.07197 11.6101 8.07197 11.2783 7.7402L10.0291 6.49098L10.0291 11.3017C10.0291 11.7688 9.64993 12.148 9.18275 12.148C8.71556 12.148 8.33639 11.7688 8.33639 11.3017L8.33639 6.4876L7.08717 7.73681C6.76217 8.06858 6.22728 8.06858 5.89551 7.7402Z" fill="#FF2E2E"></path>
										</svg>
										</span>&nbsp;&nbsp;{element.amount}</div>;
									} else {
										amount = <div>
											<span className="me-2 oi-icon bgl-success">
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0)">
														<path d="M11.4238 16.2304C11.2206 15.8106 11.4001 15.3027 11.8199 15.0996C12.9878 14.5376 13.9764 13.6642 14.6805 12.5707C15.4016 11.4501 15.7842 10.1501 15.7842 8.80952C15.7842 4.96369 12.6561 1.83556 8.81022 1.83556C4.96439 1.83556 1.83626 4.96369 1.83626 8.80952C1.83626 10.1501 2.21881 11.4501 2.93652 12.5741C3.6373 13.6676 4.62923 14.541 5.7972 15.103C6.21699 15.3061 6.39642 15.8106 6.19329 16.2337C5.99017 16.6535 5.48574 16.833 5.06256 16.6298C3.61022 15.9324 2.38131 14.8491 1.51126 13.4882C0.617512 12.0934 0.143554 10.4751 0.143554 8.80952C0.143554 6.49389 1.04408 4.31707 2.68262 2.68192C4.31777 1.04337 6.4946 0.142853 8.81022 0.142854C11.1258 0.142854 13.3027 1.04337 14.9378 2.68192C16.5764 4.32046 17.4769 6.4939 17.4769 8.80952C17.4769 10.4751 17.0029 12.0934 16.1058 13.4882C15.2324 14.8457 14.0034 15.9324 12.5545 16.6298C12.1313 16.8296 11.6269 16.6535 11.4238 16.2304Z" fill="#2BC155" />
														<path d="M12.1045 9.2598C12.2704 9.42569 12.3516 9.64235 12.3516 9.85902C12.3516 10.0757 12.2704 10.2924 12.1045 10.4582L9.97506 12.5877C9.66361 12.8991 9.25059 13.0684 8.81387 13.0684C8.37715 13.0684 7.96074 12.8957 7.65267 12.5877L5.52324 10.4582C5.19147 10.1265 5.19147 9.59157 5.52324 9.2598C5.85501 8.92803 6.38991 8.92803 6.72168 9.2598L7.9709 10.509L7.9709 5.69834C7.9709 5.23116 8.35007 4.85199 8.81725 4.85199C9.28444 4.85199 9.66361 5.23116 9.66361 5.69834L9.66361 10.5124L10.9128 9.26319C11.2378 8.93142 11.7727 8.93142 12.1045 9.2598Z" fill="#2BC155" />
													</g>
													<defs>
														<clipPath id="clip0">
															<rect width="17.3333" height="17.3333" fill="white" transform="matrix(-9.93477e-08 1 1 9.93477e-08 0.143555 0.142853)" />
														</clipPath>
													</defs>
												</svg>
											</span>&nbsp;&nbsp;{element.amount}</div>;
									}

									if (element.currency_type === "USD") {
										current_balance = element.current_balance + " USD";
									} else {
										current_balance = element.current_balance + " EUR";
									}

									var fiatonetemp = {
										transaction_id: "#" + element.balance_sheet_id,
										date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
										transaction_details: element.transaction_type,
										type: element.type,
										amount: amount,
										balance: current_balance,
										amountText: element.amount,
									};
									TempArrfiat.push(fiatonetemp);

								});
								setfiataccount(TempArrfiat);

								setTimeout(() => {
									setLoader(false);
								}, 1000);
							} else {
								setTimeout(() => {
									setLoader(false);
								}, 3000);
							}
						})
						.catch(function (error) {
							setTimeout(() => {
								setLoader(false);
							}, 1000);
						});
				} else {
					setTimeout(() => {
						setLoader(false);
					}, 1000);
				}
			})
			.catch(function (error) {
				setTimeout(() => {
					setLoader(false);
				}, 1000);
			});
	}


	const handleLoadCrypto = () => {
		var jsonpar = {
			user_id: window.sessionStorage.getItem("user_id")
		}
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + '../settings/GetAllCrypto', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					if (res.data.usercryptowallet.length > 0) {
						var TempArrcrypto = [];
						res.data.usercryptowallet.forEach(element => {
							var newcoin = "";
							var newcoincode = "";
							var newwalletbalance = "";
							var newtotalunavailable = "";
							var newtotalavailable = "";

							if (element.coin_code === "BTC") {
								newcoincode = element.coin_code;
								newcoin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
								newwalletbalance = element.wallet_balance + " BTC";
								newtotalunavailable = element.total_unavailable + " BTC";
								newtotalavailable = element.total_available + " BTC";
							} else if (element.coin_code === "ETH") {
								newcoin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
								newcoincode = element.coin_code;
								newwalletbalance = element.wallet_balance + " ETH";
								newtotalunavailable = element.total_unavailable + " ETH";
								newtotalavailable = element.total_available + " ETH";
							} else if (element.coin_code === "USDT") {
								newcoin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
								newcoincode = element.coin_code;
								newwalletbalance = element.wallet_balance + " USDT";
								newtotalunavailable = element.total_unavailable + " USDT";
								newtotalavailable = element.total_available + " USDT";
							}

							var fiatonetemp = {
								coin: newcoin,
								coin_code: newcoincode,
								wallet_balance: newwalletbalance,
								total_unavailable: newtotalunavailable,
								total_available: newtotalavailable
							};
							TempArrcrypto.push(fiatonetemp);

						});
						setUserCryptoWallet(TempArrcrypto);
					}

				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setTimeout(() => {
					setLoader(false);
				}, 1000);
			});
	}

	const handleLoadTransaction = () => {
		var jsonpar = {
			user_id: window.sessionStorage.getItem("user_id")
		}
		const headers = {
			'Content-Type': 'application/json',
			'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
			'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + '../settings/GetAllCryptoExchange', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setexchnagetra(res.data.buyselltra);
					setswaptra(res.data.swaptra);

					if (res.data.buyselltra.length > 0) {
						var TempArrExchange = [];
						res.data.buyselltra.forEach(element => {

							var status = "";
							if (element.transfer_status === "Completed") {
								status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
							} else if (element.transfer_status === "Failed") {
								status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
							} else {
								status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
							}
							var fiatonetemp = {
								transaction_hash: element.transaction_hash,
								created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
								exchange_type: element.exchange_type,
								exchnage_pair: element.exchnage_pair,
								fiat_amount: element.fiat_amount,
								crypto_amount: element.crypto_amount,
								transfer_status: status,
								transfer_statusText: element.transfer_status,
							};
							TempArrExchange.push(fiatonetemp);

						});
						setexchnagetra(TempArrExchange);
					}


					if (res.data.swaptra.length > 0) {
						var TempArrswap = [];
						res.data.swaptra.forEach(element => {
							var from_coin = "";
							var to_coin = "";
							var status = "";
							if (element.swap_status === "Completed") {
								status = <div><Link className="btn btn-outline-success btn-rounded">Success</Link></div>
							} else if (element.swap_status === "Failed") {
								status = <div><Link className="btn btn-outline-danger btn-rounded">Failed</Link></div>
							} else {
								status = <div><Link className="btn btn-outline-warning btn-rounded">Pending</Link></div>
							}

							if (element.coin_code === "BTC") {
								from_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
							} else if (element.coin_code === "ETH") {
								from_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
							} else if (element.coin_code === "USDT") {
								from_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
							}

							if (element.to_coin_code === "BTC") {
								to_coin = <div><img alt="" src={btcicon} className="coin-img" /></div>;
							} else if (element.to_coin_code === "ETH") {
								to_coin = <div><img alt="" src={ethicon} className="coin-img" /></div>;
							} else if (element.to_coin_code === "USDT") {
								to_coin = <div><img alt="" src={usdticon} className="coin-img" /></div>;
							}

							var swaptemp = {
								transaction_hash: element.transaction_hash,
								created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
								from_amount: element.from_amount,
								from_coin: from_coin,
								to_amount: element.to_amount,
								to_coin: to_coin,
								swap_status: status,
								swap_statusText: element.swap_status,
								from_coincode: element.coin_code,
								to_coincode: element.to_coin_code,

							};
							TempArrswap.push(swaptemp);

						});
						setswaptra(TempArrswap);
					}
				} else {

					setLoader(false);

				}
			})
			.catch(function (error) {
				setTimeout(() => {
					setLoader(false);
				}, 1000);
			});
	}


	const sendaccinfo = () => {
		if (validateEURModal(modal_eur) === false) {
			seteurmodalemail_error(t("PLACEHOLDER.PLACEHOLDER_EMAIL"));
		} else {
			seteurmodalemail_error("");
			setEURModal(false);
			var text = t("HEADER.ACCOUNTNO") + " : " + accountarray[1].account_number + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + accountarray[1].routing_number + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + accountarray[1].swift_code + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + accountarray[1].bank_name + "<br>" +
				t("HEADER.ACCOUNT_TYPE") + " : " + accountarray[1].account_currency_type + "<br>" +
				t("HEADER.CUS_NAME") + " : " + accountarray[1].customer_name + "<br>" +
				t("HEADER.CUS_EMAIL") + " : " + accountarray[1].customer_email + "<br>" +
				t("HEADER.CUS_PHONE") + " : +" + accountarray[1].customer_phone + "<br>" +
				t("HEADER.CUS_ADDRESS") + " : " + accountarray[1].customer_address;

			var jsonpar = {
				user_id: window.sessionStorage.getItem("user_id"),
				emailtext: text,
				email_id: modal_eur
			}
			const headers = {
				'Content-Type': 'application/json',
				'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
				'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
			}
			setLoader(true);
			axios.post(process.env.REACT_APP_API_URL + '../settings/sendaccountinfoemail', jsonpar, { headers: headers })
				.then(function (res) {
					res = res.data;
					if (res.code === "200") {
						setmodal_eur("");
						setSuccess(true);
						setSuccessMsg(res.message);
						setLoader(false);
						setmodal_eur("");
						setmodal_usd("");
						setTimeout(() => {
							setSuccess(false);
						}, 6000);
					} else {
						setmodal_eur("");
						setmodal_usd("");
						setTimeout(() => {
							setLoader(false);
						}, 1000);
					}
				})
				.catch(function (error) {
					setmodal_eur("");
					setmodal_usd("");
					setTimeout(() => {
						setLoader(false);
					}, 1000);
				});
		}
	}

	const clickclip = () => {
		setSuccess(true);
		setSuccessMsg(t("CRYPTOTRANSACTION.COPIEDMSGS"));
		setTimeout(() => {
			setSuccess(false);
		}, 6000);
	}


	const sendaccinfotwo = () => {
		if (validateUSDModal(modal_usd) === false) {
			setusdmodalemail_error(t("PLACEHOLDER.PLACEHOLDER_EMAIL"));
		} else {
			setusdmodalemail_error("");

			setUSDModal(false);
			var text = t("HEADER.ACCOUNTNO") + " : " + accountarray[0].account_number + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + accountarray[0].routing_number + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + accountarray[0].swift_code + "<br>" +
				t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + accountarray[0].bank_name + "<br>" +
				t("HEADER.ACCOUNT_TYPE") + " : " + accountarray[0].account_currency_type + "<br>" +
				t("HEADER.CUS_NAME") + " : " + accountarray[0].customer_name + "<br>" +
				t("HEADER.CUS_EMAIL") + " : " + accountarray[0].customer_email + "<br>" +
				t("HEADER.CUS_PHONE") + " : +" + accountarray[0].customer_phone + "<br>" +
				t("HEADER.CUS_ADDRESS") + " : " + accountarray[0].customer_address;

			var jsonpar = {
				user_id: window.sessionStorage.getItem("user_id"),
				emailtext: text,
				email_id: modal_usd
			}
			const headers = {
				'Content-Type': 'application/json',
				'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
				'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
			}
			setLoader(true);
			axios.post(process.env.REACT_APP_API_URL + '../settings/sendaccountinfoemail', jsonpar, { headers: headers })
				.then(function (res) {
					res = res.data;
					if (res.code === "200") {
						setmodal_usd("");
						setSuccess(true);
						setSuccessMsg(res.message);
						setLoader(false);
						setmodal_eur("");
						setmodal_usd("");
						setTimeout(() => {
							setSuccess(false);
						}, 6000);
					} else {
						setmodal_eur("");
						setmodal_usd("");
						setTimeout(() => {
							setLoader(false);
						}, 1000);
					}
				})
				.catch(function (error) {
					setmodal_eur("");
					setmodal_usd("");
					setTimeout(() => {
						setLoader(false);
					}, 1000);
				});
		}

	}


	const exportone = () => {
		html2canvas(document.querySelector("#accountone")).then(canvas => {
			document.body.appendChild(canvas);
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF();
			pdf.addImage(imgData, 'PNG', 0, 0);
			pdf.save("Deposit_Account_" + Math.floor(100000 + Math.random() * 900000) + ".pdf");
			canvas.remove();
		});

	}

	const exporttwo = () => {
		html2canvas(document.querySelector("#accounttwo")).then(canvas => {
			document.body.appendChild(canvas);
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF();
			pdf.addImage(imgData, 'PNG', 0, 0);
			pdf.save("Deposit_Account_" + Math.floor(100000 + Math.random() * 900000) + ".pdf");
			canvas.remove();
		});

	}

	const validateUSDModal = (value) => {
		setmodal_usd(value);
		if (validator.isEmail(value)) {
			setusdmodalemail_error("");
			return true;
		} else {
			setusdmodalemail_error(t('PLACEHOLDER.PLACEHOLDER_EMAIL'));
			return false;
		}
	}

	const validateEURModal = (value) => {
		setmodal_eur(value);
		if (validator.isEmail(value)) {
			seteurmodalemail_error("");
			return true;
		} else {
			seteurmodalemail_error(t('PLACEHOLDER.PLACEHOLDER_EMAIL'));
			return false;
		}
	}


	return (
		<>
			{success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
			<div className="card">
				{loader === true ?
					<div>
						<Loader open_close={loader} />
					</div>
					:
					<div className="card-header page-title-space">
						<h2 className="text-black font-w600 mb-0">{t('HEADER.MYACC')}</h2>
					</div>
				}
			</div>

			<div className='row'>

				{loader === true ?
					<div>
						<Loader open_close={loader} />
					</div>
					:
					accountarray.length > 0 ?
						<>
							<div className="col-xl-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0 align-items-end">
										<div className="d-flex align-items-center mb-3 mr-3">
											<svg className="mr-3 color-primary" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
											</svg>
											<div className="mr-auto">
												<h5 className="fs-20 text-black font-w600">{t('HEADER.MAIN_BALANCE')}</h5>
												<span className="text-num text-black font-w600">$ {accountarray[0].available_balance}</span>
											</div>
										</div>
										<Dropdown className="mb-auto">
											<Dropdown.Toggle variant="" as="div" className="btn-link i-false share-info">
												<i className="fa fa-share-alt-square" aria-hidden="true" style={{ top: '-11px' }}></i>
											</Dropdown.Toggle>
											<Dropdown.Menu alignRight={true} className="dropdown-menu-right">
												<Dropdown.Item onClick={() => setUSDModal(true)}>{t('HEADER.EMAIL')}</Dropdown.Item>
												<Dropdown.Item onClick={exportone}>{t('HEADER.PDF')}</Dropdown.Item>
												<CopyToClipboard text={t("HEADER.ACCOUNTNO") + " : " + accountarray[0].account_number + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + accountarray[0].routing_number + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + accountarray[0].swift_code + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + accountarray[0].bank_name + "\n" +
													t("HEADER.ACCOUNT_TYPE") + " : " + accountarray[0].account_currency_type + "\n" +
													t("HEADER.CUS_NAME") + " : " + accountarray[0].customer_name + "\n" +
													t("HEADER.CUS_EMAIL") + " : " + accountarray[0].customer_email + "\n" +
													t("HEADER.CUS_PHONE") + " : +" + accountarray[0].customer_phone + "\n" +
													t("HEADER.CUS_ADDRESS") + " : " + accountarray[0].customer_address}>
													<Dropdown.Item onClick={clickclip}>{t('HEADER.CLIPBOARD')}</Dropdown.Item>
												</CopyToClipboard>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div className="card-header bdr-none row" id="accountone">
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNTNO')}</p>
											<span className="text-black">{accountarray[0].account_number}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL3')}</p>
											<span className="text-black">{accountarray[0].routing_number}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL4')}</p>
											<span className="text-black">{accountarray[0].swift_code}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL5')}</p>
											<span className="text-black">{accountarray[0].bank_name}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNT_TYPE')}</p>
											<span className="text-black">{accountarray[0].account_currency_type}</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_NAME')}</p>
											<span className="text-black">{accountarray[0].customer_name}</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_EMAIL')}</p>
											<span className="text-black">{accountarray[0].customer_email}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_PHONE')}</p>
											<span className="text-black">{'+' + accountarray[0].customer_phone}</span>
										</div>
										<div className="col-lg-12 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_ADDRESS')}</p>
											<span className="text-black">{accountarray[0].customer_address}</span>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0 align-items-end">
										<div className="d-flex align-items-center mb-3 mr-3">
											<svg className="mr-3 color-primary" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
											</svg>
											<div className="mr-auto">
												<h5 className="fs-20 text-black font-w600">{t('HEADER.MAIN_BALANCE')}</h5>
												<span className="text-num text-black font-w600">€ {accountarray[1].available_balance}</span>
											</div>
										</div>
										<Dropdown className="mb-auto">
											<Dropdown.Toggle variant="" as="div" className="btn-link i-false share-info">
												<i className="fa fa-share-alt-square" aria-hidden="true" style={{ top: '-11px' }}></i>
											</Dropdown.Toggle>
											<Dropdown.Menu alignRight={true} className="dropdown-menu-right">
												<Dropdown.Item onClick={() => setEURModal(true)}>{t('HEADER.EMAIL')}</Dropdown.Item>
												<Dropdown.Item onClick={exporttwo}>{t('HEADER.PDF')}</Dropdown.Item>
												<CopyToClipboard text={t("HEADER.ACCOUNTNO") + " : " + accountarray[1].account_number + "\n" +
													t("HEADER.ACCOUNT_TYPE") + " : " + accountarray[1].account_currency_type + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL3") + " : " + accountarray[1].routing_number + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL4") + " : " + accountarray[1].swift_code + "\n" +
													t("FIAT.FIAT_DEPOSIT_LABEL5") + " : " + accountarray[1].bank_name + "\n" +
													t("HEADER.CUS_NAME") + " : " + accountarray[1].customer_name + "\n" +
													t("HEADER.CUS_EMAIL") + " : " + accountarray[1].customer_email + "\n" +
													t("HEADER.CUS_PHONE") + " : +" + accountarray[1].customer_phone + "\n" +
													t("HEADER.CUS_ADDRESS") + " : " + accountarray[1].customer_address}>
													<Dropdown.Item onClick={clickclip}>{t('HEADER.CLIPBOARD')}</Dropdown.Item>
												</CopyToClipboard>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div className="card-header row bdr-none" id="accounttwo">
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNTNO')}</p>
											<span className="text-black">{accountarray[1].account_number}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL3')}</p>
											<span className="text-black">{accountarray[1].routing_number}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL4')}</p>
											<span className="text-black">{accountarray[1].swift_code}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('FIAT.FIAT_DEPOSIT_LABEL5')}</p>
											<span className="text-black">{accountarray[1].bank_name}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNT_TYPE')}</p>
											<span className="text-black">{accountarray[1].account_currency_type}</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_NAME')}</p>
											<span className="text-black">{accountarray[1].customer_name}</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_EMAIL')}</p>
											<span className="text-black">{accountarray[1].customer_email}</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_PHONE')}</p>
											<span className="text-black">{'+' + accountarray[1].customer_phone}</span>
										</div>
										<div className="col-lg-12 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_ADDRESS')}</p>
											<span className="text-black">{accountarray[1].customer_address}</span>
										</div>
									</div>
								</div>
							</div>
						</>
						:
						<>
							<div className="col-xl-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0 align-items-end">
										<div className="d-flex align-items-center mb-3 mr-3">
											<svg className="mr-3 color-primary" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
											</svg>
											<div className="mr-auto">
												<h5 className="fs-20 text-black font-w600">{t('HEADER.MAIN_BALANCE')}</h5>
												<span className="text-num text-black font-w600">$ 0.00</span>
											</div>
										</div>
									</div>
									<div className="card-header bdr-none row" id="accountone">
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNTNO')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNT_TYPE')}</p>
											<span className="text-black">************</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_NAME')}</p>
											<span className="text-black">************</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_EMAIL')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_PHONE')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-12 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_ADDRESS')}</p>
											<span className="text-black">************</span>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0 align-items-end">
										<div className="d-flex align-items-center mb-3 mr-3">
											<svg className="mr-3 color-primary" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
											</svg>
											<div className="mr-auto">
												<h5 className="fs-20 text-black font-w600">{t('HEADER.MAIN_BALANCE')}</h5>
												<span className="text-num text-black font-w600">€ 0.00</span>
											</div>
										</div>
									</div>
									<div className="card-header row bdr-none" id="accounttwo">
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNTNO')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.ACCOUNT_TYPE')}</p>
											<span className="text-black">************</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_NAME')}</p>
											<span className="text-black">************</span>
										</div>

										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_EMAIL')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-6 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_PHONE')}</p>
											<span className="text-black">************</span>
										</div>
										<div className="col-lg-12 col-md-12 mb-3">
											<p className="fs-14 mb-1">{t('HEADER.CUS_ADDRESS')}</p>
											<span className="text-black">************</span>
										</div>
									</div>
								</div>
							</div>
						</>
				}
			</div>

			<div className="row">
				<div className="col-sm-12">
					{loader === true ?
						<div>
							<Loader open_close={loader} />
						</div>
						:
						<div className="card height-cstm-chart">
							<div className="card-body">
								<div className="table-responsive">
									{fiataccount.length > 0 ?
										<MaterialTable
											title={t('TRANSACTION.FIAT_TRANSACTION')}
											columns={[
												// { title: t('TRANSACTION.TRA_ID'), field: 'transaction_id' },
												{ title: t('TRANSACTION.DATE_LA'), field: 'date' },
												{ title: t('TRANSACTION.TRANSACTION_DETAILS'), field: 'transaction_details' },
												{ title: t('TRANSACTION.DEBIT_LA'), field: 'type' },
												{ title: t('TRANSACTION.AMOUNT'), field: 'amount' },
												{ title: t('TRANSACTION.BALANCE_LA'), field: 'balance' },
											]}
											data={fiataccount}
											options={{
												exportMenu: [
													{ label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDFfour("PDF") },
													{ label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDFfour("CSV") },
												], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
											}}
										/>
										: <div className="d-sm-flex d-block border-0 pb-0">
											<div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
												<h4 className="fs-20 text-black mb-1 nofoundTitle">{t('TRANSACTION.FIAT_TRANSACTION')} Overview</h4>
												<span className="fs-12 nofoundText">No Data Found</span>
											</div>
										</div>}
								</div>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12">
					{loader === true ?
						<div>
							<Loader open_close={loader} />
						</div>
						:
						<div className="card height-cstm-chart">
							<div className="card-body">
								<div className="table-responsive">
									{allUsercrypto.length > 0 ?
										<MaterialTable
											title={t("CRYPTOTRANSACTION.CRYPTOACCOUNT")}
											columns={[
												{ title: t("CRYPTOTRANSACTION.COIN"), field: 'coin' },
												{ title: t("CRYPTOTRANSACTION.COIN_CODE"), field: 'coin_code' },
												{ title: t("CRYPTOTRANSACTION.WALLET_BALANCE"), field: 'wallet_balance' },
												{ title: t("CRYPTOTRANSACTION.TOTAL_UNAVAIL"), field: 'total_unavailable' },
												{ title: t("CRYPTOTRANSACTION.TOTAL_AVAIL"), field: 'total_available' },
											]}
											data={allUsercrypto}
											options={{
												exportMenu: [
													{ label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDFthird("PDF") },
													{ label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDFthird("CSV") },
												], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
											}}
										/>
										: <div className="d-sm-flex d-block border-0 pb-0">
											<div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
												<h4 className="fs-20 text-black mb-1 nofoundTitle">{t("CRYPTOTRANSACTION.CRYPTOACCOUNT")} Overview</h4>
												<span className="fs-12 nofoundText">No Data Found</span>
											</div>
										</div>}
								</div>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12">
					{loader === true ?
						<div>
							<Loader open_close={loader} />
						</div>
						:
						<div className="card height-cstm-chart">
							<div className="card-body">
								<div className="table-responsive">
									{exchnagetra.length > 0 ?
										<MaterialTable
											title={t("TRANSACTION.BUYSELLLA")}
											columns={[
												{ title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
												{ title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
												{ title: t("TRANSACTION.TYPE"), field: 'exchange_type' },
												{ title: t("TRANSACTION.PAIR"), field: 'exchnage_pair', },
												{ title: t("TRANSACTION.FIAT_AMOUNT"), field: 'fiat_amount' },
												{ title: t("TRANSACTION.AMOUNT"), field: 'crypto_amount' },
												{ title: t("TRANSACTION.STATUS_LA"), field: 'transfer_status' },
											]}
											data={exchnagetra}
											options={{
												exportMenu: [
													{ label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDFsecond("PDF") },
													{ label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDFsecond("CSV") },
												], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
											}}
										/>
										: <div className="d-sm-flex d-block border-0 pb-0">
											<div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
												<h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.BUYSELLLA")} Overview</h4>
												<span className="fs-12 nofoundText">No Data Found</span>
											</div>
										</div>}
								</div>
							</div>
						</div>
					}
				</div>
			</div>


			<div className="row">
				<div className="col-sm-12">
					{loader === true ?
						<div>
							<Loader open_close={loader} />
						</div>
						:
						<div className="card height-cstm-chart">
							<div className="card-body">
								<div className="table-responsive">
									{swaptra.length > 0 ?
										<MaterialTable
											title={t("TRANSACTION.SWAP_TRA_LA")}
											columns={[
												{ title: t("TRANSACTION.TRA_ID"), field: 'transaction_hash', },
												{ title: t("TRANSACTION.DATE_LA"), field: 'created_datetime' },
												{ title: t("FIAT.FROM_AMOUNT"), field: 'from_amount' },
												{ title: t("FIAT.FROM"), field: 'from_coin' },
												{ title: t("FIAT.TO_AMOUNT"), field: 'to_amount' },
												{ title: t("FIAT.TO"), field: 'to_coin' },
												{ title: t("TRANSACTION.STATUS_LA"), field: 'swap_status' },
											]}
											data={swaptra}
											options={{
												exportMenu: [
													{ label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
													{ label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
												], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
											}}
										/>
										: <div className="d-sm-flex d-block border-0 pb-0">
											<div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
												<h4 className="fs-20 text-black mb-1 nofoundTitle">{t("TRANSACTION.SWAP_TRA_LA")} Overview</h4>
												<span className="fs-12 nofoundText">No Data Found</span>
											</div>
										</div>}
								</div>
							</div>
						</div>
					}
				</div>
			</div>

			<Modal className="modal fade" show={USDModal}>
				<div className="modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">{t('KYC.SEND_ACC_CRED')}</h3>
							<button type="button" className="close" onClick={() => setUSDModal(false)}><span>&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="col-md-12">
								<div className="form-group">
									<label className="mb-2 ">{t('LABEL.LABEL_EMAIL')}</label>
									<input type="text" className="form-control" value={modal_usd} onChange={(e) => validateUSDModal(e.target.value)} placeholder={t('PLACEHOLDER.PLACEHOLDER_EMAIL')} />
									{usdmodalemail_error !== "" ? <label className='text-red'>{usdmodalemail_error}</label> : ""}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-light btn-rounded light" onClick={() => setUSDModal(false)}>{t('BUTTONS.CANCEL')}</button>
							<button type="button" onClick={sendaccinfotwo} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
						</div>
					</div>
				</div>
			</Modal>

			<Modal className="modal fade" show={EURModal}>
				<div className="modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">{t('KYC.SEND_ACC_CRED')}</h3>
							<button type="button" className="close" onClick={() => setEURModal(false)}><span>&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="col-md-12">
								<div className="form-group">
									<label className="mb-2 ">{t('LABEL.LABEL_EMAIL')}</label>
									<input type="text" className="form-control" value={modal_eur} onChange={(e) => validateEURModal(e.target.value)} placeholder={t('PLACEHOLDER.PLACEHOLDER_EMAIL')} />
									{eurmodalemail_error !== "" ? <label className='text-red'>{eurmodalemail_error}</label> : ""}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-light btn-rounded light" onClick={() => setEURModal(false)}>{t('BUTTONS.CANCEL')}</button>
							<button type="button" onClick={sendaccinfo} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)

}
export default Home;