import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  useEffect(() => {
    if (window.sessionStorage.getItem("user_id") > 0) {
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <div className='justify-content-center h-100 align-items-center mt-5'>
      <div className='form-input-content text-center error-page center-error-box'>
        <h1 className='error-text font-weight-bold'>404</h1>
        <h4>
          <i className='fa fa-exclamation-triangle text-warning' />  {t('ERROR_PAGE_TEXT.NOT_FOUND')}
        </h4>
        <p>
          {t('ERROR_PAGE_TEXT.NOT_FOUND_TWO')}
        </p>
        <div>
          <Link className='btn btn-primary' to={isLoggedIn === true? '/dashboard' : '/login'}>
            {/* {t('ERROR_PAGE_TEXT.BACKHOME')} */}
            Back to {isLoggedIn === true? "Home" : "Login"}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Error404
