import React, { useState, useEffect } from 'react'
/// React router dom
import { Switch, Route, useHistory } from 'react-router-dom'
import axios from 'axios';
/// Css
import './index.css'
import './chart.css'
import './step.css'

import AppLogout from './pages/AppLogout';
/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from './components/Dashboard/Home'
import MyWallet from './components/Dashboard/MyWallet'
import Invoices from './components/Dashboard/Invoices'
import CardsCenter from './components/Dashboard/CardsCenter'
import Transactions from './components/Dashboard/Transactions'
import TransactionsDetails from './components/Dashboard/TransactionsDetails'
import Task from './components/Dashboard/Task';

/// Accounts Pages
import Fiat from './components/Accounts/Fiat'
import Crypto from './components/Accounts/Crypto'
import Fiat_Incoming_Transfers from './components/Accounts/Fiat_Incoming_Transfers'
import Fiat_Outgoing_Transfers from './components/Accounts/Fiat_Outgoing_Transfers'
import Fiat_Internal_Transfers from './components/Accounts/Fiat_Internal_Transfers'
import Wallets_Deposit_Transaction from './components/Accounts/Wallets_Deposit_Transaction'
import Wallets_Withdrawal_Transaction from './components/Accounts/Wallets_Withdrawal_Transaction'

/// Crypto Exchanges
import MyCards from './components/CardAccount/MyCards'
import RequestCard from './components/CardAccount/RequestCard'
import PhysicalCard from './components/CardAccount/PhysicalCard'
import VirtualCard from './components/CardAccount/VirtualCard'
import TopUpCard from './components/CardAccount/TopUpCard'
import TransferAccount from './components/CardAccount/TransferAccount'
import MyCardTransactions from './components/CardAccount/MyCardTransactions'
import CardSettings from './components/CardAccount/CardSettings'

/// Card Account
import Crypto_Exchanges from './components/CryptoExchanges/Crypto_Exchanges'

/// Transaction
import Fiat_Transactions from './components/Transaction/Fiat_Transactions'
import Crypto_Transactions from './components/Transaction/Crypto_Transactions'

/// Activity Logs
import Users_Activity_Logs from './components/ActivityLogs/Users_Activity_Logs'

/// News
import News from './components/Management/News'
import News_Details from './components/Management/News_Details'
import FAQ from './components/Management/FAQ'
import Terms from './components/Management/Terms'
import Privacy from './components/Management/Privacy'
import Contactus from './components/Management/Contactus'

/// Verify Your Account
import Verify_Your_Account from './components/VerifyYourAccount/Verify_Your_Account'

/// Profile Management
import Profile from './components/Profile/profile'
import EditProfile from './components/Profile/EditProfile'

/// Pages

import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

const Markup = () => {
  const history = useHistory();
  const [Kyc, setKyc] = useState("Pending");
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  useEffect(() => {
    setTimeout(() => {
      if (window.sessionStorage.getItem("user_id") <= 0) {
        history.push('/login');
      }
    }, 3000);

    if (window.sessionStorage.getItem("user_ip") !== "") {
      getData();
    }

    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    if (res.data.IPv4 === window.sessionStorage.setItem("user_ip", res.data.ipaddress)) {
      return true;
    } else {
      if (window.sessionStorage.getItem("diffips") === "Yes") {
        if (window.sessionStorage.getItem("demouser") === "Yes") {
          window.sessionStorage.removeItem("demouser");
          localStorage.clear();
          sessionStorage.clear();
          window.location.pathname = "/demologin";
        } else {
          window.sessionStorage.removeItem("demouser");
          localStorage.clear();
          sessionStorage.clear();
          window.location.pathname = "/login";
        }
      }
    }
  }

  const handleLoad = () => {
    const headers = {
      'Content-Type': 'application/json',
      'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
      'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
    }
    axios.get(process.env.REACT_APP_API_URL + 'checkkyc/' + window.sessionStorage.getItem("user_id"), { headers: headers })
      .then(function (res) {
        res = res.data;
        if (res.data.length > 0) {
          setKyc(res.data[0].kyc_status);
        }
      })
      .catch(function (error) {

      });
  }

  const routes = [
    /// Dashboard
    { url: 'dashboard', component: Home },
    { url: 'my-wallet', component: MyWallet },
    { url: 'invoices', component: Invoices },
    { url: 'cards-center', component: CardsCenter },
    { url: 'transactions', component: Transactions },
    { url: 'transactions-details', component: TransactionsDetails },
    { url: 'task', component: Task },

    /// Accounts
    { url: 'fiat', component: Fiat },
    { url: 'crypto', component: Crypto },
    { url: 'fiat-incoming-transfers', component: Fiat_Incoming_Transfers },
    { url: 'fiat-outgoing-transfers', component: Fiat_Outgoing_Transfers },
    { url: 'fiat-internal-transfers', component: Fiat_Internal_Transfers },
    { url: 'wallets-deposit-transaction', component: Wallets_Deposit_Transaction },
    { url: 'wallets-withdrawal-transaction', component: Wallets_Withdrawal_Transaction },

    /// Crypto Exchanges
    { url: 'crypto-exchanges', component: Crypto_Exchanges },

    /// Card Account 
    { url: 'my-cards', component: MyCards },
    { url: 'request-card', component: RequestCard },
    { url: 'physical-card', component: PhysicalCard },
    { url: 'virtual-card', component: VirtualCard },
    { url: 'top-up-card', component: TopUpCard },
    { url: 'transfer-account', component: TransferAccount },
    { url: 'my-cards-transactions', component: MyCardTransactions },
    { url: 'card-settings/:id', component: CardSettings },

    /// Transaction
    { url: 'fiat-transactions', component: Fiat_Transactions },
    { url: 'crypto-transactions', component: Crypto_Transactions },

    /// Activity Logs
    { url: 'users-activity-logs', component: Users_Activity_Logs },

    /// News
    { url: 'news', component: News },
    { url: 'news-details/:id', component: News_Details },
    { url: 'faq', component: FAQ },
    { url: 'terms', component: Terms },
    { url: 'privacypolicy', component: Privacy },
    { url: 'contact-us', component: Contactus },

    /// Verify Your Account
    { url: 'verify-your-account', component: Verify_Your_Account },

    /// Profile Management
    { url: 'profile', component: Profile },
    { url: 'editprofile', component: EditProfile },

    /// pages
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
    { url: 'register-verify-otp', component: Home },
    { url: 'register-google-auth', component: Home },
    { url: '*', component: Error404 },
  ]

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        <AppLogout>
          {/* {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
          {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""} */}
          {!pagePath && (
            <Nav
              onClick={() => setActiveEvent(!activeEvent)}
              activeEvent={activeEvent}
              onClick2={() => setActiveEvent(false)}
              onClick3={() => setActiveEvent(true)}
            />
          )}
          <div
            className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''
              }`}
          >
            <div
              className={`${!pagePath ? 'container-fluid' : ''}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {Kyc === "Approved" ?
                  routes.map((data, i) => (
                    <Route key={i} exact path={`/${data.url}`} component={data.component} />
                  ))
                  :
                  <>
                    <Route exact path="/dashboard" component={Home} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/editprofile" component={EditProfile} />
                    <Route exact path="/users-activity-logs" component={Users_Activity_Logs} />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/news-details/:id" component={News_Details} />
                    <Route exact path="/faq" component={FAQ} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/privacypolicy" component={Privacy} />
                    <Route exact path="/contact-us" component={Contactus} />
                    <Route exact path="/verify-your-account" component={Verify_Your_Account} />
                  </>
                }
              </Switch>
            </div>
          </div>
          {!pagePath && <Footer />}
          {/* <Switch>
          <Route path='*' exact={true} component={Error404} />
        </Switch> */}
        </AppLogout>
      </div>
      <ScrollToTop />
    </>
  )
}

export default Markup
