import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import validator from 'validator';

export const Fiat_Internal_Transfers = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [TransferAccept, setTransferAccept] = useState(false);
    const [TransferDecline, setTransferDecline] = useState(false);
    const [loader, setLoader] = useState(false);
    const [accountarray, setAccountarray] = useState([]);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");
    const [OTPVerification, setOTPVerification] = useState(false);
    const [account, setaccount] = useState("");
    const [account_error, setaccount_error] = useState("");
    const [amount, setamount] = useState("");
    const [currancy, setcurrancy] = useState("");
    const [message, setmessage] = useState("");
    const [amount_error, setamount_error] = useState("");
    const [currancy_error, setcurrancy_error] = useState("");
    const [message_error, setmessage_error] = useState("");
    const [otpmessage, setotpmessage] = useState("");
    const [otpmessage_error, setotpmessage_error] = useState("");
    const [emailaddress, setemailaddress] = useState("");
    const [emailaddress_error, setemailaddress_error] = useState("");
    const [accname, setaccname] = useState(t('FIAT.USER_ACC_NAME'));
    const [showdiv, setshowdiv] = useState(false);
    const [minamount, setminamount] = useState("");
    const [maxamount, setmaxamount] = useState("");

    const [GoogleVerification, setGoogleVerification] = useState(false);
    const [transferotp, settransferotp] = useState("");
    const [transferotpmessage, settransferotpmessage] = useState("");
    const [transferotpmessage_error, settransferotpmessage_error] = useState("");
    const [google_auth, setgoogle_auth] = useState("");
    const [google_auth_error, setgoogle_auth_error] = useState("");


    const [success_display_cur, setsuccess_display_cur] = useState("");
    const [success_display_amount, setsuccess_display_amount] = useState("");
    const [success_display_refno, setsuccess_display_refno] = useState("");
    const [success_display_fromaccount, setsuccess_display_fromaccount] = useState("");
    const [success_display_fromaccount_cur, setsuccess_display_fromaccount_cur] = useState("");
    const [success_display_toaccount, setsuccess_display_toaccount] = useState("");
    const [success_display_toaccount_cur, setsuccess_display_toaccount_cur] = useState("");
    const [success_display_details, setsuccess_display_details] = useState("");

    const [error_display_details, seterror_display_details] = useState("");
    const [error_display_cur, seterror_display_cur] = useState("");
    const [error_display_amount, seterror_display_amount] = useState("");

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getuseraccoundsbyid/' + window.sessionStorage.getItem("user_id"), { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    settransferotp(res.internalotp);
                    setAccountarray(res.data);
                    // setminamount(res.mininternal);
                    // setmaxamount(res.maxinternal);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateCode = (value) => {
        setotpmessage(value);
        if (!validator.isEmpty(value)) {
            setotpmessage_error("");
            return true;
        } else {
            setotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const validateCodeTransfer = (value) => {
        settransferotpmessage(value);
        if (!validator.isEmpty(value)) {
            settransferotpmessage_error("");
            return true;
        } else {
            settransferotpmessage_error(t('FIAT.OTP_CODE_PLACE'));
            return false;
        }
    }

    const getlimit = (value) => {
        console.log(value);
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            coinname: value,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getbaselimit', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setminamount(res.mininternal);
                    setmaxamount(res.maxinternal);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const validateAccount = (value) => {
        const myArray = value.split("|");
        setaccount(value);
        setcurrancy(myArray[1]);
        if (!validator.isEmpty(myArray[0])) {
            setaccount_error("");
            console.log(myArray[1]);
            getlimit(myArray[1]);
            //CheckEmail(emailaddress, myArray[1]);
            return true;
        } else {
            setaccount_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        }
    }

    const ValidateEmail = (value) => {
        setemailaddress(value);
        if (validator.isEmail(value)) {
            setemailaddress_error("");
            //const myArray = account.split("|");
            // CheckEmail(value, myArray[1]);
            return true;
        } else {
            setemailaddress_error(t('ERROR_MSG.ERROR_EMAIL'));
            return false;
        }
    }

    const ValidateEmails = (value) => {
        setemailaddress(value);
        if (validator.isEmail(value)) {
            setemailaddress_error("");
            const myArray = account.split("|");
            // CheckEmail(value, myArray[1]);
            return true;
        } else {
            setemailaddress_error(t('ERROR_MSG.ERROR_EMAIL'));
            return false;
        }
    }

    const validateAmount = (value) => {
        setamount(value);
        if (validator.isNumeric(value) && parseFloat(value) > 0) {
            if (parseFloat(maxamount) > 0) {
                if (parseFloat(value) < parseFloat(minamount)) {
                    setamount_error("Minimum Amount is " + minamount);
                    return false;
                }

                if (parseFloat(value) > parseFloat(maxamount)) {
                    setamount_error("Maximum Amount is " + maxamount);
                    return false;
                }
            }
            setamount_error("");
            return true;
        } else {
            setamount_error(t('FIAT.AMOUNT_TO_TRANSFER_ERROR'));
            return false;
        }
    }

    const validateCurrancy = (value) => {
        setcurrancy(value);
        if (!validator.isEmpty(value)) {
            setcurrancy_error("");
            return true;
        } else {
            setcurrancy_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        }
    }

    const validateMessage = (value) => {
        setmessage(value);
        if (!validator.isEmpty(value)) {
            setmessage_error("");
            return true;
        } else {
            setmessage_error(t('FIAT.MESSAGE_PLACE'));
            return false;
        }
    }

    const validateGoogleAuth = (value) => {
        setgoogle_auth(value);
        if (!validator.isEmpty(value)) {
            setgoogle_auth_error("");
            return true;
        } else {
            setgoogle_auth_error(t("FIAT.OTP_CODE_PLACE"));
            return false;
        }
    }


    const finalsubmit = () => {
        var accountid = account.split("|");
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id"),
            account: accountid[0],
            emailaddress: emailaddress,
            amount: amount,
            currancy: currancy,
            message: message,
            otp: otpmessage,
            auth_code: google_auth,
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);

        axios.post(process.env.REACT_APP_API_URL + 'fiatinternal', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    setsuccess_display_cur(res.data.success_display_cur);
                    setsuccess_display_amount(res.data.success_display_amount);
                    setsuccess_display_refno(res.data.success_display_refno);
                    setsuccess_display_fromaccount(res.data.success_display_fromaccount);
                    setsuccess_display_fromaccount_cur(res.data.success_display_fromaccount_cur);
                    setsuccess_display_toaccount(res.data.success_display_toaccount);
                    setsuccess_display_toaccount_cur(res.data.success_display_toaccount_cur);
                    setsuccess_display_details(res.data.success_display_details);

                    setTransferAccept(true);
                    setaccount("");
                    setemailaddress("");
                    setamount("");
                    setcurrancy("");
                    setmessage("");
                    setotpmessage("");
                    setaccname("");
                    setshowdiv(false);
                    setOTPVerification(false);
                    setGoogleVerification(false);
                    setgoogle_auth("");

                    setTimeout(() => {
                        handleLoad();
                        // history.push({
                        //     pathname: '/fiat',
                        //     openSnackbar: true,
                        //     openSnackbarmsg: res.message
                        // });
                        //history.push('/fiat');
                        //handleLoad();
                    }, 1000);
                } else {
                    seterror_display_details(res.data.error_display_details);
                    seterror_display_cur(res.data.error_display_cur);
                    seterror_display_amount(res.data.error_display_amount);
                    setTransferDecline(true);
                    setLoader(false);
                    setotpmessage("");
                    setgoogle_auth("");
                    setOTPVerification(false);
                    setGoogleVerification(false);
                }

            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setotpmessage("");
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }
    const handlesubmit = () => {
        if (validateAccount(account) === false) {
            setaccount_error(t('FIAT.SELECT_ACCOUNT'));
            return false;
        } else if (ValidateEmail(emailaddress) === false) {
            setaccount_error("");
            setemailaddress_error(t('ERROR_MSG.ERROR_EMAIL'));
            return false;
        } else if (validateAmount(amount) === false) {
            setemailaddress_error("");
            setamount_error(t('FIAT.AMOUNT_TO_TRANSFER_ERROR'));
            return false;
        } else if (validateCurrancy(currancy) === false) {
            setamount_error("");
            setcurrancy_error(t('FIAT.COUNTRY_PLACE'));
            return false;
        } else if (validateMessage(message) === false) {
            setcurrancy_error("");
            setmessage_error(t('FIAT.MESSAGE_PLACE'));
            return false;
        } else {
            setmessage_error("");

            if (transferotp === "Google Authentication") {
                setGoogleVerification(true);
            } else if (transferotp === "SMS OTP Authentication") {


                var jsonpar = {
                    user_id: window.sessionStorage.getItem("user_id")
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                    'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
                }
                setLoader(true);
                axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
                    .then(function (res) {
                        res = res.data;
                        if (res.code === "200") {
                            setotpmessage("");
                            setOTPVerification(true);
                            setLoader(false);
                            setSuccess(true);
                            setSuccessMsg(res.message);
                            setTimeout(() => {
                                setSuccess(false);
                            }, 6000);
                        } else {
                            setLoader(false);
                            setError(true);
                            setErrorMsg(res.message);
                            setTimeout(() => {
                                setError(false);
                            }, 6000);
                        }

                    })
                    .catch(function (error) {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(t('ERROR_MSG.SOMETHING'));
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    });
            } else {
                finalsubmit();
            }
        }
    }

    const resendotp = () => {
        var jsonpar = {
            user_id: window.sessionStorage.getItem("user_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        document.getElementById("submit").disabled = true;
        document.getElementById("submit").textContent = t('PROCESSING');
        axios.post(process.env.REACT_APP_API_URL + 'sendandcheckotp', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }


    const CheckEmail = () => {
        if (emailaddress === "" || emailaddress === null || emailaddress === undefined || account === "" || account === null || account === undefined) {
            setemailaddress_error("Please select account and enter valid email address");
            return false;
        } else {
            setemailaddress_error("");
            const myArray = account.split("|");

            var jsonpar = {
                user_email: emailaddress,
                currancy: myArray[1],
                user_id: window.sessionStorage.getItem("user_id")
            }
            const headers = {
                'Content-Type': 'application/json',
                'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
                'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
            }
            setLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'checkemailaccount', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setaccname(res.data);
                        setshowdiv(true);
                    } else {
                        setemailaddress_error(res.message);
                        setaccname("");
                        setshowdiv(false);
                    }
                    setLoader(false);

                })
                .catch(function (error) {
                    setaccname("");
                    setshowdiv(false);
                    setLoader(false);
                });
        }
    }



    return (
        <>
            <div className="card">
                {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
                {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t('FIAT.INTERNAL_TITLE')}</h2>
                    <Link to="/fiat" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t('LINK.BACK')}</Link>
                </div>
            </div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row position-relative z-111">
                            <div className="col-md-6">
                                <div className="form-group position-relative">
                                    <h4 className="card-title mb-3">{t('FIAT.FROM')}</h4>
                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateAccount(e.target.value)} value={account}>
                                        <option value="">{t('FIAT.SELECT_ACCOUNT')}</option>
                                        {accountarray.length > 0 ?
                                            <>
                                                <option value={accountarray[0].account_id_pk + "|USD"}>{t('KYC.MYACCOUNT')} : {accountarray[0].account_number} ( {accountarray[0].available_balance} $ )</option>
                                                <option value={accountarray[1].account_id_pk + "|EUR"}>{t('KYC.MYACCOUNT')} : {accountarray[1].account_number} ( {accountarray[1].available_balance} € )</option>
                                            </>
                                            : ""}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>

                                </div>
                                {account_error !== "" ? <label className='text-red'>{account_error}</label> : ""}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <h4 className="card-title mb-3">{t('FIAT.EMAIL_ADDRESS')}</h4>
                                    <input type="text" className="form-control" onBlur={(e) => ValidateEmails(e.target.value)} onChange={(e) => ValidateEmail(e.target.value)} value={emailaddress} placeholder={t('FIAT.EMAIL_ADDRESS_PLACE')} />
                                    {emailaddress_error !== "" ? <label className='text-red'>{emailaddress_error}</label> : ""}
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <button type="button" className="btn btn-primary btn-rounded" onClick={CheckEmail}>Next</button>
                            </div>
                        </div>
                        {showdiv === true ?
                            <>
                                <h4 className="card-title mb-4 heighlight-info mt-3">{accname}</h4>
                                <div className="col-xl-12 p-0">
                                    <div className="mt-4 row">
                                        <div className="col-md-6">
                                            <div className="form-group position-relative">
                                                <label className="mb-2 ">{t('FIAT.AMOUNT_TO_TRANSFER')}</label>
                                                <input type="text" className="form-control" onChange={(e) => validateAmount(e.target.value)} value={amount} placeholder={t('FIAT.AMOUNT_TRANSFER_PLACE')} />
                                                {amount_error !== "" ? <label className='text-red'>{amount_error}</label> : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group position-relative">
                                                <label className="mb-2 ">{t('FIAT.CURRENCY_TITLE')}</label>
                                                <select className="form-control webkit-none" id="sel1" onChange={(e) => validateCurrancy(e.target.value)} value={currancy} disabled>
                                                    <option value="USD">USD</option>
                                                    <option value="EUR">EUR</option>
                                                </select>
                                                {currancy_error !== "" ? <label className='text-red'>{currancy_error}</label> : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group position-relative">
                                                <label className="mb-2 ">{t('FIAT.MESSAGE_TITLE')}</label>
                                                <input type="text" className="form-control" onChange={(e) => validateMessage(e.target.value)} value={message} placeholder={t('FIAT.MESSAGE_PLACE')} />
                                                {message_error !== "" ? <label className='text-red'>{message_error}</label> : ""}
                                            </div>
                                        </div>

                                        {parseInt(minamount) > 0 ?
                                            <div className="col-md-12 mt-4">
                                                <label className="mb-2 minmaxclass">Min Amount = {minamount} </label>
                                                <label className="mb-2 ml-3 minmaxclass">Max Amount = {maxamount} </label>
                                            </div>
                                            : ""}

                                        <div className="col-md-12 mt-4">
                                            <button type="button" id="submit" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('BUTTONS.CONTINUE')}</button>
                                            <Link to="/fiat" className="btn btn-outline-light ml-3 btn-rounded">{t('BUTTONS.CANCEL')}</Link>
                                        </div>

                                        <Modal className="modal fade" show={OTPVerification}>
                                            <div className="modal-dialog-centered " >
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h3 className="modal-title">{t('FIAT.OTPVERIFICATION_TITLE')}</h3>
                                                        <button type="button" className="close" onClick={() => setOTPVerification(false)}><span>&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="mb-2 ">{t('FIAT.OTP_CODE_TITLE')}</label>
                                                                    <input type="text" minLength={6} maxLength={6} onChange={(e) => validateCode(e.target.value)} value={otpmessage} className="form-control" placeholder={t('FIAT.OTP_CODE_PLACE')} />
                                                                    <p className="resendcode"><Link onClick={resendotp}>{t('FIAT.RESEND_OTP')}</Link></p>
                                                                    <label className="mb-2 ">{"OTP is sent to your registered mobile number."}</label>
                                                                </div>
                                                                {otpmessage_error !== "" ? <label className='text-red'>{otpmessage_error}</label> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setOTPVerification(false)}>{t('BUTTONS.CANCEL')}</button>
                                                        <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t('BUTTONS.SUBMIT')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>

                                        <Modal className="modal fade" show={GoogleVerification}>
                                            <div className="modal-dialog-centered " >
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h3 className="modal-title">{t("LABEL.GOOGLE_AUTHENTICATOR_TITLE")}</h3>
                                                        <button type="button" className="close" onClick={() => setGoogleVerification(false)}><span>&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="mb-2 ">{t("FIAT.OTP_CODE_TITLE")}</label>
                                                                    <input type="text" minLength={6} maxLength={6} className="form-control" onChange={(e) => validateGoogleAuth(e.target.value)} value={google_auth} placeholder={t("FIAT.OTP_CODE_PLACE")} />
                                                                    {google_auth_error !== "" ? <label className='text-red'>{google_auth_error}</label> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-light btn-rounded light" onClick={() => setGoogleVerification(false)}>{t("BUTTONS.CANCEL")}</button>
                                                        <button type="button" onClick={finalsubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>

                                </div>
                            </>
                            : ""}

                        <Modal className="modal fade" show={TransferAccept}>
                            <div className="modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Fiat Account Internal Transfer</h3>
                                        <button type="button" className="close" onClick={() => setTransferAccept(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check">
                                            <i class="fa fa-solid fa-check"></i>
                                            <p className="fs-14 mb-1 Submitted">Transaction Submitted</p>
                                        </div>
                                        {success_display_refno === "" || success_display_refno === undefined || success_display_refno === null ? " " :
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Amount : <span className="text-black">{success_display_amount}</span></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Reference Number : <span className="text-black">{success_display_refno}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Transfer from {success_display_fromaccount_cur} account : <span className="text-black">{success_display_fromaccount}</span></p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-1">
                                                        <p className="fs-16 mb-1">Transferred to {success_display_toaccount_cur} account : <span className="text-black">{success_display_toaccount}</span></p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="row mt-1">
                                            <div className="col-md-12 mb-3">
                                                <p className="fs-16 mb-1">Details</p>
                                                <span className="text-black">{success_display_details}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-2">
                                            <ul className="btm-link">
                                                <li>
                                                    <Link to="#" onClick={() => setTransferAccept(false)}> New Transfer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/dashboard"> Dashboard</Link>
                                                </li>
                                                <li>
                                                    <Link to="/fiat-transactions"> Transaction List</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal className="modal fade" show={TransferDecline}>
                            <div className="modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Fiat Account Internal Transfer</h3>
                                        <button type="button" className="close" onClick={() => setTransferDecline(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check">
                                            <i class="fa fa-solid fa-times"></i>
                                            <p className="fs-14 mb-1 Submitted">Transaction Failed</p>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 mb-1">
                                                <p className="fs-16 mb-1">Amount : <span className="text-black">{error_display_amount}</span></p>
                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-md-12 mb-3">
                                                <p className="fs-16 mb-1">Details</p>
                                                <span className="text-black">{error_display_details}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-2">
                                            <ul className="btm-link">
                                                <li>
                                                    <Link to="#" onClick={() => setTransferDecline(false)}> Retry</Link>
                                                </li>
                                                <li>
                                                    <Link to="/dashboard"> Dashboard</Link>
                                                </li>
                                                <li>
                                                    <Link to="/fiat-transactions"> Transaction List</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        </>
    )

}
export default Fiat_Internal_Transfers;