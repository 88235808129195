import * as React from 'react';

export default function Loader(props) {
    var open = React.useState(props.open_close);

    return (
        <div>
            {open ?
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
                : ''}
        </div>
    )
}